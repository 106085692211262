export default {

    masterCourseMinor:{
        courseMinorData:null,
        courseMinorLinks:null,
        courseMinorMeta:null,
    },

    approveRejectCourseMinor: {
        showCourseMinor: false,
        action:'',
        data:''
    },
    editCourseMinor: {
        showCourseMinor: false,
    },
    
};