export const setMasterKeySkillData = (state, data) => {
	state.masterKeySkill.keySkillsData = data.data.data;
	state.masterKeySkill.keySkillsLinks = data.data.links;

    state.masterKeySkill.keySkillsMeta = {
        current_page: data.data.current_page,
        from: data.data.from,
        to:  data.data.to,
        total: data.data.total,
        perPage: data.data.per_page
    }
}

export const showApproveRejectConfirmationModal = (state, flag) => {
    state.approveRejectKeySkill.showKeySkill = flag;
};

export const hideApproveRejectConfirmationModal = (state) => {
    state.approveRejectKeySkill.showKeySkill = false;
};

export const setAction = (state, action) => {
    state.approveRejectKeySkill.action = action;
}

export const setData = (state, data) => {
    state.approveRejectKeySkill.data = data;
}

export const showEditModal = (state, flag) => {
    state.editKeySkill.showKeySkill = flag;
};

export const hideEditModal = (state) => {
    state.editKeySkill.showKeySkill = false;
};
