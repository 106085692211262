import { isEmpty } from 'lodash'

export const setHttpToken = (token) => {
    if (isEmpty(token)) {
        window.axios.defaults.headers.common['ApiToken'] = null
        window.axios.defaults.headers.common['Authorization'] = null
        window.axios.defaults.headers.common['ProductCode'] = null
        window.axios.defaults.headers.common['ClientType'] = null
    }
    window.axios.defaults.headers.common['ApiToken'] = 'Bearer ' + token
    window.axios.defaults.headers.common['Authorization'] = 'Bearer ' + token
    window.axios.defaults.headers.common['ProductCode'] = 'SMART-RECRUIT'
    window.axios.defaults.headers.common['ClientType'] = 'recruit-admin'
}

export const setHttpBaseUrl = (token) => {
    if (!isEmpty(token)) {
        window.axios.defaults.baseURL = process.env.VUE_APP_SMART_RECRUIT_API_URL
        return
    }
    window.axios.defaults.baseURL = process.env.VUE_APP_SSO_API_URL
}
