export default {

    masterEmployerType:{
        employerTypeData:null,
        employerTypeLinks:null,
        employerTypeMeta:null,
    },

    approveRejectEmployerType: {
        showEmployerType: false,
        action:'',
        data:''
    },
    editEmployerType: {
        showEmployerType: false,
    },
    
};