export const getMasterJobFairTypeData = (state) => {
    return state.masterJobFairType.jobFairTypeData;
}

export const getMasterJobFairTypeLink = (state) => {
    return state.masterJobFairType.jobFairTypeLinks;
}

export const getMasterJobFairTypeMeta = (state) => {
    return state.masterJobFairType.jobFairTypeMeta;
}

export const getShowApproveRejectConfirmationModal = (state) => {
    return state.approveRejectJobFairType.showJobFairType;
}

export const getAction = (state) => {
    return state.approveRejectJobFairType.action;
}

export const getData = (state) => {
    return state.approveRejectJobFairType.data;
}

export const getShowEditModal = (state) => {
    return state.editJobFairType.showJobFairType;
}

