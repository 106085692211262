import { isEmpty } from 'lodash';
// list of feedback

export const fetchFeedbackList = ({ commit, dispatch }, query = '') => {
    return window.axios.get (process.env.VUE_APP_SMART_RECRUIT_API_URL + 'api/feedback-review' + query).then((response) => {
        commit('setFeedbackList', response);
        return true;
    }).catch((error) => {
        dispatch("auth/errorHandler",error.response.status,{ root:true });
        return;
    });
}
// ~~~~~~~~~


// delete feedback
export const deleteFeedback = ({ commit, state, dispatch }, id) => {
    return window.axios.delete(process.env.VUE_APP_SMART_RECRUIT_API_URL + `api/delete-feedback/${id}`).then((response) => {
        // commit('SET_ALERT', 'success', { root: true })
        // commit('SET_ALERT_MESSAGE', response.data.data, { root: true })
        document.querySelector('[data-id="' + String('table-row-' + id) + '"]').remove();          
        return response.data
    })
}



export const feedbackPublishByID = ({ commit, dispatch }, id ) => {
  return window.axios.post(process.env.VUE_APP_SMART_RECRUIT_API_URL + `api/publish-feedback/${id}`).then((response) => {
        // commit('SET_ALERT', 'success', { root: true })
        // commit('SET_ALERT_MESSAGE', response.data.data, { root: true })
        dispatch('fetchFeedbackList')
        return response.data.success 
    })
}


// list of publish feedback
export const fetchFeedbackPublishList = ({commit, dispatch, getters}, query) => {
    let categoryName = getters['getCategoryList']
    if(query){
        query = query + '&status=1&category=' + categoryName;
    }else{
        query = '?status=1&category=' + categoryName;
    }
    return window.axios.get(process.env.VUE_APP_SMART_RECRUIT_API_URL + 'api/feedback-review' + query).then((response) => {          
        commit('setFeedbackPublishList', response);   
        return true
    })
}
// ~~~~~~~~~