import localforage from 'localforage'
import { isEmpty } from 'lodash'

export const setRoleSlug = (state, slug) => {
    state.user.roleSlug = slug
    localforage.setItem('userrole', slug)
    state.showTab = true
    if (slug == 'job-seeker') {
        state.showTab = false
    }
}

export const setToken = (state, token) => {
    if (isEmpty(token)) {
        localforage.removeItem('authtoken', token)
        return
    }

    localforage.setItem('authtoken', token)
}

export const setAuthenticated = (state, flag) => {
    state.user.authenticated = flag
}

export const setUserData = (state, userData) => {
    state.user.data = userData
}

export const CHECK_ROLE_SELECTION = (state, context) => {
    if (isEmpty(state.user.roleSlug)) {
        context.$router.replace({ name: 'select-role' })
    }
}

export const setBellNotifications = (state, data) => {
    state.user.bellNotifications.count = data.count;
    if (data.data != undefined) {
        state.user.bellNotifications.messages = data.data.data;

    }
}

export const setUser = (state, payload) => {
    localStorage.setItem('email', payload.email);
    localStorage.setItem('password', payload.password);
    localStorage.setItem('keepMeLoggedIn', payload.keepMeLoggedIn);
    localStorage.setItem('data', JSON.stringify(payload.data));
    localStorage.setItem('profilePicUrl', payload.profilePicUrl);

}
