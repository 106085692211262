export const SET_DISCOUNT_PROMOTION_PLAN_LIST = (state, data) => {
    state.discountPromotionPlan.data = data.data.data;
    state.discountPromotionPlan.links = data.data.links;
	state.discountPromotionPlan.meta = {
		current_page: data.data.current_page,
		from: data.data.from,
		to:  data.data.to,
		total: data.data.total,
		perPage: data.data.per_page
	}
}