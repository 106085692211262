export default [
	{
        path: '/advertisements',
        component: () => import('../components/AdvertisementList.vue'),
        name: 'advertisements',
        meta: { 
            guest: false,
            needsAuth: true,        
        }
    },
    {
        path: '/add-advertisement',
        component: () => import('../components/partials/AddNewAdvertisement.vue'),
        name: 'add-advertisement',
        meta: { 
            guest: false,
            needsAuth: true,        
        }
    },
   
]