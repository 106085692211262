export const fetchFileServerSettings = ({ commit, dispatch}, payload) => {
    let query = payload ? '?edit=true' : '';
    return window.axios.get(process.env.VUE_APP_SMART_RECRUIT_API_URL + 'api/site-setting' + query).then((response) => {
        commit('setFileServerData', response)
        return response.data.success;
    }).catch((error) => {
        dispatch("auth/errorHandler",error.response.status,{ root:true });
        commit("SET_ALERT_MESSAGE", "Something went wrong", { root: true });
        return response.data.success;
    });   
};
export const testConnection = ({ commit}, {context}) => {
    return window.axios.get(process.env.VUE_APP_SMART_RECRUIT_API_URL + 'api/test-connection').then((response) => {
        if(response.data.success){
            commit('SET_ALERT', 'success', { root: true })
            commit("SET_ALERT_MESSAGE", response.data.message, { root: true });
            return true;
        }
        return false;
    }).catch((error) => {
        commit("SET_ALERT", "warning", { root: true });
        commit("SET_ALERT_MESSAGE",  'Connection Failed', { root: true });
        context.testBtn = context.btn2;
        context.disebelField = false;
        return false;
    });   
  };
