export const fetchMasterLanguage = ({ commit, dispatch }, query='') => {
    let url = process.env.VUE_APP_SMART_RECRUIT_API_URL + 'api/master-data-language'+ query;
    return window.axios.get(url).then((response) => {
        commit('setMasterLanguageData', response.data);
        return true;
    }).catch((error) => {
        dispatch("auth/errorHandler",error.response.status,{ root:true });
        return false;
    })
}

export const postMasterDataLanguage = ({ commit}, { payload, id, context}) => {
    return window.axios.post (process.env.VUE_APP_SMART_RECRUIT_API_URL + 'api/master-data-language/' + id, payload).then((response) => {
        // commit('SET_ALERT', 'success', { root: true })
        // commit('SET_ALERT_MESSAGE', response.data.message, { root: true })
        return response.data.success
    }).catch((error) => {
        // commit('SET_ALERT', 'warning', { root: true })
        // commit('SET_ALERT_MESSAGE', 'Something went wrong', { root: true })
        context.errors = error.response.data
        return false
    })
};
export const deleteMasterDataRecord = ({ commit}, { payload, id, context}) => {
    return window.axios.post (process.env.VUE_APP_SMART_RECRUIT_API_URL + 'api/delete-master-data-record', payload).then((response) => {
        return response.data;
    }).catch((error) => {
        // commit('SET_ALERT', 'warning', { root: true })
        // commit('SET_ALERT_MESSAGE', 'Something went wrong', { root: true })
        context.errors = error.response.data
        return false
    })
};
