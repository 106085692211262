export const getMasterWorkStyleData = (state) => {
    return state.masterWorkStyle.workStyleData;
}

export const getMasterWorkStyleLink = (state) => {
    return state.masterWorkStyle.workStyleLinks;
}

export const getMasterWorkStyleMeta = (state) => {
    return state.masterWorkStyle.workStyleMeta;
}

export const getShowApproveRejectConfirmationModal = (state) => {
    return state.approveRejectWorkStyle.showWorkStyle;
}

export const getAction = (state) => {
    return state.approveRejectWorkStyle.action;
}

export const getData = (state) => {
    return state.approveRejectWorkStyle.data;
}

export const getShowEditModal = (state) => {
    return state.editWorkStyle.showWorkStyle;
}

