export const getMasterExamDegreeData = (state) => {
    return state.masterExamDegree.examDegreeData;
}

export const getMasterExamDegreeLink = (state) => {
    return state.masterExamDegree.examDegreeLinks;
}

export const getMasterExamDegreeMeta = (state) => {
    return state.masterExamDegree.examDegreeMeta;
}

export const getShowApproveRejectConfirmationModal = (state) => {
    return state.approveRejectExamDegree.showExamDegree;
}

export const getAction = (state) => {
    return state.approveRejectExamDegree.action;
}

export const getData = (state) => {
    return state.approveRejectExamDegree.data;
}

export const getShowEditModal = (state) => {
    return state.editExamDegree.showExamDegree;
}

