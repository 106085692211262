import { isEmpty } from 'lodash'
export const SET_AGREEMENTS_DATA = (state, data) => {
    state.agreemntList.data = data.data;
    state.agreemntList.links = data.links;
    state.agreemntList.meta = {
      current_page: data.current_page,
      from: data.from,
      to:  data.to,
      total: data.total,
      perPage: data.per_page
  }
}
export const setUserId = (state, data) => {
    state.user_id = data;
}
export const SET_USER_LIST = (state, data) => {
  state.userList.data = data.data.data.data
  state.userList.links = data.data.data.links
  state.userList.meta = {
    current_page: data.data.data.current_page,
    from: data.data.data.from,
    to:  data.data.data.to,
    total: data.data.data.total,
    perPage: data.data.data.per_page
  }
}
export const SetAgreementDetails =(state, data) => {
    state.agreementData = data;
}
export const setSettingsInclusion =(state, data) =>{
	state.inclusionsSettings = data;
}
export const setPlannerData =(state, data) =>{
	state.smartPlannerData = data;
}
export const setJiipaData =(state, data) =>{
	state.jiipaData = data;
}
export const showInclusion = (state, flag) =>{
  state.inclusions.showInclusion = flag;
}
export const hideInclusion = (state) =>{
  state.inclusions.showInclusion = false;
}
export const showWithPlanInclusion = (state, flag) => {
  state.inclusions.showWithPlanInclusion = flag;
}
export const hideWithPlanInclusion = (state) =>{
  state.inclusions.showWithPlanInclusion = false;
}
export const showSmartPlannerInclusion = (state, flag) =>{
  state.inclusions.showSmartPlannerInclusion = flag;
}
export const hideSmartPlannerInclusion = (state) =>{
  state.inclusions.showSmartPlannerInclusion = false;
}