export const getShowSlackWebhookURLModel = (state) => {
    return state.slackintegration.showSlackWebhookURLModel
}

export const getShowDeleteModule = (state) => {
    return state.slackintegration.showDeleteModule
}

// export const getSlackWebhook = (state, data) => {
// 	return state.slackWebhook.webhookData
// }

export const getSlackWebhookData = (state) => {
    return state.slackWebhook.webhookData;
}

export const getSlackWebhookLink = (state) => {
    return state.slackWebhook.webhookLinks;
}

export const getSlackWebhookMeta = (state) => {
    return state.slackWebhook.webhookMeta;
}

export const getActionCategory = (state) => {
    return state.slackWebhook.action;
}

export const getId = (state) => {
    return state.slackWebhook.id;
}

export const getSlackWebhookDataById = (state, data) => {
	return state.slackWebhook.data;
}