export default {
	contactList: {
		data: null,
        links: null,
        meta: null,
	},
    contactListByID: '',        
    replyListByID: '',        
   	showLoader: false,
}
