export default {

    masterUniversity:{
        universityData:null,
        universityLinks:null,
        universityMeta:null,
    },

    approveRejectUniversity: {
        showUniversity: false,
        action:'',
        data:''
    },
    editUniversity: {
        showUniversity: false,
    },
    
};