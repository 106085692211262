export const fetchDiscountPromotionPlanList = ({ commit, dispatch }, query = '') => {
    if(query && (query != undefined)){
        query = query+'&show_expire_plan=yes';
    }else{
        query = '?show_expire_plan=yes';
    }
    return window.axios.get(process.env.VUE_APP_SMART_RECRUIT_API_URL + 'api/discount-promotion-plan' + query).then((response) => {
        commit('SET_DISCOUNT_PROMOTION_PLAN_LIST', response.data);
        return true;
    }).catch((error) => {
        dispatch("auth/errorHandler",error.response.status,{ root:true });
        return false;
    })
}
export const postDiscountPromotionPlan = ({ commit,dispatch}, { payload, context}) => {
    return window.axios.post ('/api/discount-promotion-plan/'+payload.plan_id, payload.formData).then((response) => {
        // Showing  success message
        commit('SET_ALERT', 'success', { root: true })
        commit('SET_ALERT_MESSAGE', response.data.message, { root: true });
        return response.data;
    }).catch((error) => {
        dispatch("auth/errorHandler",error.response.status,{ root:true });
        commit('SET_ALERT', 'warning', { root: true })
        commit('SET_ALERT_MESSAGE', 'Something went wrong', { root: true });
        context.errors = error.response.data;
        return false;
    })
}

export const fetchDiscountPromotionPlan = ({ commit, dispatch}, { payload, context}) => {
    return window.axios.get ('/api/discount-promotion-plan/'+ payload.id).then((response) => {
        return response.data;
    }).catch((error) => {
        dispatch("auth/errorHandler",error.response.status,{ root:true });
        commit('SET_ALERT', 'warning', { root: true })
        commit('SET_ALERT_MESSAGE', 'Something went wrong', { root: true });
        context.errors = error.response.data;
        return false;
    })
}
export const deleteDiscountPromotionPlan = ({ commit, dispatch},payload) => {
    return window.axios.delete ('/api/discount-promotion-plan/'+ payload).then((response) => {
        // Showing  success message
        commit('SET_ALERT', 'success', { root: true })
        commit('SET_ALERT_MESSAGE', response.data.message, { root: true });
        return response.data.success;
    }).catch((error) => {
        dispatch("auth/errorHandler",error.response.status,{ root:true });
        commit('SET_ALERT', 'warning', { root: true })
        commit('SET_ALERT_MESSAGE', 'Something went wrong', { root: true });
        context.errors = error.response.data;
        return false;
    })
}