export default {
    agreemntList: {
		data: null,
		links: null,
		meta: null
	},
    user_id:'',
    userList: {
		data: null,
		links: null,
		meta: null
	},
    agreementData:'',
    inclusions : {
        showWithPlanInclusion:false,
        showInclusion: false,
        showTitpInclusion:false,
        showSmartPlannerInclusion:false,
    },
    inclusionsSettings:'',
    smartPlannerData:'',
    jiipaData:'',
    paymentData:'',
}
