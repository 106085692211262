export default {

    masterKeySkill:{
        keySkillsData:null,
        keySkillsLinks:null,
        keySkillsMeta:null,
    },

    approveRejectKeySkill: {
        showKeySkill: false,
        action:'',
        data:''
    },
    editKeySkill: {
        showKeySkill: false,
    },
    
};