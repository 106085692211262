export const fetchPersonal = ({ commit }, { context, payload }) => {
    var profile_id = "";
    if (payload.profile) {
      profile_id = "?personal_profile_id=" + payload.profile + "&employerView=" + payload.employerView;
    } else if (localStorage.getItem("setPersonalProfileId")) {
      profile_id = "?personal_profile_id=" + localStorage.getItem("setPersonalProfileId");
    }
  
    var language = payload && payload.language ? "?language=" + payload.language : "";
    return window.axios.get(process.env.VUE_APP_SMART_RECRUIT_API_URL + "api/get-js-profile-details" + profile_id + language)
      .then((response) => {
        commit("setJobseekerData", response.data);
        return response.data;
      })
      .catch((error) => {
        commit("SET_ALERT", "warning", { root: true });
        commit("SET_ALERT_MESSAGE", "Something went wrong", { root: true });
        return false;
      });
};


  