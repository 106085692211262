import { createI18n } from 'vue-i18n'
import { isEmpty } from 'lodash'

function loadLocaleMessages() {
  let currentLocale = localStorage.getItem('localforage/smartrecruitadmin/locale')
  if (isEmpty(currentLocale))
      currentLocale = 'en'
  try {
    return require(`./locales/${currentLocale.replace(/['"]+/g, '')}.json`).default
  } catch (error) {
    localStorage.setItem('localforage/smartrecruitadmin/locale', 'en')
    alert("Soory for the inconviniences. Language not supported yet")
    return require(`./locales/en.json`).default
  }
} 

function getCurrentLocale() {
  let currentLocale = 'en'
  if (!isEmpty(localStorage.getItem('localforage/smartrecruitadmin/locale'))) {
    currentLocale = localStorage.getItem('localforage/smartrecruitadmin/locale').replace(/['"]+/g, '')
  }
  return currentLocale
}
export default createI18n({
  locale: getCurrentLocale() || 'en',
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
  messages:  loadLocaleMessages()
})