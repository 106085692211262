export const showDeleteModel = (state, flag) => {
	state.showDeleteModel = flag;
}
export const hideDeleteModel = (state) => {
	state.showDeleteModel = false;
}

export const showInviteReferrals = (state, flag) => {
	state.showInviteReferrals = flag;
}
export const hideInviteReferrals = (state) => {
	state.showInviteReferrals = false;
}

export const SET_REFERRAL_LIST = (state, data) => {
    state.referralList.data = data.data.data;
    state.referralList.links = data.data.links;
	state.referralList.meta = {
		current_page: data.data.current_page,
		from: data.data.from,
		to:  data.data.to,
		total: data.data.total,
		perPage: data.data.per_page
	}
}
export const setReferralId = (state, data) => {
	state.referralList.referralId = data;
}