
export const allJobFair = ({ commit, state } , query = '') => {
  let timezone = state.client.timezone;
  
  return window.axios.get("api/job-fair/list/all" + query , { headers: { "x-timezone" : timezone} }).then((response) => {
  commit("set_jobfairs_data", response)
  
  return response.data.data.data;
  }).catch((error) => {
  // commit("SET_ALERT", "warning", { root: true });
  // commit("SET_ALERT_MESSAGE", "Something went wrong", { root: true });
  // commit('IS_LOADING', false, { root: true })
  //  errors = error.response.data.errors;
  return false;
  });   
    
};

export const organiserJobFair = ({ commit, state }, query = '') => {
  let timezone = state.client.timezone;
    
    return window.axios
    .get("api/job-fair/list/organiser" + query , { headers: {'x-timezone': timezone}})
    .then((response) => {
      commit("set_organiser_list", response)
        
      return response.data.data.data;
    })
    .catch((error) => {
      // commit("SET_ALERT", "warning", { root: true });
      // commit("SET_ALERT_MESSAGE", "Something went wrong", { root: true });
        
      //  errors = error.response.data.errors;
      return false;
    });   
};

export const subscribersList_ex = ({ commit, state }, query = '') => {
  let timezone = state.client.timezone;
    
  return window.axios
  .get("api/job-fair/list/subscribers/1"+ query, { headers: {"x-timezone" : timezone}})
  
  .then((response) => {
    commit("setExhibitorNAttendeeList", response);
      
    return response.data.data.data;
  })
  .catch((error) => {
    // commit("SET_ALERT", "warning", { root: true });
    // commit("SET_ALERT_MESSAGE", "Something went wrong", { root: true });
      
    //  errors = error.response.data.errors;
    return false;
  });   
};

export const subscribersList_at = ({ commit, state }, query = '') => {
  let timezone = state.client.timezone;
    
  return window.axios
  .get("api/job-fair/list/subscribers/2"+ query, { headers: {"x-timezone" : timezone}})
  
  .then((response) => {
    commit("setExhibitorNAttendeeList", response);
      
    
    return response.data.data.data;
  })
  .catch((error) => {
    // commit("SET_ALERT", "warning", { root: true });
    // commit("SET_ALERT_MESSAGE", "Something went wrong", { root: true });
      
    //  errors = error.response.data.errors;
    return false;
  });   
};

export const nonSubscribeJobFairs = ({ commit, state } , query = '') => {
  let timezone = state.client.timezone;
    
  return window.axios
  .get("api/job-fair/get/non-subscribe" + query, { headers: {"x-timezone" : timezone}})
  .then((response) => {
    commit("setNonSubscribedLists", response);
      
    return response.data.data;
  })
  .catch((error) => {
    // commit("SET_ALERT", "warning", { root: true });
    // commit("SET_ALERT_MESSAGE", "Something went wrong", { root: true });
      
    //  errors = error.response.data.errors;
    return false;
  });   
};

export const jobFairGetByIdCommon = ({ commit, state }, id) => {
  let timezone = state.client.timezone;
    
  return window.axios
  .get("api/job-fair/"+id+"", {headers: {"x-timezone": timezone}})
  .then((response) => {
    commit('setCommonJobfairById', response);
      
    return response.data.data;
  })
  .catch((error) => {
    // commit("SET_ALERT", "warning", { root: true });
    // commit("SET_ALERT_MESSAGE", "Something went wrong", { root: true });
     
    //  errors = error.response.data.errors;
    return false;
  });   
};
 
export const addJobFair = ({ commit, state } , {context, payload}) => {
  let timezone = state.client.timezone;
  return window.axios
  .post("api/job-fair", payload ,  {headers: {"x-timezone": timezone}})
  .then((response) => {
    return response.data;
  })
  .catch((error) => {
    commit("SET_ALERT", "warning", { root: true });
    commit('SET_ALERT_MESSAGE', error.response.statusText, { root: true })
    context.errors = error.response.data;
    return error.response.statusText;
  });   
};         
         
export const UpdateJobFair= ({ commit, state }, {id , payload, context}) => {
  let timezone = state.client.timezone;
  return window.axios
  .post("api/job-fair/update/"+id+"", payload, {headers: {"x-timezone": timezone}})
  .then((response) => {
    return response.data;
  })
  .catch((error) => {
    // commit("SET_ALERT", "warning", { root: true });
    // commit("SET_ALERT_MESSAGE", "Something went wrong", { root: true });
    context.errors = error.response.data;
    return error.response.statusText;
  });   
};

export const industryType = ({ commit }) => {
  return window.axios
  .get("api/industry")
  .then((response) => {
    commit("set_industry_types", response.data.data);
    return response.data.data;
  })
  .catch((error) => {
    // commit("SET_ALERT", "warning", { root: true });
    // commit("SET_ALERT_MESSAGE", "Something went wrong", { root: true });
    //  errors = error.response.data.errors;
    return false;
  });   
};

export const deleteJobFair = ({commit, state}, id) => {
  let timezone = state.client.timezone;
  return window.axios.delete("api/job-fair/delete/"+id+"", {headers: {"x-timezone": timezone}}).then((response)=>{
  return response.data.data;
  }).catch((error)=>{
    // commit("SET_ALERT", "warning", { root: true });
    // commit("SET_ALERT_MESSAGE", "Something went wrong", { root: true });
    //  context.errors = error.response.data;
    return error.response.data;
  })
};

export const subscribeJobfair = ({ commit, state } , {context, payload}) => {
  let timezone = state.client.timezone;
  return window.axios
  .post("api/job-fair/subscribe", payload, {headers: {"x-timezone": timezone}})
  .then((response) => {
    return response.data;
  })
  .catch((error) => {
    // commit("SET_ALERT", "warning", { root: true });
    // commit("SET_ALERT_MESSAGE", "Something went wrong", { root: true });
    context.errors = error.response.data;
    return false;
  });   
}; 

export const subscriptionRequest = ({ commit, state } , {context, payload}) => {
  let timezone = state.client.timezone;
  return window.axios
  .post("api/job-fair/acceptOrDeclineSubscriptionRequest", payload, {headers: {"x-timezone": timezone}})
  // .post(process.env.VUE_APP_API_URL + "api/job-fair", payload)
  .then((response) => {
    return response.data;
  })
  .catch((error) => {
    // commit("SET_ALERT", "warning", { root: true });
    // commit("SET_ALERT_MESSAGE", "Something went wrong", { root: true });
    context.errors = error.response.data;
    return false;
  });   
}; 

export const unsubscribe = ({ commit } , {context, payload}) => {
  return window.axios
    .post("api/job-fair/unsubscribe", payload)
    // .post(process.env.VUE_APP_API_URL + "api/job-fair", payload)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      commit("SET_ALERT", "warning", { root: true });
      commit("SET_ALERT_MESSAGE", "Something went wrong", { root: true });
      context.errors = error.response.data;
      return false;
    });   
}; 

export const cancelJobfair = ({ commit, state } , id) => {
  let timezone = state.client.timezone;
  return window.axios
    .put("api/job-fair/cancel/"+id+"", {headers:{"x-timezone": timezone}})
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      // context.errors = error.response.data;
      return error.response.data;
    });   
}; 

export const rescheduleJobfair = ({ commit, state } ,  {id , payload, context}) => {
  let timezone = state.client.timezone;
  return window.axios
    .post("api/job-fair/reschedule/"+id+"", payload, {headers:{"x-timezone": timezone}})
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      commit("SET_ALERT", "warning", { root: true });
      commit("SET_ALERT_MESSAGE", "Something went wrong", { root: true });
      // context.errors = error.response.data;
      return error.response.data;
    });   
}; 


// subscribers of jobfair - users list api
export const subscribersList_exhibitor = ({ commit } ,  {id,category,status, query = ''}) => {
  return window.axios
    .get("api/job-fair/subscribers/"+id+"/"+category+"/"+status+"" + query)
    .then((response) => {
      commit("set_subscribers_list", response) 
      return response.data;
    })
    .catch((error) => {
      commit("SET_ALERT", "warning", { root: true });
      commit("SET_ALERT_MESSAGE", "Something went wrong", { root: true });
      return false;
    });   
}; 

export const subscribersList_attendee = ({ commit } ,  {id,category,status, query = ''}) => {
  return window.axios
    .get("api/job-fair/subscribers/"+id+"/"+category+"/"+status+"" + query)
    .then((response) => {
      commit("set_subscribers_list_attendee", response) 
      return response.data;
    })
    .catch((error) => {
      commit("SET_ALERT", "warning", { root: true });
      commit("SET_ALERT_MESSAGE", "Something went wrong", { root: true });
      return false;
    });   
}; 

export const timezoneConverter = ({commit} , {payload}) => {
  return window.axios
  .post("api/tz-converter", payload)
  .then((response) => {
    commit("setTzConverter", response);
    return response.data;
  })
  .catch((error) => {
    // commit("SET_ALERT", "warning", { root: true });
    // commit("SET_ALERT_MESSAGE", "Something went wrong", { root: true });
    context.errors = error.response.data;
    return error.response.data;
  });  
};
