export default {

    masterIndustrySubType:{
        industrySubTypeData:null,
        industrySubTypeLinks:null,
        industrySubTypeMeta:null,
    },

    approveRejectIndustrySubType: {
        showIndustrySubType: false,
        action:'',
        data:''
    },
    editIndustrySubType: {
        showIndustrySubType: false,
    },
    
};