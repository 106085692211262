import { createRouter, createWebHistory } from 'vue-router'
import { routes as routes } from '../app/index'

// import Home from '../views/Home.vue'

import PageNotFound from '../views/PageNotFound.vue'
import beforeEach from './beforeEach'

const otherRoutes = [
// {
//   path: '/',
//   name: 'Home',
//   component: () => import('../views/SignIn.vue')

// },

// {
//   path: '/sign-in',
//   name: 'Sign-In',
//   component: () => import('../views/SignIn.vue')
// },
// {
//   path: '/forgot-password',
//   name: 'forgot-password',
//   component: () => import('../views/ForgotPassword.vue')
// },
// {
//   path: '/reset-password',
//   name: 'reset-password',
//   component: () => import('../views/ResetPassword.vue')
// },

// {
//   path: '/role-and-permission',
//   name: 'Role-And-Permission',
//   component: () => import('../views/RoleAndPermission.vue')
// },

// {
//   path: '/role-add',
//   name: 'role-add',
//   component: () => import('../views/RoleAdd.vue')
// },
// {
//   path: '/role-edit',
//   name: 'role-edit',
//   component: () => import('../views/RoleEdit.vue')
// },
// {
//   path: '/role-view',
//   name: 'role-view',
//   component: () => import('../views/RoleView.vue')
// },

// {
//   path: '/settings',
//   name: 'Settings',
//   component: () => import('../views/Settings.vue')
// },



// {
//   path: '/localization',
//   name: 'Localization',
//   component: () => import('../views/Localization.vue')
// },
 
{
  path: '/agreements',
  name: 'Agreements',
  component: () => import('../views/Agreements.vue')
},

{
  path: '/agreements-functions',
  name: 'Agreements-Functions',
  component: () => import('../views/AgreementsFunctions.vue')
},

{
  path: '/edit-agreements',
  name: 'Edit-Agreements',
  component: () => import('../views/EditAgreements.vue')
},

{ 
  path: '/customer-non-admin-profile',
  name: 'customer-non-admin-profile',
  component: () => import('../views/CustomerNonAdminProfile.vue')

},

{
  path: '/list-error-log',
  name: 'ListErrorLog',
  component: () => import('../views/ListErrorLog.vue')
},

// {
//   path: '/activity-log',
//   name: 'ActivityLog',
//   component: () => import('../views/ActivityLog.vue')
// },

{
  path: '/about',
  name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },

  {
    path: '/:pathMatch(.*)*',
    name: '404',
    component: PageNotFound
  },
  
  ]

for (let i = 0; i < otherRoutes.length; i++){
    routes.push(otherRoutes[i]);
  }

  const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
     scrollBehavior() {
        document.getElementById('app').scrollIntoView();
    }
  })

  router.beforeEach(beforeEach)
  export default router
