export default {
	comission: {
		EmOjJp:'',
		EmAmJp:'',
		EmOjTj:'',
		EmAmTj:'',
		EmOjAmP:'',

		HcOjJp:'',
		HcAmJp:'',
		HcOjTj:'',
		HcAmTj:'',
		HcOjAmP:'',

		RaOjJp:'',
		RaAmJp:'',
		RaOjTj:'',
		RaAmTj:'',
		RaOjAmP:'',

		SoOjJp:'',
		SoAmJp:'',
		SoOjTj:'',
		SoAmTj:'',
		SoOjAmP:'',

		PaySupOrg:'',
		PaySenOrg:'',	
	},
}