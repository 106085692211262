export const getMasterKeySkillData = (state) => {
    return state.masterKeySkill.keySkillsData;
}

export const getMasterKeySkillLink = (state) => {
    return state.masterKeySkill.keySkillsLinks;
}

export const getMasterKeySkillMeta = (state) => {
    return state.masterKeySkill.keySkillsMeta;
}

export const getShowApproveRejectConfirmationModal = (state) => {
    return state.approveRejectKeySkill.showKeySkill;
}

export const getAction = (state) => {
    return state.approveRejectKeySkill.action;
}

export const getData = (state) => {
    return state.approveRejectKeySkill.data;
}

export const getShowEditModal = (state) => {
    return state.editKeySkill.showKeySkill;
}

