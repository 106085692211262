export default {

    masterPosition:{
        positionData:null,
        positionLinks:null,
        positionMeta:null,
    },

    approveRejectPosition: {
        showPosition: false,
        action:'',
        data:''
    },
    editPosition: {
        showPosition: false,
    },
    
};