export const fetchReferralList = ({ commit, dispatch }, query = '') => {
    return window.axios.get(process.env.VUE_APP_SMART_RECRUIT_API_URL + 'api/referrals' + query).then((response) => {
        commit('SET_REFERRAL_LIST', response.data);
        return true;
    }).catch((error) => {
        return false;
    })
}
export const inviteReferral = ({ commit  }, {context, payload }) => {
    return window.axios.post (process.env.VUE_APP_SMART_RECRUIT_API_URL +'api/referrals', payload).then((response) => {
        // Showing  success message
        commit('SET_ALERT', 'success', { root: true })
        commit('SET_ALERT_MESSAGE', response.data.message, { root: true })
        return response.data;
    }).catch((error) => {
       	commit('SET_ALERT', 'warning', { root: true })
        commit('SET_ALERT_MESSAGE', 'Something went wrong', { root: true })
        context.errors = error.response.data;
        return error.response.data;
    })
}
export const sendReminderReferrals= ({ commit  }, {context, payload }) => {
    return window.axios.post (process.env.VUE_APP_SMART_RECRUIT_API_URL +'api/referral-send-reminder', payload).then((response) => {
        // Showing  success message
        // commit('SET_ALERT', 'success', { root: true })
        // commit('SET_ALERT_MESSAGE', response.data.message, { root: true })
        return response.data;
    }).catch((error) => {
       	commit('SET_ALERT', 'warning', { root: true })
        commit('SET_ALERT_MESSAGE', 'Something went wrong', { root: true })
        context.errors = error.response.data
        return error.response.data;
    })
}
export const deleteReferralPlan = ({ commit}, id) => {
    return window.axios.delete(process.env.VUE_APP_SMART_RECRUIT_API_URL + 'api/referrals/'+id).then((response) => {
        // commit('SET_ALERT', 'success', { root: true })
        // commit("SET_ALERT_MESSAGE", response.data.message, {root: true});
        return response.data;
    }).catch((error) => {
        commit("SET_ALERT", "warning", { root: true });
        commit("SET_ALERT_MESSAGE", "Something went wrong", { root: true });
        return false;
    });   
};