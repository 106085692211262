export const showDeleteSlackFunction = (state, flag) => {
	state.showDeleteSlackFunction = flag
}

export const editFunctionId = (state, id) => {
	state.editFunctionId = id;
}

export const SET_SLACK_FUNCTION_LIST = (state, data) => {
	state.slackFunction.data = data.data.data;
    state.slackFunction.links = data.data.links;
	state.slackFunction.meta = {
		current_page: data.data.current_page,
		from: data.data.from,
		to:  data.data.to,
		total: data.data.total,
		perPage: data.data.per_page
	}
}

export const SET_SLACK_MODULE_LIST = (state, data) => {
	state.slackModule.data = data.data.data
    
}