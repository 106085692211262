export default [
    {
        path: '/settings/slack-integration',
        component: () => import('../components/SlackIntegration.vue'),
        name: 'slack-integration',
        meta: {		
            guest: false,
            needsAuth: true,	
        }
    },
]