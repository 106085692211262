<template>
  <div>  
  <Header/>
  </div>
</template>

<script>

import Header from '../components/partial/Header.vue'

export default  ({
  data: function() {
    return {
      
    }
  },
  components: {
     Header,
 },
});
</script>

<style>

.imageBox {
  position: relative;
  width: 50%;
}

.slidImage {
  opacity: 1;
  display: block;
  width: 100%;
  height: auto;
  transition: .5s ease;
  backface-visibility: hidden;
}

.middle {
  transition: .5s ease;
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
}

.imageBox:hover .slidImage {
  opacity: 0.3;
}

.imageBox:hover .middle {
  opacity: 1;
}


  </style>