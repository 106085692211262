export const set_jobfairs_data = (state, data) => {
    state.jobfairs.all_data =  data.data.data.data
    state.jobfairs.all_links = data.data.data
}

export const set_organiser_list = (state, data) => {
    state.jobfairs.organiser_list_data =  data.data.data.data
    state.jobfairs.organiser_list_links = data.data.data
}

export const set_industry_types = (state, data) => {
    state.jobfairs.industryTypes =  data;
}
export const set_jobfair_id = (state, id) => {
    state.jobfairs.jobfairId =  id;
}

export const set_jobfair_delete_modal = (state, flag) => {
    state.jobfairs.showDeleteJobFair = flag;
}
    
export const hide_jobfair_delete_modal  = (state) => {
    state.jobfairs.showDeleteJobFair = false;
}

export const setExhibitorNAttendeeList  = (state, response) => {
   
    state.jobfairs.exhibitorNAttendeeList = response.data.data.data;
    state.jobfairs.exhibitorNAttendeeListLinks = response.data.data;
    
}

export const setNonSubscribedLists  = (state, response) => {
    state.jobfairs.nonSubscribedList = response.data.data.data;
    state.jobfairs.nonSubscribedListLinks = response.data.data;
}

export const showReschedule  = (state, data) => {
    state.jobfairs.reschedule.reschedule = data.flag;
    state.jobfairs.reschedule.jobfair_id = data.id;
}
export const hideReschedule  = (state) => {
    state.jobfairs.reschedule.reschedule = false;
}
export const showCancel  = (state, data) => {
    state.jobfairs.cancel.cancelJobfair = data.flag;
    state.jobfairs.cancel.jobfair_id = data.id;
}
export const hideCancel  = (state) => {
    state.jobfairs.cancel.cancelJobfair = false;
}
export const showAcceptDecline  = (state, data) => {
    state.jobfairs.acceptDeclineModal.acceptDecline = data.status;
    state.jobfairs.acceptDeclineModal.msg = data.msg;
    state.jobfairs.acceptDeclineModal.title = data.title;
}
export const hideAcceptDecline  = (state) => {
    state.jobfairs.acceptDeclineModal.acceptDecline = false;
}
export const set_subscribers_list  = (state, response) => {
    state.jobfairs.subscriberList.subscribers = response.data.data.data;
    state.jobfairs.subscriberList.subscribers_links = response.data.data;
}
export const set_subscribers_list_attendee  = (state, response) => {
    state.jobfairs.subscriberList_attendee.subscribers = response.data.data.data;
    state.jobfairs.subscriberList_attendee.subscribers_links = response.data.data;
}

export const set_subscribed_as  = (state, data) => {
    state.jobfairs.subscribed_as= data;
}

export const setStartDate =  (state,data) =>{
    state.datetime.startDate = data.date;
}

export const setEndDate =  (state,data) =>{
    state.datetime.endDate = data.date;
}

export const setStartTime =  (state,data) =>{
 state.datetime.startTime = data.time;
}

export const setEndTime =  (state,data) =>{
 state.datetime.endTime = data.time;
}

export const setTimezone =  (state,data) =>{
 state.datetime.timezone = data.timezone;
}

export const setCommonJobfairById = (state, response) => {
 state.jobfairs.commonJobfairById = response.data.data;
}

export const setClientTimezone = (state, data) => {
 state.client.timezone = data.timezone;
}

export const setTzConverter = (state, response) =>{
 state.converter.data = response.data.data;
}