export default {

    masterJobFairType:{
        jobFairTypeData:null,
        jobFairTypeLinks:null,
        jobFairTypeMeta:null,
    },

    approveRejectJobFairType: {
        showJobFairType: false,
        action:'',
        data:''
    },
    editJobFairType: {
        showJobFairType: false,
    },
    
};