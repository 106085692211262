export const getBlockListData = (state) => {
	return state.blocklist.data
}

export const getBlockListLinks = (state) => {
	return state.blocklist.links
}

export const getShowBlockModal = (state) =>{
	return state.showBlockModal
}

export const getHideBlockModal = (state) =>{
	return state.hideBlockModal
}

export const getShowUnblockModal = (state) =>{
	return state.showUnblockModal
}

export const getHideUnblockModal = (state) =>{
	return state.hideUnblockModal
}

export const getId = (state) => {
	return state.blocklist.id
}

export const getName = (state) => {
	return state.blocklist.name
}

export const getBlockUnblock = (state) => {
	return state.blocklist.block
}