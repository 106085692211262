export default {

    relocationServices:{
        relocationServicesData:null,
        relocationServicesLinks:null,
        relocationServicesMeta:null,
    },
    relocation:{
        relocationDataById:null,
        relocationReplyDataById:null
    },
};