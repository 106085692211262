export const getMasterLanguageLevelData = (state) => {
    return state.masterLanguageLevel.languageLevelData;
}

export const getMasterLanguageLevelLink = (state) => {
    return state.masterLanguageLevel.languageLevelLinks;
}

export const getMasterLanguageLevelMeta = (state) => {
    return state.masterLanguageLevel.languageLevelMeta;
}

export const getShowApproveRejectConfirmationModal = (state) => {
    return state.approveRejectLanguageLevel.showLanguageLevel;
}

export const getAction = (state) => {
    return state.approveRejectLanguageLevel.action;
}

export const getData = (state) => {
    return state.approveRejectLanguageLevel.data;
}

export const getShowEditModal = (state) => {
    return state.editLanguageLevel.showLanguageLevel;
}

