export const setMasterJobTitleData = (state, data) => {
	state.masterJobTitle.jobTitleData = data.data.data;
	state.masterJobTitle.jobTitleLinks = data.data.links;

    state.masterJobTitle.jobTitleMeta = {
        current_page: data.data.current_page,
        from: data.data.from,
        to:  data.data.to,
        total: data.data.total,
        perPage: data.data.per_page
    }
}

export const showApproveRejectConfirmationModal = (state, flag) => {
    state.approveRejectJobTitle.showJobTitle = flag;
};

export const hideApproveRejectConfirmationModal = (state) => {
    state.approveRejectJobTitle.showJobTitle = false;
};

export const setAction = (state, action) => {
    state.approveRejectJobTitle.action = action;
}

export const setData = (state, data) => {
    state.approveRejectJobTitle.data = data;
}

export const showEditModal = (state, flag) => {
    state.editJobTitle.showJobTitle = flag;
};

export const hideEditModal = (state) => {
    state.editJobTitle.showJobTitle = false;
};
