
export default [
    {
      path: "/master-data-position",
      component: () => import("../components/Position.vue"),
      name: "Master-Data-Position",
      meta: {
        guest: false,
        needsAuth: true,
      },
    },
  ];