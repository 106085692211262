export default {

    masterJobTitle:{
        jobTitleData:null,
        jobTitleLinks:null,
        jobTitleMeta:null,
    },

    approveRejectJobTitle: {
        showJobTitle: false,
        action:'',
        data:''
    },
    editJobTitle: {
        showJobTitle: false,
    },
    
};