export default {
	showDeleteSlackFunction: false,
	slackFunction: {
		data: null,
		links: null,
		meta: null,
	},
	slackModule: {
		data: null,
	},
	editFunctionId:''
}
