export default {

    masterFunctionArea:{
        functionAreaData:null,
        functionAreaLinks:null,
        functionAreaMeta:null,
    },

    approveRejectFunctionArea: {
        showFunctionArea: false,
        action:'',
        data:''
    },
    editFunctionArea: {
        showFunctionArea: false,
    },
    
};