export default {

    titpDocument:{
        data:null,
        category:null,
        sub_category:null,
    },

    addTitpCommonDocument: {
        showTitpCommonDocument: false,
        action:''
    },

    addTitpCategory: {
        showTitpCategory: false,
        action:'',
        id:''
    },

    titpCategory:{
        categoryData:null,
        categoryLinks:null,
        categoryMeta:null,
    },
    titpCategoryById:{
        data:null
    },
};