export default {

    masterVisaStatus:{
        visaStatusData:null,
        visaStatusLinks:null,
        visaStatusMeta:null,
    },

    approveRejectVisaStatus: {
        showVisaStatus: false,
        action:'',
        data:''
    },
    editVisaStatus: {
        showVisaStatus: false,
    },
    
};