export default {
	general: {
		base_url:'',
        site_access_reminder_no:'',
        site_access_reminder_type:'',
        hiring_authorities_non_titp:'',
        hiring_authorities_titp:'',
        advertisements_per_week:'',
        job_fairs_no_allowed:'',
        consumption_tax:'',
        tax_registration_number:'',
        referrals_jobseekers_rewards:'',
        referrals_companies_discount:'',
        referrals_jobseeker_commission:'',
	},	
    min_contract_duration:'',
}