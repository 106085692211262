export const setMasterEmployerTypeData = (state, data) => {
	state.masterEmployerType.employerTypeData = data.data.data;
	state.masterEmployerType.employerTypeLinks = data.data.links;

    state.masterEmployerType.employerTypeMeta = {
        current_page: data.data.current_page,
        from: data.data.from,
        to:  data.data.to,
        total: data.data.total,
        perPage: data.data.per_page
    }
}

export const showApproveRejectConfirmationModal = (state, flag) => {
    state.approveRejectEmployerType.showEmployerType = flag;
};

export const hideApproveRejectConfirmationModal = (state) => {
    state.approveRejectEmployerType.showEmployerType = false;
};

export const setAction = (state, action) => {
    state.approveRejectEmployerType.action = action;
}

export const setData = (state, data) => {
    state.approveRejectEmployerType.data = data;
}

export const showEditModal = (state, flag) => {
    state.editEmployerType.showEmployerType = flag;
};

export const hideEditModal = (state) => {
    state.editEmployerType.showEmployerType = false;
};
