export default {

    masterExamDegree:{
        examDegreeData:null,
        examDegreeLinks:null,
        examDegreeMeta:null,
    },

    approveRejectExamDegree: {
        showExamDegree: false,
        action:'',
        data:''
    },
    editExamDegree: {
        showExamDegree: false,
    },
    
};