export default [
{
	path: '/contact-us',
	component: () => import('../components/ContactUs.vue'),
	name: 'contact-us',	
	meta: {
		guest: false,
		needsAuth: true
	}
},
{
	path: '/reply/:id',
	component: () => import('../components/partials/ReplySection.vue'),
	name: 'reply',	
	meta: {
		guest: false,
		needsAuth: true
	}
},
]
