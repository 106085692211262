export const getMasterFunctionAreaData = (state) => {
    return state.masterFunctionArea.functionAreaData;
}

export const getMasterFunctionAreaLink = (state) => {
    return state.masterFunctionArea.functionAreaLinks;
}

export const getMasterFunctionAreaMeta = (state) => {
    return state.masterFunctionArea.functionAreaMeta;
}

export const getShowApproveRejectConfirmationModal = (state) => {
    return state.approveRejectFunctionArea.showFunctionArea;
}

export const getAction = (state) => {
    return state.approveRejectFunctionArea.action;
}

export const getData = (state) => {
    return state.approveRejectFunctionArea.data;
}

export const getShowEditModal = (state) => {
    return state.editFunctionArea.showFunctionArea;
}

