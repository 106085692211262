export const setRelocationServicesData = (state, data) => {
	state.relocationServices.relocationServicesData = data.data.data;
	state.relocationServices.relocationServicesLinks = data.data.links;

    state.relocationServices.relocationServicesMeta = {
        current_page: data.data.current_page,
        from: data.data.from,
        to:  data.data.to,
        total: data.data.total,
        perPage: data.data.per_page
    }
}
export const setRelocationServicesDataById = (state, data) => {
	state.relocation.relocationDataById = data.data.data;
}
export const setRelocationServicesReplyDataById = (state, data) => {
	state.relocation.relocationReplyDataById = data.data.data;
}
