export const setAdvertiesment = (state, data) => {
    state.advertisment = data
};


export const setJobFairs = (state, data) => {
    state.jobFairs = data
};


export const setRefferals = (state, data) => {
    state.refferals = data
};


export const setJobSeekerEmployerData = (state, data) => {
    state.jobSeekerEmployer = data
};


export const setglobalJobProjectsData = (state, data) => {
    state.globalJobProjects = data
};