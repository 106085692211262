export const showSlackWebhookURLModel = (state, flag) => {
    state.slackintegration.showSlackWebhookURLModel = flag;
}

export const showDeleteModule = (state, flag) => {
    state.slackintegration.showDeleteModule = flag;
}

// export const setSlackWebhook = (state, data) => {
// 	state.slackWebhook.webhookData = data.data.data
// }

export const setSlackWebhook = (state, data) => {
	state.slackWebhook.webhookData = data.data.data.data;
	state.slackWebhook.webhookLinks = data.data.data.links;

    state.slackWebhook.webhookMeta = {
        current_page: data.data.data.current_page,
        from: data.data.data.from,
        to:  data.data.data.to,
        total: data.data.data.total,
        perPage: data.data.data.per_page
    }
}

export const setActionCategory = (state, action) => {
    state.slackWebhook.action = action;
};

export const setId = (state, id) => {
    state.slackWebhook.id = id;
}

export const setSlackWebhookDataById = (state, data) => {
	state.slackWebhook.data = data.data.data
}