export const getAdvertisment = (state) => {
	return state.advertisment
}

export const getJobFairs = (state) => {
	return state.jobFairs
}


export const getRefferals = (state) => {
	return state.refferals
}


export const getJobSeekerEmployerData = (state) => {
	return state.jobSeekerEmployer
}

export const getglobalJobProjectsData = (state) => {
	return state.globalJobProjects
}