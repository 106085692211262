export const setComissionData = (state, data) => {
	state.comission.EmOjJp = data.data.data.EmOjJp
	state.comission.EmAmJp = data.data.data.EmAmJp
	state.comission.EmOjTj = data.data.data.EmOjTj
	state.comission.EmAmTj = data.data.data.EmAmTj
	state.comission.EmOjAmP = data.data.data.EmOjAmP

	state.comission.HcOjJp = data.data.data.HcOjJp
	state.comission.HcAmJp = data.data.data.HcAmJp
	state.comission.HcOjTj = data.data.data.HcOjTj
	state.comission.HcAmTj = data.data.data.HcAmTj
	state.comission.HcOjAmP = data.data.data.HcOjAmP

	state.comission.RaOjJp = data.data.data.RaOjJp
	state.comission.RaAmJp = data.data.data.RaAmJp
	state.comission.RaOjTj = data.data.data.RaOjTj
	state.comission.RaAmTj = data.data.data.RaAmTj
	state.comission.RaOjAmP = data.data.data.RaOjAmP

	state.comission.SoOjJp = data.data.data.SoOjJp
	state.comission.SoAmJp = data.data.data.SoAmJp
	state.comission.SoOjTj = data.data.data.SoOjTj
	state.comission.SoAmTj = data.data.data.SoAmTj
	state.comission.SoOjAmP = data.data.data.SoOjAmP

	state.comission.PaySupOrg = data.data.data.PaySupOrg
	state.comission.PaySenOrg = data.data.data.PaySenOrg

}