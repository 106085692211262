export default [
    {
      path: "/master-data-course-minor",
      component: () => import("../components/CourseMinor.vue"),
      name: "Master-Data-Course-Minor",
      meta: {
        guest: false,
        needsAuth: true,
      },
    },
  ];