export default [
    {
        path: '/localization',
        component: () => import('../components/Localization.vue'),
        name: 'localization',
        meta: {		
            guest: false,
            needsAuth: true,	
        }
    },
    {
        path: '/international',
        component: () => import('../components/Internationalization.vue'),
        name: 'international',
        meta: {		
            guest: false,
            needsAuth: true,	
        }
    },
]
