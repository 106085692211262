export default {

    masterStrength:{
        strengthData:null,
        strengthLinks:null,
        strengthMeta:null,
    },

    approveRejectStrength: {
        showStrength: false,
        action:'',
        data:''
    },
    editStrength: {
        showStrength: false,
    },
    
};