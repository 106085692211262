export default {

    masterLanguage:{
        languageData:null,
        languageLinks:null,
        languageMeta:null,
    },

    approveRejectLanguage: {
        showLanguage: false,
        action:'',
        data:''
    },
    editLanguage: {
        showLanguage: false,
    },
    
};