export default [
{
	path: '/jobs-list',
	component: () => import('../components/JobList.vue'),
	name: 'jobslist',
	
	meta: {
		guest: false,
		needsAuth: true
	}
},
{
        path: '/manage-jobs',
        name: 'JobViewList',
        component: () => import ('../components/JobViewList.vue'),
        meta: {
            guest: false,
            needsAuth: true
        }
    },

    {
        path: '/manage-jobs-edit/:id',
        component: () => import('../components/JobEdit.vue'),
        name: 'edit-job-by-id',
        meta: { 
            guest: false,
            needsAuth: true,        
        }
    },

    {
        path: '/employer-view-job/:id',
        component: () => import('../components/EmployerViewJob.vue'),
        name: 'employer-view-job',
        meta: { 
            guest: false,
            needsAuth: true,        
        }
    },

    

]
