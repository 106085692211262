export const getMasterLanguageData = (state) => {
    return state.masterLanguage.languageData;
}

export const getMasterLanguageLink = (state) => {
    return state.masterLanguage.languageLinks;
}

export const getMasterLanguageMeta = (state) => {
    return state.masterLanguage.languageMeta;
}

export const getShowApproveRejectConfirmationModal = (state) => {
    return state.approveRejectLanguage.showLanguage;
}

export const getAction = (state) => {
    return state.approveRejectLanguage.action;
}

export const getData = (state) => {
    return state.approveRejectLanguage.data;
}

export const getShowEditModal = (state) => {
    return state.editLanguage.showLanguage;
}