export const fetchContactList = ({ commit } , query = '') => {
    return window.axios
      .get(process.env.VUE_APP_SMART_RECRUIT_API_URL + "api/contact-us" + query)
      .then((response) => {
        commit("setContactList", response)
        return response.data.data.data;
      })
      .catch((error) => {
        return false;
    });   
};

export const fetchContactListByID = ({ commit}, id) => {
    return window.axios.get(process.env.VUE_APP_SMART_RECRUIT_API_URL + 'api/contact-us/'+id).then((response) => {
        commit('setContactListByID', response.data.contactUsInfo)
        return response.data.success;
    }).catch((error) => {
        commit("SET_ALERT", "warning", { root: true });
        commit("SET_ALERT_MESSAGE", "Something went wrong", { root: true });
        return false;
    });   
};

export const replyMessage = ({commit}, {payload, id, context})=>{
    return window.axios.post('api/contact-us-reply/' + id,payload).then((response)=>{
        // commit('SET_ALERT', 'success', { root: true })
        // commit('SET_ALERT_MESSAGE', response.data.message, { root: true })
        return response.data.success;
    }).catch((error)=>{
        commit('SET_ALERT', 'warning', { root: true })
        commit('SET_ALERT_MESSAGE', 'Something went wrong', { root: true })
        context.errors = error.response.data
        return false
    })
}

export const fetchReplyListByID = ({ commit}, id) => {
    return window.axios.get(process.env.VUE_APP_SMART_RECRUIT_API_URL + 'api/contact-us-reply-list/'+id).then((response) => {
        commit('setReplyListByID', response.data.data)
        return response.data.success;
    }).catch((error) => {
        commit("SET_ALERT", "warning", { root: true });
        commit("SET_ALERT_MESSAGE", "Something went wrong", { root: true });
        return false;
    });   
};

