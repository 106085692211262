import { createStore } from 'vuex'
import state from "./state.js";
import getters from "./getters.js";
import mutations from "./mutations.js";
import actions from "./actions.js";
import financeAndAudit from "../app/financeAndAudit/vuex";
import UserList from "./modules/UserList.js";
// import MembershipPlan from "./modules/MembershipPlan.js";
import roleAndPermission from '../app/roleAndPermission/vuex/index.js'
import ListErrorLog from "./modules/ListErrorLog.js";
import auth from "../app/auth/vuex";
import ChangePasswordModel from "./modules/ChangePasswordModel.js";
import superAdminSetting from "../app/superAdminSetting/vuex";
import MembershipPlan from "../app/MembershipPlan/vuex";
import users from "../app/users/vuex"
import referrals from "../app/referrals/vuex"
import profile from "../app/profile/vuex"
import jobFairs from "../app/jobFairs/vuex"
import agreements from "../app/agreements/vuex"
import jobs from "../app/jobs/vuex"
import advertisements from "../app/advertisements/vuex"
import feedbackReviews from "../app/feedbackReviews/vuex/index.js";
import contactUs from "../app/contactUs/vuex/index.js";
import footer from "../app/footer/vuex/index.js";
import PromotionMessages from "../app/PromotionMessages/vuex/index.js";
import EmailTemplate from "../app/EmailTemplate/vuex/index.js";
import employerJobFair from "../app/employerJobFair/vuex";
import blockList from "../app/blockList/vuex"
import dashboard from "../app/dashboard/vuex/index.js";
import settingsGeneral from "../app/settingsGeneral/vuex/index.js";
import settingsSlackFuntion from "../app/settingsSlackFuntion/vuex/index.js";
import settingsFinanceAudit from "../app/settingsFinanceAudit/vuex/index.js";
import settingsAutoEmailDispatch from "../app/settingsAutoEmailDispatch/vuex/index.js";
import settingsComission from "../app/settingsComission/vuex/index.js";
import settingsDiscountPromotionPlans from "../app/settingsDiscountPromotionPlans/vuex/index.js";
import settingsContractAgreements from "../app/settingsContractAgreements/vuex/index.js";
import settingsFileServer from "../app/settingsFileServer/vuex/index.js";
import settingsTitpRestrictions from "../app/settingsTitpRestrictions/vuex/index.js";
import settingsTitpDocuments from "../app/settingsTitpDocuments/vuex/index.js";
import settingsSlackIntegration from "../app/settingsSlackIntegration/vuex/index.js";
import localization from "../app/localization/vuex/index.js";
import MasterDataLanguage from "../app/MasterDataLanguage/vuex/index.js";
import MasterDataLanguageLevel from "../app/MasterDataLanguageLevel/vuex/index.js";
import MasterDataVisaStatus from "../app/MasterDataVisaStatus/vuex/index.js";
import MasterDataExamDegree from "../app/MasterDataExamDegree/vuex/index.js";
import MasterDataCourseMinor from "../app/MasterDataCourseMinor/vuex/index.js";
import MasterDataUniversity from "../app/MasterDataUniversity/vuex/index.js";
import MasterDataStrength from "../app/MasterDataStrength/vuex/index.js";
import MasterDataKeySkill from "../app/MasterDataKeySkill/vuex/index.js";
import MasterDataFunctionArea from "../app/MasterDataFunctionArea/vuex/index.js";
import MasterDataPosition from "../app/MasterDataPosition/vuex/index.js";
import MasterDataJobTitle from "../app/MasterDataJobTitle/vuex/index.js";
import MasterDataWorkStyle from "../app/MasterDataWorkStyle/vuex/index.js";
import MasterDataIndustryType from "../app/MasterDataIndustryType/vuex/index.js";
import MasterDataIndustrySubType from "../app/MasterDataIndustrySubType/vuex/index.js";
import MasterDataEmployerType from "../app/MasterDataEmployerType/vuex/index.js";
import MasterDataJobFairType from "../app/MasterDataJobFairType/vuex/index.js";
import RelocationServices from "../app/RelocationServices/vuex/index.js";
import JobSeekerResume from "../app/JobSeekerResume/vuex/index.js";

export default createStore({
  state,
  getters,
  mutations,
  actions,
  modules: {
    localization: localization,
    financeAndAudit: financeAndAudit,
    UserList: UserList,
    // MembershipPlan: MembershipPlan,
    roleAndPermission: roleAndPermission,
    ListErrorLog: ListErrorLog,
    // DeleteContract: DeleteContract,
    auth: auth,
    ChangePasswordModel: ChangePasswordModel,
    superAdminSetting: superAdminSetting,
    MembershipPlan: MembershipPlan,
    users: users,
    referrals: referrals,
    profile: profile,
    jobfairs: jobFairs,
    agreements: agreements,
    adv: advertisements,
    feedbackReviews:feedbackReviews,
    contactUs:contactUs,
    footer:footer,
    PromotionMessages:PromotionMessages,
    EmailTemplate:EmailTemplate,
    blockList: blockList,
    jobs: jobs,
    employerJobFair: employerJobFair,
    dashboard:dashboard,
    settingsGeneral:settingsGeneral,
    settingsSlackFuntion:settingsSlackFuntion,
    settingsFinanceAudit:settingsFinanceAudit,
    settingsAutoEmailDispatch:settingsAutoEmailDispatch,
    settingsComission:settingsComission,
    settingsDiscountPromotionPlans:settingsDiscountPromotionPlans,
    settingsContractAgreements:settingsContractAgreements,
    settingsFileServer:settingsFileServer,
    settingsTitpRestrictions:settingsTitpRestrictions,
    settingsTitpDocuments:settingsTitpDocuments,
    settingsSlackIntegration:settingsSlackIntegration,
    localization:localization,
    MasterDataLanguage:MasterDataLanguage,
    MasterDataLanguageLevel:MasterDataLanguageLevel,
    MasterDataVisaStatus:MasterDataVisaStatus,
    MasterDataExamDegree:MasterDataExamDegree,
    MasterDataCourseMinor:MasterDataCourseMinor,
    MasterDataUniversity:MasterDataUniversity,
    MasterDataStrength:MasterDataStrength,
    MasterDataKeySkill:MasterDataKeySkill,
    MasterDataFunctionArea:MasterDataFunctionArea,
    MasterDataPosition:MasterDataPosition,
    MasterDataJobTitle:MasterDataJobTitle,
    MasterDataWorkStyle:MasterDataWorkStyle,
    MasterDataIndustryType:MasterDataIndustryType,
    MasterDataIndustrySubType:MasterDataIndustrySubType,
    MasterDataEmployerType:MasterDataEmployerType,
    MasterDataJobFairType:MasterDataJobFairType,
    RelocationServices:RelocationServices,
    JobSeekerResume: JobSeekerResume,
  },
});


