export const setMasterWorkStyleData = (state, data) => {
	state.masterWorkStyle.workStyleData = data.data.data;
	state.masterWorkStyle.workStyleLinks = data.data.links;

    state.masterWorkStyle.workStyleMeta = {
        current_page: data.data.current_page,
        from: data.data.from,
        to:  data.data.to,
        total: data.data.total,
        perPage: data.data.per_page
    }
}

export const showApproveRejectConfirmationModal = (state, flag) => {
    state.approveRejectWorkStyle.showWorkStyle = flag;
};

export const hideApproveRejectConfirmationModal = (state) => {
    state.approveRejectWorkStyle.showWorkStyle = false;
};

export const setAction = (state, action) => {
    state.approveRejectWorkStyle.action = action;
}

export const setData = (state, data) => {
    state.approveRejectWorkStyle.data = data;
}

export const showEditModal = (state, flag) => {
    state.editWorkStyle.showWorkStyle = flag;
};

export const hideEditModal = (state) => {
    state.editWorkStyle.showWorkStyle = false;
};
