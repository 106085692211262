export const showDeleteCredit = (state, flag) => {
	state.showDeleteCredit = flag
}

export const showDeleteExpenses = (state, flag) => {
	state.showDeleteExpenses = flag
}

export const showDeleteAudit = (state, flag) => {
	state.showDeleteAudit = flag
}