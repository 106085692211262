export const getDeleteSlackFunction = (state) => {
	return state.showDeleteSlackFunction
}

export const getSlackFunctionListData = (state) => {
	return state.slackFunction.data;
}

export const getSlackFunctionListLinks = (state) => {
	return state.slackFunction.links;
}
export const getSlackFunctionListMeta = (state) => {
	return state.slackFunction.meta;
}
export const getEditFunctionId = (state) => {
	return state.editFunctionId;
}

export const getSlackModuleListData = (state) => {
	return state.slackModule.data;
}
