export default {
  contract: {
    showDeleteContract: false,
    hideDeleteContract: false,
    agreements: [],
    userRole: [],
    language: [],
    agreementId: "",
    visibleToRoleSlug: "",
},
addAgreement: {
    showAgreement: false,
    hideAgreement: false,
},

editAgreement: {
    showAgreement: false,
    hideAgreement: false,
},
agrCheckModal: {
    showAgreement: false,
    hideAgreement: false,
},

addTitpDocument: {
    showTitpDocument: false,
    hideTitpDocument: false,
},
addTitpCategory: {
    showTitpCategory: false,
    hideTitpCategory: false,
},


addTitpRestriction: {
    showTitpRestriction: false,
    hideTitpRestriction: false,
},

autoEmailDispatch: {

    data: [],
    

},
discountPromotionPlanList: {
    showDeleteDiscount:false,
    hideDeleteDiscount: false,
    data: [],
    links:[],
    discountId:'',
    plan:[],
    // links: [],
    // referralId:[]
},
general:{
    data:[],
},
    slackintegration:{
        showSlackIntegration: false,
        showAddModuleModel: false,
        showEditWebhookUrl: false,
        showEditModuleModel: false,
        showDeleteWebhookUrl: false,
        showDeleteModule: false,
    }
};