export default [
	{
		path: '/user-list',
		name: 'user-list',
		component: () => import('../components/UserList.vue'),
		meta: {
			guest: false,
			needsAuth: true,
			permission : 'sr-list-user-list'
		}
	},
	{
		path: '/new-user',
		name: 'new-user',
		component: () => import('../components/AddNewUser.vue'),
		meta: {
			guest: false,
			needsAuth: true
		}
	},
	{
		path: '/edit/user/:id',
		name: 'edit-user-by-id',
		component: () => import('../components/EditUserList.vue'),
		meta: {
			guest: false,
			needsAuth: true
		}
	},
	{
		path: '/view/user/:id',
		name: 'view-user-by-id',
		component: () => import('../components/ViewUserList.vue'),
		meta: {
			guest: false,
			needsAuth: true
		}
	},
	{
		path: '/user-list/deleted-users',
		name: 'deleted-user-list',
		component: () => import('../components/DeletedList.vue'),
		meta: {
			guest: false,
			needsAuth: true
		}
	},
];