export default {

    masterIndustryType:{
        industryTypeData:null,
        industryTypeLinks:null,
        industryTypeMeta:null,
    },

    approveRejectIndustryType: {
        showIndustryType: false,
        action:'',
        data:''
    },
    editIndustryType: {
        showIndustryType: false,
    },
    
};