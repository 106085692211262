export default [
    {
        path: '/settings/auto-email-dispatch',
        component: () => import('../components/AutoEmailDispatch.vue'),
        name: 'auto-email-dispatch',
        meta: {		
            guest: false,
            needsAuth: true,	
        }
    },
]