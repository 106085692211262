export const getShowDeleteContract = (state) => {
      return state.contract.showDeleteContract;
}

export const getShowAddAgreement = (state) => {
      return state.addAgreement.showAgreement;
}

export const getShowEditAgreement = (state) => {
      return state.editAgreement.showAgreement;
}


export const getShowAddTitpDocument = (state) => {
      return state.addTitpDocument.showTitpDocument;
}

export const getShowAddTitpCategory = (state) => {
      return state.addTitpCategory.showTitpCategory;
}

export const getShowAddTitpRestriction = (state) => {
      return state.addTitpRestriction.showTitpRestriction;
}

export const getContractData = (state) => {
         return state.contract.agreements;
};
export const getAgreementId = (state) => {
         return state.contract.agreementId;
};
export const getRoles = (state) => {
      return state.contract.userRole;
}
export const getLanguage = (state) => {
      return state.contract.language;
}

export const getagrCheckModal = (state) => {
      return state.agrCheckModal.showAgreement;
};

export const getAutoEmailDispatchData = (state) => {
      return state.autoEmailDispatch.data;
};
export const getDiscountPromotionPlanListData = (state) => {
	return state.discountPromotionPlanList.data
}
export const getDiscountPromotionPlanListLinks = (state) => {
	return state.discountPromotionPlanList.links;
}


export const getShowDeleteDiscountPlan = (state) => {
      return state.discountPromotionPlanList.showDeleteDiscount;
}
export const getDiscountPlanId = (state) => {
      return state.discountPromotionPlanList.discountId;
};
export const getPromoPlanById = (state) => {
	return state.discountPromotionPlanList.plan
}
export const getGeneral = (state) => {
      return state.general.data;
}
export const getStripePaymentSettings = (state) => {
      return state.settings.pyament.stripe.data;
}

export const getShowSlackIntegration = (state) => {
      return state.slackintegration.showSlackIntegration
}
export const getShowAddModuleModel = (state) => {
      return state.slackintegration.showAddModuleModel
}
export const getShowEditWebhookUrl = (state) => {
      return state.slackintegration.showEditWebhookUrl
}
export const getShowEditModuleModel = (state) => {
      return state.slackintegration.showEditModuleModel
}
export const getDeleteWebhookUrl = (state) => {
      return state.slackintegration.showDeleteWebhookUrl
}
export const getDeleteModule = (state) => {
      return state.slackintegration.showDeleteModule
}