

export const allJobFair = ({ commit, state, } , query = '') => {
    
};

export const organiserJobFair = ({ commit, state }, query = '') => {
  
    
};
//subscribed jobfair's list
export const subscribersList_ex = ({ commit, state }, query = '') => {
  

};
export const subscribersList_at = ({ commit, state }, query = '') => {
  
};

export const nonSubscribeJobFairs = ({ commit, state } , query = '') => {
    
   
};

// export const organiserJobFairGetById = ({ commit }, id) => {
//     return window.axios
//       .get("api/job-fair/organiser/"+id+"")
//       .then((response) => {
//         return response.data.data;
//       })
//       .catch((error) => {
//         commit("SET_ALERT", "warning", { root: true });
//         commit("SET_ALERT_MESSAGE", "Something went wrong", { root: true });
//         //  errors = error.response.data.errors;
//         return false;
//       });   
// };

export const jobFairGetByIdCommon = ({ commit,state }, id) => {
      
      let timezone = state.client.timezone;
      // let timezone = state.datetime.timezone;
      return window.axios
      .get(process.env.VUE_APP_SMART_RECRUIT_API_URL + "api/job-fair/"+id+"" , { headers: { "x-timezone" : timezone} })
      .then((response) => {
        commit('setCommonJobfairById', response);
        
        return response.data.data;
      })
      .catch((error) => {
        // commit("SET_ALERT", "warning", { root: true });
        // commit("SET_ALERT_MESSAGE", "Something went wrong", { root: true });
        
        //  errors = error.response.data.errors;
        return false;
      });   
};

export const addJobFair = ({ commit, state } , {context, payload}) => {
    let timezone = state.client.timezone;
    return window.axios
    .post("api/job-fair", payload, { headers: { "x-timezone" : timezone} })
    .then((response) => {
      // commit('SET_ALERT', 'success', { root: true })
      // commit('SET_ALERT_MESSAGE', response.data.msg, { root: true })
      return response.data;
    })
    .catch((error) => {
      commit('SET_ALERT', 'warning', { root: true })
      commit('SET_ALERT_MESSAGE', error.response.statusText, { root: true })
      context.errors = error.response.data;
      return error.response.statusText;
    });   
};         
         
export const UpdateJobFair= ({ commit, state }, {id , payload, context}) => {
      let timezone = state.client.timezone;
      return window.axios
      .post("api/job-fair/update/"+id+"", payload, { headers: { "x-timezone" : timezone} })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        commit("SET_ALERT", "warning", { root: true });
        commit("SET_ALERT_MESSAGE", "Something went wrong", { root: true });
        context.errors = error.response.data;
        return error.response.data;
      });   
};

export const industryType = ({ commit }) => {
      return window.axios
        .get("api/industry")
        .then((response) => {
          commit("set_industry_types", response.data.data);
          return response.data.data;
        })
        .catch((error) => {
        commit("SET_ALERT", "warning", { root: true });
        commit("SET_ALERT_MESSAGE", "Something went wrong", { root: true });
          return false;
        });   
};

export const deleteJobFair = ({commit, state}, id) => {
    let timezone = state.client.timezone;
    return window.axios.delete("api/job-fair/delete/"+id+"", { headers: { "x-timezone" : timezone} }).then((response)=>{
    return response.data.data;
  }).catch((error)=>{
    commit("SET_ALERT", "warning", { root: true });
    commit("SET_ALERT_MESSAGE", "Something went wrong", { root: true });
    // context.errors = error.response.data;
    return error.response.data;
  })
};

export const subscribeJobfair = ({ commit, state } , {context, payload}) => {
    let timezone = state.client.timezone;
    return window.axios
    .post("api/job-fair/subscribe", payload, { headers: { "x-timezone" : timezone} })
    // .post(process.env.VUE_APP_API_URL + "api/job-fair", payload)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      commit("SET_ALERT", "warning", { root: true });
      commit("SET_ALERT_MESSAGE", "Something went wrong", { root: true });
      context.errors = error.response.data;
      return error.response.data;
    });   
}; 

export const subscriptionRequest = ({ commit, state } , {context, payload}) => {
    let timezone = state.client.timezone;
    return window.axios
    .post("api/job-fair/acceptOrDeclineSubscriptionRequest", payload, { headers: { "x-timezone" : timezone} })
    // .post(process.env.VUE_APP_API_URL + "api/job-fair", payload)
    .then((response) => {
      commit("SET_ALERT_MESSAGE", response.data.message, { root: true });
      return response.data;
    })
    .catch((error) => {
      commit("SET_ALERT", "warning", { root: true });
      commit("SET_ALERT_MESSAGE", "Something went wrong", { root: true });
      context.errors = error.response.data;
      return error.response.data;
    });   
}; 

export const unsubscribe = ({ commit } , {context, payload}) => {
  return window.axios
    .post("api/job-fair/unsubscribe", payload)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      commit("SET_ALERT", "warning", { root: true });
      commit("SET_ALERT_MESSAGE", "Something went wrong", { root: true });
      context.errors = error.response.data;
      return error.response.data;
    });   
}; 

export const cancelJobfair = ({ commit, state } , id) => {
    let timezone = state.client.timezone;
    return window.axios
    .put("api/job-fair/cancel/"+id+"" , { headers: { "x-timezone" : timezone} })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      commit("SET_ALERT", "warning", { root: true });
      commit("SET_ALERT_MESSAGE", "Something went wrong", { root: true });
      // context.errors = error.response.data;
      return error.response.data;
    });   
}; 

export const rescheduleJobfair = ({ commit, state } ,  {id , payload, context}) => {
    let timezone = state.client.timezone;
    return window.axios
    .post("api/job-fair/reschedule/"+id+"", payload , { headers: { "x-timezone" : timezone} })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      commit("SET_ALERT", "warning", { root: true });
      commit("SET_ALERT_MESSAGE", "Something went wrong", { root: true });
      context.errors = error.response.data;
      return error.response.data;
    });   
}; 

// jobfair subscribers as exhibitor
export const subscribersList_exhibitor = ({ commit } ,  {id,category,status, query = ''}) => {
  return window.axios
    .get("api/job-fair/subscribers/"+id+"/"+category+"/"+status+"" + query)
    .then((response) => {
      commit("set_subscribers_list", response) 
      return response.data;
    })
    .catch((error) => {
      commit("SET_ALERT", "warning", { root: true });
      commit("SET_ALERT_MESSAGE", "Something went wrong", { root: true });
      return false;
    });   
}; 

// jobfair subscribers as attendee
export const subscribersList_attendee = ({ commit } ,  {id,category,status, query = ''}) => {
  return window.axios
    .get("api/job-fair/subscribers/"+id+"/"+category+"/"+status+"" + query)
    .then((response) => {
      commit("set_subscribers_list_attendee", response) 
      return response.data;
    })
    .catch((error) => {
      commit("SET_ALERT", "warning", { root: true });
      commit("SET_ALERT_MESSAGE", "Something went wrong", { root: true });
      return false;
    });   
}; 

export const timezoneConverter = ({commit} , {payload}) => {
    return window.axios
    .post("api/tz-converter", payload)
    .then((response) => {
      commit("setTzConverter", response);
      return response.data;
    })
    .catch((error) => {
      commit("SET_ALERT", "warning", { root: true });
      commit("SET_ALERT_MESSAGE", "Something went wrong", { root: true });
      context.errors = error.response.data;
      return error.response.data;
    });  
};

export const fetchCompanyLogo = ({ commit }) => {
  return window.axios.get( process.env.VUE_APP_SMART_RECRUIT_API_URL + "api/company-logo").then((response) => {
      // commit('SET_APPLICANTS', response)
      return response.data
  }).catch((error) => {
      return false;
  });
};

export const checkPayment = ({commit, context }, type) => {
  return window.axios.get( process.env.VUE_APP_SMART_RECRUIT_API_URL + "api/check-payment/"+type).then((response) => {
      // commit("SET_JOBSEEKER_RESUME", response.data)
      return response.data.success;
      // return response;
  }).catch((error) => {
    commit("SET_ALERT", "warning", { root: true });
    commit("SET_ALERT_MESSAGE", "Something went wrong", { root: true });
      return false;
  })  
};

export const fetchJobseekerMembershipPlan = ({ commit }) => {
  return window.axios.get(process.env.VUE_APP_SMART_RECRUIT_API_URL+"api/seeker-membership-plan")
    .then((response) => {
      commit('jobseekerMembershipPlan', response.data.data);
      return response.data.data
    }).catch((error) => {
      return false;
    });
  };

  export const subscribeJobfairLimit = ({ commit, state } , {context, payload}) => {
    let timezone = state.client.timezone;
    return window.axios
    .post("api/job-fair/subscribe-limit", payload, { headers: { "x-timezone" : timezone} })
    // .post(process.env.VUE_APP_API_URL + "api/job-fair", payload)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      commit("SET_ALERT", "warning", { root: true });
      commit("SET_ALERT_MESSAGE", "Something went wrong", { root: true });
      context.errors = error.response.data;
      return error.response.data;
    });   
};

export const checkReferrals = ({ commit }) => {
  return window.axios.get(process.env.VUE_APP_SMART_RECRUIT_API_URL+"api/check-referrals")
    .then((response) => {
      return response.data
    }).catch((error) => {
      commit("SET_ALERT", "warning", { root: true });
      commit("SET_ALERT_MESSAGE", "Something went wrong", { root: true });
      return error.response.data;
    });
  };

  export const updateReferralDiscountStatus = ({commit}) => {
    return window.axios.post(process.env.VUE_APP_SMART_RECRUIT_API_URL + "api/update-referral").then((response) => {
        commit('SET_ALERT', 'success', { root: true })
        // commit("SET_ALERT_MESSAGE", response.data.message, { root: true });
        // return response.data.success;
    }).catch((error) => {
        commit("SET_ALERT", "warning", { root: true });
        commit("SET_ALERT_MESSAGE", "Something went wrong", { root: true });
        // context.errors = error.response.data.errors;
        return false;
    });   
  };