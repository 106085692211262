export default [
    {
      path: "/relocation-services",
      component: () => import("../components/RelocationServices.vue"),
      name: "relocation-services",
      meta: {
        guest: false,
        needsAuth: true,
      },
    },
    {
      path: '/relocation-services-detail/:id',
      component: () => import("../components/partials/RelocationServiceDetail.vue"),
      name: "relocation-services-detail",
      meta: {
        guest: false,
        needsAuth: true,
      },
    },
  ];