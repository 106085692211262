export const getTitpDocumentData = (state) => {
    return JSON.parse(state.titpDocument.data);
}
export const getTitpCategory = (state) => {
    return state.titpDocument.category;
}
export const getTitpSubCategory = (state) => {
    return state.titpDocument.sub_category;
}

export const getShowAddNewTitpCommonDocument = (state) => {
    return state.addTitpCommonDocument.showTitpCommonDocument;
}

export const getAction = (state) => {
    return state.addTitpCommonDocument.action;
}

export const getShowAddNewTitpCategory = (state) => {
    return state.addTitpCategory.showTitpCategory;
}

export const getActionCategory = (state) => {
    return state.addTitpCategory.action;
}

export const getId = (state) => {
    return state.addTitpCategory.id;
}

export const getTitpCategoryData = (state) => {
    let docJson = [];
    let operationJson = [];
    if(state.titpCategory.categoryData){
        state.titpCategory.categoryData.forEach(element => {
            docJson.push(JSON.parse(element.mandatory_documents));
            operationJson.push(JSON.parse(element.cate_titp_operations));
        });
        return {'data':state.titpCategory.categoryData, 'doc':docJson, 'operation':operationJson};
    }   
}


export const getTitpCategoryLink = (state) => {
    return state.titpCategory.categoryLinks;
}
export const getTitpCategoryMeta = (state) => {
    return state.titpCategory.categoryMeta;
}

export const getTitpCategoryDataById = (state) => {
	 return state.titpCategoryById.data
}
