export default{
    adv:{
       cardId:'',
       showDeleteModal:false,
    },
    advertisement:{
        advertisementData:null,
        advertisementLinks:null,
        advertisementMeta:null,
    },
    advertisementById:{
        id:"",
        user_id:"",
        ad_title:"",
        created_date:"",
        publish_startdate:"",
        publish_enddate:"",
        posting_firstname:"",
        posting_lastname:"",
        ad_filename:"",
        ad_status:"",
        ad_filename_url:"",
        sso_user_role:"",
        company_name:"",
        company_logo:"",
        sso_designation:"",
    },
    alert:{
        status:false,
        title:'',
        msg:'',
       },
}