export const getMasterVisaStatusData = (state) => {
    return state.masterVisaStatus.visaStatusData;
}

export const getMasterVisaStatusLink = (state) => {
    return state.masterVisaStatus.visaStatusLinks;
}

export const getMasterVisaStatusMeta = (state) => {
    return state.masterVisaStatus.visaStatusMeta;
}

export const getShowApproveRejectConfirmationModal = (state) => {
    return state.approveRejectVisaStatus.showVisaStatus;
}

export const getAction = (state) => {
    return state.approveRejectVisaStatus.action;
}

export const getData = (state) => {
    return state.approveRejectVisaStatus.data;
}

export const getShowEditModal = (state) => {
    return state.editVisaStatus.showVisaStatus;
}

