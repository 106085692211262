export default [
    {
      path: "/master-data-degree",
      component: () => import("../components/ExamDegree.vue"),
      name: "Master-Data-Degree",
      meta: {
        guest: false,
        needsAuth: true,
      },
    },
  ];