export const fetchAutoEmailDispatchSettings = ({ commit,dispatch}, payload) => {
    let query = payload ? '?editMail=true' : '';
    return window.axios.get(process.env.VUE_APP_SMART_RECRUIT_API_URL + 'api/site-setting' + query).then((response) => {
        commit('setAutoEmailDispatchData', response)
        return response.data.success;
    }).catch((error) => {
        dispatch("auth/errorHandler",error.response.status,{ root:true });
        commit("SET_ALERT_MESSAGE", "Something went wrong", { root: true });
        return response.data.success;
    });   
};
export const testSMTPConnection = ({ commit, dispatch},{payload,context}) => {
    return window.axios.post(process.env.VUE_APP_SMART_RECRUIT_API_URL + 'api/check-smtp-connection',payload).then((response) => {
        return response.data;
    }).catch((error) => {
        dispatch("auth/errorHandler",error.response.status,{ root:true });
        commit("SET_ALERT_MESSAGE", "Something went wrong", { root: true });
        context.isbeingCreating1 = true;
        return response.data.success;
    });   
};