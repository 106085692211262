export const contractData = ({ commit, dispatch }) => {
  return window.axios
    .get(
      "api/contract-agreement/"
    )
    .then((response) => {
      // commit("SET_ALERT", "success", { root: true });
      // commit("SET_ALERT_MESSAGE", response.data.message, { root: true });
      commit("setContractData", response.data.data);
      return response.data.data;
    })
    .catch((error) => {
      // commit("SET_ALERT", "warning", { root: true });
      // commit("SET_ALERT_MESSAGE", "Something went wrong", { root: true });
      return false;
    });
};
export const deleteAgreement = ({ commit }, id) => {
  return window.axios
    .delete(
      "api/contract-agreement/" +
        id +
        ""
    )
    .then((response) => {
      // window.location = "settings"
      // commit("SET_ALERT", "success", { root: true });
      // commit("SET_ALERT_MESSAGE", response.data.message, { root: true });
      return response.data;
  
    })
    .catch((error) => {
      // commit("SET_ALERT", "warning", { root: true });
      // commit("SET_ALERT_MESSAGE", "Something went wrong", { root: true });
      return false;
    });
};
export const userRoles = ({ commit }, id) => {
  return window.axios
    .get(process.env.VUE_APP_SSO_API_URL + "api/get/role/smart-recruit")
    .then((response) => {
      // commit("SET_ALERT", "success", { root: true });
      // commit("SET_ALERT_MESSAGE", response.data.message, { root: true });
      commit("setUserRole", response.data.roles);
      return response.data.roles;
    })
    .catch((error) => {
      // commit("SET_ALERT", "warning", { root: true });
      // commit("SET_ALERT_MESSAGE", "Something went wrong", { root: true });
      return false;
    });
};
export const agg_language = ({ commit }) => {
  return window.axios
    .get("api/language-mapping")
    .then((response) => {
      // commit("SET_ALERT", "success", { root: true });
      // commit("SET_ALERT_MESSAGE", response.data.message, { root: true });
      commit("setLanguage", response.data.data);
      return response.data.data;
    })
    .catch((error) => {
      // commit("SET_ALERT", "warning", { root: true });
      // commit("SET_ALERT_MESSAGE", "Something went wrong", { root: true });
      return false;
    });
};

export const postAgreement = ({ commit,dispatch }, { payload, context }) => {
  return window.axios
    .post("api/contract-agreement/", payload, {
      config: { headers: { "Content-Type": "multipart/form-data" } },
    })
    .then((response) => {
      // window.location = "settings"
      // commit("SET_ALERT", "success", { root: true });
      // commit("SET_ALERT_MESSAGE", response.data.message, { root: true });
      return response.data;
    })
    .catch((error) => {
      // commit("SET_ALERT", "warning", { root: true });
      // commit("SET_ALERT_MESSAGE", "Something went wrong", { root: true });
      context.errors = error.response.data;
      return false;
    });
};
export const getContractByID = ({ commit }, id) => {
  return window.axios.get("api/contract-agreement/"+id+"")
    .then((response) => {
      // commit("SET_ALERT", "success", { root: true });
      // commit("SET_ALERT_MESSAGE", response.data.message, { root: true });
      return response.data;
    })
    .catch((error) => {
      // commit("SET_ALERT", "warning", { root: true });
      // commit("SET_ALERT_MESSAGE", "Something went wrong", { root: true });
      // context.errors = error.response.data;
      return false;
    });   
};
export const updateContract = ({ commit }, {id,payload}) => {
  return window.axios
    .post("api/contract-agreement/" +id +"",
      payload,
      {
        config: { headers: { "Content-Type": "multipart/form-data" } },
      }
    ).then((response) => {
      // commit("SET_ALERT", "success", { root: true });
      // commit("SET_ALERT_MESSAGE", response.data.message, { root: true });
      return response.data;
    }).catch((error) => {
      // commit("SET_ALERT", "warning", { root: true });
      // commit("SET_ALERT_MESSAGE", "Something went wrong", { root: true });
      // context.errors = error.response.data;
      return false;
    });      
};


export const titpDocsData = ({ commit, context }) => {
    return window.axios.get( process.env.VUE_APP_SMART_RECRUIT_API_URL +"api/managejob/documents/titp").then((response) => {
        // commit("setAuthenticated", true);
        commit("SET_ALERT_MESSAGE", response.data.message, { root: true });
        return response.data.data;
    }).catch((error) => {
        commit("SET_ALERT", "warning", { root: true });
        commit("SET_ALERT_MESSAGE", "Something went wrong Document Data", { root: true });
        context.errors = error.response.data.errors;
        return false;
    });
};
export const titpDocsCreate = ({ commit, dispatch }, { payload, context }) => {
  return window.axios.post(process.env.VUE_APP_SMART_RECRUIT_API_URL +"api/managejob/titp-job-documents/submit", payload).then((response) => {      // Showing  success message
      commit('SET_ALERT', 'success', { root: true })
      commit('SET_ALERT_MESSAGE', response.data.msg, { root: true })
      setTimeout(function(){
          location.reload();
      }, 3000);
      return response.data.success
  }).catch((error) => {
      // commit('SET_ALERT', 'warning', { root: true })
      // commit('SET_ALERT_MESSAGE', 'Something went wrong To Upload Titp Document ', { root: true })
      context.errors = error.response.data
      return error.response.data
  })
};

export const titpEditDocs = ({ commit, context }, id) => {

    return window.axios.get(process.env.VUE_APP_SMART_RECRUIT_API_URL +"api/managejob/titp-job-documents/edit/"+id+"").then((response) => {
        // commit("setAuthenticated", true);
        commit("SET_ALERT_MESSAGE", response.data.message, { root: true });
        return response.data;
    }).catch((error) => {
        commit("SET_ALERT", "warning", { root: true });
        commit("SET_ALERT_MESSAGE", "Something went wrong Edit Document Data", { root: true });
        context.errors = error.response.data.errors;
        return false;
    });
};

export const titpDeleteDocs = ({ commit, context }, id) => {

    return window.axios.get(process.env.VUE_APP_SMART_RECRUIT_API_URL +"api/managejob/titp-job-documents/delete/"+id+"").then((response) => {
      commit('SET_ALERT', 'success', { root: true })
      commit('SET_ALERT_MESSAGE', response.data.message, { root: true })
          return response.data.success
    }).catch((error) => {
        commit("SET_ALERT", "warning", { root: true });
        commit("SET_ALERT_MESSAGE", "Something went Job delete", { root: true });
        context.errors = error.response.data.errors;
        return false;
    });
};


export const titpCategoryData = ({ commit, context }) => {
    return window.axios.get(process.env.VUE_APP_SMART_RECRUIT_API_URL +"api/managejob/titp-job-category").then((response) => {
        // commit("setAuthenticated", true);
        commit("SET_ALERT_MESSAGE", response.data.message, { root: true });
        return response.data.data;
    }).catch((error) => {
        commit("SET_ALERT", "warning", { root: true });
        // commit("SET_ALERT_MESSAGE", "Something went wrong Category Data", { root: true });
        context.errors = error.response.data.errors;
        return false;
    });
};

export const titpCategoryCreate = ({ commit, dispatch }, { payload, context }) => {
  return window.axios.post(process.env.VUE_APP_SMART_RECRUIT_API_URL +"api/managejob/titp-job-category/submit", payload).then((response) => {      // Showing  success message
      commit('SET_ALERT', 'success', { root: true })
      commit('SET_ALERT_MESSAGE', response.data.msg, { root: true })

      setTimeout(function(){
          location.reload();
      }, 3000);
      return response.data.success


  }).catch((error) => {
      // commit('SET_ALERT', 'warning', { root: true })
      // commit('SET_ALERT_MESSAGE', 'Something went wrong To Upload Titp Document ', { root: true })
      context.errors = error.response.data
      return error.response.data
  })
};

export const titpEditCategory = ({ commit, context }, id) => {

    return window.axios.get(process.env.VUE_APP_SMART_RECRUIT_API_URL +"api/managejob/titp-job-category/edit/"+id+"").then((response) => {
        // commit("setAuthenticated", true);
        commit("SET_ALERT_MESSAGE", response.data.message, { root: true });
        return response.data;
    }).catch((error) => {
        commit("SET_ALERT", "warning", { root: true });
        commit("SET_ALERT_MESSAGE", "Something went wrong Edit Document Data", { root: true });
        context.errors = error.response.data.errors;
        return false;
    });
};

export const titpDeleteCategory = ({ commit, context }, id) => {

    return window.axios.get(process.env.VUE_APP_SMART_RECRUIT_API_URL +"api/managejob/titp-job-category/delete/"+id+"").then((response) => {
      commit('SET_ALERT', 'success', { root: true })
      commit('SET_ALERT_MESSAGE', response.data.message, { root: true })
        
          return response.data.success
    }).catch((error) => {
        commit("SET_ALERT", "warning", { root: true });
        commit("SET_ALERT_MESSAGE", "Something went Job delete", { root: true });
        context.errors = error.response.data.errors;
        return false;
    });
};


export const titpRestrictionData = ({ commit, context }) => {
    return window.axios.get(process.env.VUE_APP_SMART_RECRUIT_API_URL +"api/managejob/titp-restriction").then((response) => {
        // commit("setAuthenticated", true);
        commit("SET_ALERT_MESSAGE", response.data.message, { root: true });
        return response.data.data;
    }).catch((error) => {
        commit("SET_ALERT", "warning", { root: true });
        // commit("SET_ALERT_MESSAGE", "Something went wrong Titp Restriction Data", { root: true });
        context.errors = error.response.data.errors;
        return false;
    });
};

export const titpRestrictionCreate = ({ commit, dispatch }, { payload, context }) => {
  return window.axios.post(process.env.VUE_APP_SMART_RECRUIT_API_URL +"api/managejob/titp-restriction/submit", payload).then((response) => {      // Showing  success message
      commit('SET_ALERT', 'success', { root: true })
      commit('SET_ALERT_MESSAGE', response.data.message, { root: true })

      setTimeout(function(){
          location.reload();
      }, 3000);
      return response.data.success



  }).catch((error) => {
      // commit('SET_ALERT', 'warning', { root: true })
      // commit('SET_ALERT_MESSAGE', 'Something went wrong To Upload Titp Restriction ', { root: true })
      context.errors = error.response.data
      return error.response.data
  })
};

export const titpEditRestriction = ({ commit, context }, id) => {

    return window.axios.get(process.env.VUE_APP_SMART_RECRUIT_API_URL +"api/managejob/titp-restriction/edit/"+id+"").then((response) => {
        // commit("setAuthenticated", true);
        commit("SET_ALERT_MESSAGE", response.data.message, { root: true });
        return response.data;
    }).catch((error) => {
        commit("SET_ALERT", "warning", { root: true });
        commit("SET_ALERT_MESSAGE", "Something went wrong Edit Restriction Data", { root: true });
        context.errors = error.response.data.errors;
        return false;
    });
};

export const titpRestrictionDelete = ({ commit, context }, id) => {

    return window.axios.get(process.env.VUE_APP_SMART_RECRUIT_API_URL +"api/managejob/titp-restriction/delete/"+id+"").then((response) => {
      commit('SET_ALERT', 'success', { root: true })
      commit('SET_ALERT_MESSAGE', response.data.message, { root: true })
          return response.data.success
    }).catch((error) => {
        commit("SET_ALERT", "warning", { root: true });
        // commit("SET_ALERT_MESSAGE", "Something went wrong Titp Restriction", { root: true });
        context.errors = error.response.data.errors;
        return false;
    });
};





// process.env.VUE_APP_SMART_RECRUIT_API_URL +
export const hiringAuthorityData = ({ commit, context }) => {
    return window.axios.get(process.env.VUE_APP_SSO_API_URL +"api/employee/hiring-source/check").then((response) => {
        // commit("setAuthenticated", true);
        // commit("SET_ALERT_MESSAGE", response.data.message, { root: true });
        return response.data.data;
    }).catch((error) => {
        // commit("SET_ALERT", "warning", { root: true });
        // commit("SET_ALERT_MESSAGE", "Something went wrong Category Data", { root: true });
        context.errors = error.response.data.errors;
        return false;
    });
};

export const hiringAuthorityCreate = ({ commit, dispatch }, { payload, context }) => {
  return window.axios.post(process.env.VUE_APP_SSO_API_URL +"api/general/hiringauthority/store/", payload).then((response) => {      // Showing  success message
      // commit('SET_ALERT', 'success', { root: true })
      // commit('SET_ALERT_MESSAGE', response.data.message, { root: true })

      setTimeout(function(){
          location.reload();
      }, 3000);
      return response.data.success


  }).catch((error) => {
      // commit('SET_ALERT', 'warning', { root: true })
      // commit('SET_ALERT_MESSAGE', 'Something went wrong To Upload Titp Document ', { root: true })
      context.errors = error.response.data
      return error.response.data
  })
};




// process.env.VUE_APP_SMART_RECRUIT_API_URL +
export const commissionData = ({ commit, context }) => {
    return window.axios.get(process.env.VUE_APP_SMART_RECRUIT_API_URL +"api/commission/check/get").then((response) => {
        // commit("setAuthenticated", true);
        commit("SET_ALERT_MESSAGE", response.data.message, { root: true });
        return response.data.data;
    }).catch((error) => {
        commit("SET_ALERT", "warning", { root: true });
        // commit("SET_ALERT_MESSAGE", "Something went wrong Category Data", { root: true });
        context.errors = error.response.data.errors;
        return false;
    });
};

export const commissionCreate = ({ commit, dispatch }, { payload, context }) => {
  return window.axios.post(process.env.VUE_APP_SMART_RECRUIT_API_URL +"api/commission/store", payload).then((response) => {      // Showing  success message
      commit('SET_ALERT', 'success', { root: true })
      commit('SET_ALERT_MESSAGE', response.data.message, { root: true })

      setTimeout(function(){
          location.reload();
      }, 3000);
      return response.data.success


  }).catch((error) => {
      // commit('SET_ALERT', 'warning', { root: true })
      // commit('SET_ALERT_MESSAGE', 'Something went wrong To Upload Titp Document ', { root: true })
      context.errors = error.response.data
      // console.log(error.response.data);
      return error.response.data
  })
};

export const fetchAutoEmailDispatch = ({ commit, dispatch }) => {
  return window.axios.get('/api/auto-email/get/').then((response) => {
      commit("SET_ALERT", "success", { root: true });
      commit("SET_AUTO_EMAIL_DISPATCH", response.data.data);
      return response.data.data;
    })
    .catch((error) => {
      commit("SET_ALERT", "warning", { root: true });
      commit("SET_ALERT_MESSAGE", "Something went wrong", { root: true });
      return false;
    });
};

export const updateAutoEmailDispatch = ({ commit }, {context,payload}) => {
  return window.axios.post ('/api/auto-email/store', payload).then((response) => {
      commit('SET_ALERT', 'success', { root: true })
      commit('SET_ALERT_MESSAGE', response.data.message, { root: true })
      return true
  }).catch((error) => {
       commit('SET_ALERT', 'warning', { root: true })
      commit('SET_ALERT_MESSAGE', 'Something went wrong', { root: true })
      context.errors = error.response.data
      return false
  })
}

export const fetchDiscountPromotionPlanList = ({ commit, dispatch }, query = '') => {
  return window.axios.get('/api/discount-promotion' + query).then((response) => {
      commit('SET_DISCOUNT_PROMOTION_PLAN_LIST', response)
      return true
  }).catch((error) => {
      return false
  })
}

export const deleteDiscount = ({ commit}, id) => {
  return window.axios.delete('/api/discount-promotion/'+id+'').then((response) => {
      commit("SET_ALERT_MESSAGE", response.data.message, { root: true });
      return response.data;
  }).catch((error) => {
      commit("SET_ALERT", "warning", { root: true });
      commit("SET_ALERT_MESSAGE", "Something went wrong", { root: true });
      return false;
  });   
};

export const postDiscountPromotionPlan = ({ commit}, { payload, context}) => {
  return window.axios.post ('/api/discount-promotion', payload).then((response) => {
      // Showing  success message
      commit('SET_ALERT', 'success', { root: true })
      commit('SET_ALERT_MESSAGE', response.data.message, { root: true })
      return response.data.success
  }).catch((error) => {
      commit('SET_ALERT', 'warning', { root: true })
      commit('SET_ALERT_MESSAGE', 'Something went wrong', { root: true })
      context.errors = error.response.data
      return false
  })
}
export const fetchPromoPlanById = ({ commit}, id) => {
  return window.axios.get('/api/discount-promotion/'+id+'').then((response) => {
      commit("SET_ALERT_MESSAGE", response.data.message, { root: true });
      commit('setPromoPlanById', response.data);
      return response.data.success;
  }).catch((error) => {
      commit("SET_ALERT", "warning", { root: true });
      commit("SET_ALERT_MESSAGE", "Something went wrong", { root: true });
      return false;
  });   
};

export const updateDiscountPromotionPlan = ({ commit}, { payload,id, context}) => {
  return window.axios.post ('/api/discount-promotion/'+id+'', payload).then((response) => {
      // Showing  success message
      commit('SET_ALERT', 'success', { root: true })
      commit('SET_ALERT_MESSAGE', response.data.message, { root: true })

      return response.data.success
  }).catch((error) => {
      commit('SET_ALERT', 'warning', { root: true })
      commit('SET_ALERT_MESSAGE', 'Something went wrong', { root: true })
      context.errors = error.response.data
      return false
  })
}

export const postGeneral = ({ commit}, { payload, context}) => {
  return window.axios.post ('/api/general/store', payload).then((response) => {
      commit('SET_ALERT', 'success', { root: true })
      commit('SET_ALERT_MESSAGE', response.data.message, { root: true })
      return response.data.success
  }).catch((error) => {
      commit('SET_ALERT', 'warning', { root: true })
      commit('SET_ALERT_MESSAGE', 'Something went wrong', { root: true })
      context.errors = error.response.data
      return false
  })
}

export const fetchGeneral = ({ commit}) => {
  return window.axios.get('/api/general/list').then((response) => {
      // commit("SET_ALERT_MESSAGE", response.data.message, { root: true });
      commit('setGeneral', response.data)
      return response.data;
  }).catch((error) => {
      // commit("SET_ALERT", "warning", { root: true });
      // commit("SET_ALERT_MESSAGE", "Something went wrong", { root: true });
      return false;
  });   
};



export const fetchEnabledPayments = ({ commit }) => {
  return window.axios.get('/api/get-enabled-payment').then((response) => {
    return response.data;
  }).catch((error) => {
    commit("SET_ALERT", "warning", { root: true });
    commit("SET_ALERT_MESSAGE", "Something went wrong", { root: true });
    return false;
  });   
};
export const updatePaymentStatusSettings = ({ commit }, { payload, context}) => {
  return window.axios.post('/api/enable-disable-payment-setting', payload).then((response) => {
      if(response.data.success){
        commit('SET_ALERT', 'success', { root: true })
        commit("SET_ALERT_MESSAGE", response.data.message, { root: true });
      }else{
        commit("SET_ALERT", "warning", { root: true });
        commit("SET_ALERT_MESSAGE", response.data.error, { root: true });
      }
      return response.data;
  }).catch((error) => {
      commit("SET_ALERT", "warning", { root: true });
      commit("SET_ALERT_MESSAGE", "Something went wrong", { root: true });
      return false;
  });   
};
export const fetchStripePaymentSettings = ({ commit }) => {
  return window.axios.get('/api/get-payment-setting/stripe').then((response) => {
    return response.data;
  }).catch((error) => {
    commit("SET_ALERT", "warning", { root: true });
    commit("SET_ALERT_MESSAGE", "Something went wrong", { root: true });
    return false;
  });   
};
export const updateStripePaymentSettings = ({ commit }, { payload, context}) => {
  return window.axios.post('/api/update-payment-setting', payload).then((response) => {
      if(response.data.success){
        commit('SET_ALERT', 'success', { root: true })
        commit("SET_ALERT_MESSAGE", response.data.message, { root: true });
      }else{
        commit("SET_ALERT", "warning", { root: true });
        commit("SET_ALERT_MESSAGE", response.data.error, { root: true });
      }
      return response.data;
  }).catch((error) => {
      commit("SET_ALERT", "warning", { root: true });
      commit("SET_ALERT_MESSAGE", "Something went wrong", { root: true });
      return false;
  });   
};
export const fetchBankPaymentSettings = ({ commit }) => {
  return window.axios.get('/api/get-payment-setting/bank').then((response) => {
    return response.data;
  }).catch((error) => {
    commit("SET_ALERT", "warning", { root: true });
    commit("SET_ALERT_MESSAGE", "Something went wrong", { root: true });
    return false;
  });   
};
export const updateBankPaymentSettings = ({ commit }, { payload, context}) => {
  return window.axios.post('/api/update-payment-setting', payload).then((response) => {
      if(response.data.success){
        commit('SET_ALERT', 'success', { root: true })
        commit("SET_ALERT_MESSAGE", response.data.message, { root: true });
      }else{
        commit("SET_ALERT", "warning", { root: true });
        commit("SET_ALERT_MESSAGE", response.data.error, { root: true });
      }
      return response.data;
  }).catch((error) => {
      commit("SET_ALERT", "warning", { root: true });
      commit("SET_ALERT_MESSAGE", "Something went wrong", { root: true });
      return false;
  });   
};

export const updateFileServerSetails = ({ commit }, { payload, context}) => {
  return window.axios.post(process.env.VUE_APP_SMART_RECRUIT_API_URL + 'api/update-server', payload).then((response) => {
      commit("SET_ALERT", "success", { root: true });
      commit("SET_ALERT_MESSAGE", response.data.message, { root: true });  
    return response.data;
  }).catch((error) => {
      commit("SET_ALERT", "warning", { root: true });
      commit("SET_ALERT_MESSAGE", "Something went wrong", { root: true });
      return false;
  });   
};

export const testS3BucketConnection = ({ commit }) => {
  return window.axios.get(process.env.VUE_APP_SMART_RECRUIT_API_URL + 'api/connection').then((response) => {
      commit("SET_ALERT", "success", { root: true });
      commit("SET_ALERT_MESSAGE", response.data.message, { root: true });
      return response.data;
  }).catch((error) => {
      commit("SET_ALERT", "warning", { root: true });
      commit("SET_ALERT_MESSAGE", "Something went wrong", { root: true });
      return false;
  });   
};