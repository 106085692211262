export const fetchMasterIndustrySubType = ({ commit, dispatch }, query='') => {
    let url = process.env.VUE_APP_SMART_RECRUIT_API_URL + 'api/master-data-industry-sub-type'+ query;
    return window.axios.get(url).then((response) => {
        commit('setMasterIndustrySubTypeData', response.data);
        return true;
    }).catch((error) => {
        dispatch("auth/errorHandler",error.response.status,{ root:true });
        return false;
    })
}

export const fetchMasterIndustryTypeList = ({ commit, dispatch }) => {
    let url = process.env.VUE_APP_SMART_RECRUIT_API_URL + 'api/master-industry-list';
    return window.axios.get(url).then((response) => {
        return response.data;
    }).catch((error) => {
        dispatch("auth/errorHandler",error.response.status,{ root:true });
        return false;
    })
}


