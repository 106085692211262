export default [
    {
      path: "/master-data-visa-status",
      component: () => import("../components/VisaStatus.vue"),
      name: "Master-Data-Visa-Status",
      meta: {
        guest: false,
        needsAuth: true,
      },
    },
  ];