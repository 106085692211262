export default [
    {
      path: "/master-data-work-style",
      component: () => import("../components/WorkStyle.vue"),
      name: "Master-Data-Work-Style",
      meta: {
        guest: false,
        needsAuth: true,
      },
    },
  ];