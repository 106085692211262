export default {
	financeAudit: {
		financial_year_start:'',
		financial_year_end:'',
		isStripe:'',
		isBank:'',
		isPaypal:'',
		stripe_api_key:'',
		stripe_secret_key:'',
		bank_transfer_account_name:'',
		bank_transfer_bank_name:'',
		bank_transfer_branch_name:'',
		bank_transfer_account_type:'',
		bank_transfer_ifsc_code:'',
		bank_transfer_account_number:'',
		paypal_url:'',
    	paypal_id:'',
	},
	state: { 
	showAddPaymentModal: false,
    hideAddPaymentModal: false,
    editPaymentModal: false,
    editStripePaymentModel: false,
    editPaypalPaymentModel: false,
    disablePaymentModel: false,
    updatePaymentModal: false,
    showRemovePaymentModal: false,
    hideRemovePaymentModal: false,
	paymentType:''
	}
}