export default [
    {
        path: '/settings/finance-audit',
        component: () => import('../components/FinanceAudit.vue'),
        name: 'finance-audit',
        meta: {		
            guest: false,
            needsAuth: true,	
        }
    },
]