export const SET_USER_LIST = (state, data) => {
    state.userList.data = data.data.data.data;
    state.userList.links = data.data.data.links;
	state.userList.current_page = data.data.data.current_page;
    state.userList.meta = {
		current_page: data.data.data.current_page,
		from: data.data.data.from,
		to:  data.data.data.to,
		total: data.data.data.total,
		perPage: data.data.data.per_page
	}
}

export const SET_ROLE_LIST = (state, data) => {
    state.myRoles = data.data;
}




