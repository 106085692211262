import { createApp } from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import localforage from 'localforage'
import globalMixin from './globalMixin'

import gliderHelper from './plugins/js/glider-helper.js'
window.glider = gliderHelper

import App from './App.vue'
import router from './router'
import store from './store/store.js'
import './index.css'
import './plugins/js/glider.min.js'
import './plugins/css/glider.min.css'
import VCalendar from 'v-calendar';
import i18n from './i18n'
import PrimeVue from 'primevue/config'
// import Calendar from 'primevue/calendar'


import 'primevue/resources/themes/saga-blue/theme.css'    //theme
import 'primevue/resources/primevue.min.css'                 //core css
import 'primeicons/primeicons.css'
import CKEditor from '@ckeditor/ckeditor5-vue'

import Donut from 'vue-css-donut-chart';
import 'vue-css-donut-chart/dist/vcdonut.css';
import VueSignaturePad from 'vue-signature-pad';

// import ApexCharts from 'apexcharts'
import VueApexCharts from "vue3-apexcharts";


window.axios = axios.create({
    baseURL: process.env.VUE_APP_SSO_API_URL || '',
    headers: {
        Accept: 'application/json'
    }
})

localforage.config({
    driver: localforage.LOCALSTORAGE,
    storeName: 'smartrecruitadmin'
})

window.alertDuration = process.env.VUE_APP_ALERT_DURATION || 2000

store.dispatch('auth/setToken').then(() => {
    store.dispatch('auth/fetchUser').then(() => {
    }).catch(() => {
        store.dispatch('auth/clearAuth')
        router.replace({ name: 'Sign-In'})
    })
}).catch(() => {
    store.dispatch('auth/clearAuth')
})




createApp(App).use(i18n).use(store).use(router).use(VCalendar).use(PrimeVue).use(CKEditor).use(Donut).use(VueApexCharts).use(VueSignaturePad).mixin(globalMixin).mount('#app');


// app.mixin(globalMixin);

