export const setAutoEmailDispatchData = (state, data) => {
	state.autoEmailDispatch.mail_mailer = data.data.data.mail_mailer
	state.autoEmailDispatch.mail_host = data.data.data.mail_host
	state.autoEmailDispatch.mail_port = data.data.data.mail_port
	state.autoEmailDispatch.mail_username = data.data.data.mail_username
	state.autoEmailDispatch.mail_password = data.data.data.mail_password
	state.autoEmailDispatch.mail_encryption = data.data.data.mail_encryption
	state.autoEmailDispatch.mail_from_email = data.data.data.mail_from_email
	state.autoEmailDispatch.mail_from_name = data.data.data.mail_from_name

}