export default [
    {
        path: '/settings/slack-function',
        component: () => import('../components/SlackFunction.vue'),
        name: 'slack-function',
        meta: {		
            guest: false,
            needsAuth: true,	
        }
    },
    {
        path: '/settings/slack-function-add',
        component: () => import('../components/SlackFunctionAdd.vue'),
        name: 'slack-function-add',
        meta: {     
            guest: false,
            needsAuth: true,    
        }
    },

    {
        path: '/settings/slack-function-view/:id',
        component: () => import('../components/SlackFunctionView.vue'),
        name: 'slack-function-view',
        meta: {     
            guest: false,
            needsAuth: true,    
        }
    },

    {
        path: '/settings/slack-function-edit/:id',
        component: () => import('../components/SlackFunctionEdit.vue'),
        name: 'slack-function-edit',
        meta: {     
            guest: false,
            needsAuth: true,    
        }
    },
]