export const getAgreementList = ({ commit, dispatch }, query = '') => {
    if(query){
        query = query + '&show_all_agreements=1'
    }
    return window.axios.get(process.env.VUE_APP_SMART_RECRUIT_API_URL +"api/agreements-list" + query).then((response) => {
        commit('SET_AGREEMENTS_DATA', response.data.data)
        return response.data;
    }).catch((error) => {
        return false;
    });
}

export const getAgreementById = ({ commit }, id) => {
    return window.axios.get(process.env.VUE_APP_SMART_RECRUIT_API_URL +"api/get-employer-profile-details?agreement_id="+id).then((response) => {
      commit('SetAgreementDetails', response.data)  
      return response.data;
    }).catch((error) => {
        return false;
    });
}
export const fetchUserList = ({ commit, dispatch, getters }, query = '') => {
  if(query){
    query = query + '&is_disable=yes&user_id='+ getters['getUserId'];
  }
  return window.axios.get('api/user-list' + query).then((response) => {
      commit('SET_USER_LIST', response);
      return true
  }).catch((error) => {
      dispatch("auth/errorHandler",error.response.status,{ root:true });
      return false
  })
}
export const changeUserStatus = ({ commit }, {payload}) => {
  return window.axios.post(process.env.VUE_APP_SMART_RECRUIT_API_URL +"api/change-user-status",payload).then((response) => {
      return response.data;
  }).catch((error) => {
      return false;
  });
}
export const getDiscountPromotionPlanDetails = ({ commit }, {payload}) => {
  return window.axios.get(process.env.VUE_APP_SMART_RECRUIT_API_URL +"api/discount-promotion-plan/",payload.id).then((response) => {
      return response.data;
  }).catch((error) => {
      return false;
  });
}
export const uploadSignedAgreement = ({ commit, dispatch, state },{payload, context}) => {
  return window.axios.post(process.env.VUE_APP_SMART_RECRUIT_API_URL +"api/agreement-document-upload",payload).then((response) => {
      return response.data;
  }).catch((error) => {
      return false;
  });
}
export const employerProfileMembershipById = ({ commit, dispatch, state },{payload, context}) => {
  return window.axios.post(process.env.VUE_APP_SMART_RECRUIT_API_URL +"api/employer-profile-membership-plan",payload).then((response) => {
      return response.data;
  }).catch((error) => {
      context.isBtnDisable = false;
      return false;
  });
}
export const getDiscountPromotionPlanData = ({ commit, dispatch, state },{payload}) => {
  return window.axios.get(process.env.VUE_APP_SMART_RECRUIT_API_URL +"api/discount-promotion-plan/"+payload).then((response) => {
      return response;
  }).catch((error) => {
      return false;
  });
}
export const settings = ({ commit, dispatch, state }) => {
  return window.axios.get(process.env.VUE_APP_SMART_RECRUIT_API_URL +"api/site-setting").then((response) => {
      return response.data;
  }).catch((error) => {
      return false;
  });
}
export const paymentDetails = ({ commit, dispatch, state },{payload}) =>{
  return window.axios.post(process.env.VUE_APP_SMART_RECRUIT_API_URL +"api/employer-profile-payment",payload).then((response) => {
    return response.data;
  }).catch((error) => {
      return false;
  });
}