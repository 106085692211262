export default [
    {
        path: '/settings/discount-promotion-plans',
        component: () => import('../components/DiscountPromotionPlans.vue'),
        name: 'discount-promotion-plans',
        meta: {		
            guest: false,
            needsAuth: true,	
        }
    },
    {
        path: '/settings/discount-promotion-plans-add',
        component: () => import('../components/DiscountPromotionPlanAdd.vue'),
        name: 'discount-promotion-plans-add',
        meta: {		
            guest: false,
            needsAuth: true,	
        }
    },
]