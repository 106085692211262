export const showDeleteFeedback = (state, flag) => {
	state.showDeleteFeedback = flag
}

export const setFeedbackList = (state, data) => {
	state.feedbackList.data = data.data.data.data;
	state.feedbackList.links = data.data.data.links;
	state.feedbackList.current_page = data.data.data.current_page;
    state.feedbackList.meta = {
        current_page: data.data.data.current_page,
        from: data.data.data.from,
        to:  data.data.data.to,
        total: data.data.data.total,
        perPage: data.data.data.per_page
    }
}
export const setFeedbackId = (state, data) => {
	state.feedbackList.feedId = data;
}

export const showLoader = (state, flag) => {
	state.showLoader = flag
}
export const slideLoader = (state, flag) => {
	state.slideLoader = flag
}

// publish slide
export const setFeedbackPublishList = (state, data) => {
	return state.feedbackPublishList = data.data.data.data
}

export const setFeedbackCategoryList = (state, data) => {
	return state.category_name = data
}
////


