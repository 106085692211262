export default [
{
	path: '/activity-log',
	component: () => import('../components/ActivityLog.vue'),
	name: 'activity-log',
	
	meta: {
		guest: false,
		needsAuth: true
	}
},

]
