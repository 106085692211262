export const fetchMasterCourseMinor = ({ commit, dispatch }, query='') => {
    let url = process.env.VUE_APP_SMART_RECRUIT_API_URL + 'api/master-data-course-minor'+ query;
    return window.axios.get(url).then((response) => {
        commit('setMasterCourseMinorData', response.data);
        return true;
    }).catch((error) => {
        dispatch("auth/errorHandler",error.response.status,{ root:true });
        return false;
    })
}

export const fetchMasterExamDegreeList = ({ commit, dispatch }) => {
    let url = process.env.VUE_APP_SMART_RECRUIT_API_URL + 'api/master-degree-exam-list';
    return window.axios.get(url).then((response) => {
        return response.data;
    }).catch((error) => {
        dispatch("auth/errorHandler",error.response.status,{ root:true });
        return false;
    })
}


