export const getMasterEmployerTypeData = (state) => {
    return state.masterEmployerType.employerTypeData;
}

export const getMasterEmployerTypeLink = (state) => {
    return state.masterEmployerType.employerTypeLinks;
}

export const getMasterEmployerTypeMeta = (state) => {
    return state.masterEmployerType.employerTypeMeta;
}

export const getShowApproveRejectConfirmationModal = (state) => {
    return state.approveRejectEmployerType.showEmployerType;
}

export const getAction = (state) => {
    return state.approveRejectEmployerType.action;
}

export const getData = (state) => {
    return state.approveRejectEmployerType.data;
}

export const getShowEditModal = (state) => {
    return state.editEmployerType.showEmployerType;
}

