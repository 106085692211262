export default {
	autoEmailDispatch: {
		mail_mailer:'',
		mail_host:'',
		mail_port:'',
		mail_username:'',
		mail_password:'',
		mail_encryption:'',
		mail_from_email:'',
		mail_from_name:'',
	},
}