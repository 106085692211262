export default {
	userList: {
		data: null,
		links: null,
		meta: null,
		current_page:null
	},
	myRoles: [],
	cities: [],
	profile_pic: '',
	editUser: {
		name:"",
		email:"",
		password:"",
		confirm_password:"",
		user_role: "",
		last_name: "",
		company_logo: "",
		designation:"",
		department:"",
		pin_code: "",
		company_name: "",
		city: "",
		state: "",
		country: "",
		address_line_1: "",
		company_tel: "",
        company_phonecode: "",
		company_fax: "",
        company_fax_phonecode: "",
		mobile_number: "",
        mobile_phonecode: "",
		website: "",
		guard_name:process.env.VUE_APP_GUARD_NAME_ROLE,
		s_token: process.env.VUE_APP_SSO_PUBLIC_KEY,
		c_token: process.env.VUE_APP_SMART_RECRUIT_PUBLIC_KEY
	},
	ssoData:{
		id:""
	}
}