export default {

    masterLanguageLevel:{
        languageLevelData:null,
        languageLevelLinks:null,
        languageLevelMeta:null,
    },

    approveRejectLanguageLevel: {
        showLanguageLevel: false,
        action:'',
        data:''
    },
    
    editLanguageLevel: {
        showLanguageLevel: false,
    },
    
};