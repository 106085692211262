export const getShowAddAgreement = (state) => {
    return state.addAgreement.showAgreement;
}
export const getShowEditAgreement = (state) => {
    return state.editAgreement.showAgreement;
}
export const getAgreementId = (state) => {
    return state.contract.agreementId;
};
export const getEditId = (state) => {
    return state.setEditId;
};

export const getContractData = (state) => {
    return state.contract.contractData;
};

export const getContractLink = (state) => {
    return state.contract.contractLinks;
}
export const getContractMeta = (state) => {
    return state.contract.contractMeta;
}

