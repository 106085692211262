<template>
  <div> 
    <div class="relative">
      <img src="images/myProfile.png" class="w-full h-full">
      <div class="absolute inset-0 bottom-0 lg:-bottom-10 border-none flex flex-col items-center justify-center">
        <label class="text-white text-6xl font-semibold">My Profile</label>
        <label class="w-28 pt-6 border-b-4 border-white"></label>
        <label class="w-20 pt-2 border-b-4 border-white"></label>        
      </div>
    </div>   
  </div>
</template>

<script>

export default  ({

 data: function() {
  return {

  }
},
components: {
},

methods: {

},
});
</script>

<style>

</style>