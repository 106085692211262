export default {
	user: {
		token: null,
		authenticated: false,
		data: null,
		roleName: null,
		roleSlug: null,
		bellNotifications: {
			count: 0,
			messages: []
		}
	},

	showTab: true
}