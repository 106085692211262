export const postGeneralSettings = ({ commit, dispatch}, { payload, context}) => {
    return window.axios.post (process.env.VUE_APP_SMART_RECRUIT_API_URL + 'api/site-setting', payload).then((response) => {
        // if(response.data.success){
        //     commit('SET_ALERT', 'success', { root: true })
        //     commit('SET_ALERT_MESSAGE', response.data.message, { root: true })
        // }else{
        //     commit('SET_ALERT', 'warning', { root: true })
        //     commit('SET_ALERT_MESSAGE', response.data.message, { root: true })
        // }
        
        return response.data.success
    }).catch((error) => {
        dispatch("auth/errorHandler",error.response.status,{ root:true });
        commit('SET_ALERT', 'warning', { root: true })
        commit('SET_ALERT_MESSAGE', 'Something went wrong', { root: true })
        context.errors = error.response.data
        return response.data.success
    })
};

export const fetchGeneralSettings = ({ commit, dispatch}) => {
    return window.axios.get(process.env.VUE_APP_SMART_RECRUIT_API_URL + 'api/site-setting').then((response) => {
        commit('setGeneralSettingsData', response)
        return response.data.success;
    }).catch((error) => {
        dispatch("auth/errorHandler",error.response.status,{ root:true });
        commit("SET_ALERT_MESSAGE", "Something went wrong", { root: true });
        return response.data.success;
    });   
};