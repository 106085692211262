export default [
	{
        path: '/projects',
        component: () => import('../components/ProjectList.vue'),
        name: 'projects',
        meta: { 
            guest: false,
            needsAuth: true,        
        }
    },
    {
        path: '/view-project',
        component: () => import('../components/ViewProject.vue'),
        name: 'view-project',
        meta: { 
            guest: false,
            needsAuth: true,        
        }
    },


]