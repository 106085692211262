export default {
	showDeleteFeedback: false,

   	showLoader: false,  	
   	slideLoader: false,  	
   	
   	feedbackList:{
        data: null,
        links: null,
        meta: null,
        current_page:null,
        feedId:''
    },

   	feedbackPublishList: [],

    category_name: '',	
}