export const fetchAdvertisement = ({commit, dispatch}, query='') => {
    let url = process.env.VUE_APP_SMART_RECRUIT_API_URL + 'api/advertisement'+ query;
    return window.axios.get(url).then((response) => {
        commit('setAdvertisementData', response.data);
        return true;
    }).catch((error) => {
        dispatch("auth/errorHandler",error.response.status,{ root:true });
        return false;
    })
}

export const deleteAdvertisement = ({ commit }, id) => {
    return window.axios.delete(process.env.VUE_APP_SMART_RECRUIT_API_URL +"api/advertisement/" + id).then((response) => {
      commit('SET_ALERT', 'success', { root: true })
      commit('SET_ALERT_MESSAGE', response.data.message, { root: true })
          return response.data;
    }).catch((error) => {
        commit('SET_ALERT', 'warning', { root: true })
        commit('SET_ALERT_MESSAGE', 'Something went wrong', { root: true })        
        return false
    });
};

export const fetchAdvertisementById = ({ commit}, id) => {
    return window.axios.get(process.env.VUE_APP_SMART_RECRUIT_API_URL + 'api/advertisement-get-details/'+id).then((response) => {
        commit('setAdvertisementById', response)
        return response.data.success;
    }).catch((error) => {
        commit("SET_ALERT", "warning", { root: true });
        commit("SET_ALERT_MESSAGE", "Something went wrong", { root: true });
        return false;
    });   
};

export const postAdvertisement = ({commit}, {payload, id, context})=>{
    return window.axios.post('api/advertisement/' + id,payload).then((response)=>{
        commit('SET_ALERT', 'success', { root: true })
        commit('SET_ALERT_MESSAGE', response.data.message, { root: true })
        return response.data;
    }).catch((error)=>{
        // commit('SET_ALERT', 'warning', { root: true })
        // commit('SET_ALERT_MESSAGE', 'Something went wrong', { root: true })
        context.errors = error.response.data
        return false
    })
}