export const fetchAdvertisment = ({ commit }) => {
    
}


export const fetchJobFairs = ({ commit }) => {
    
}

export const fetchRefferals = ({ commit }) => {
    
}


export const jobSeekerEmployerData = ({ commit }) => {
   
}




export const globalJobProjectsData = ({ commit }) => {
    
}