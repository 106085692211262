export const getEmpMembershipPlan = (state) => {
  return state.membershipPlan.empPlan;
}
export const getEmpMembershipPlanLink = (state) => {
  return state.membershipPlan.empLinks;
}
export const getEmpMembershipPlanMeta = (state) => {
  return state.membershipPlan.empMeta;
}
export const getTitpMembershipPlan = (state) => {
  return state.membershipPlan.titpPlan;
}
export const getTitpMembershipPlanLink = (state) => {
  return state.membershipPlan.titpLinks;
}
export const getTitpMembershipPlanMeta = (state) => {
  return state.membershipPlan.titpMeta;
}
export const getShowAddMembershipPlan = (state) => {
  return state.showAddMembershipPlan
}
export const getShowAddTitpMembershipPlan = (state) => {
  return state.showAddTitpMembershipPlan
}
export const getHideAddMembershipPlan = (state) => {
  return state.hideAddMembershipPlan
} 
export const getHideAddTitpMembershipPlan = (state) => {
  return state.hideAddTitpMembershipPlan
}
export const getShowEditMembershipPlan = (state) => {
  return state.showEditMembershipPlan
}
export const getShowEditTitpMembershipPlan = (state) => {
  return state.showEditTitpMembershipPlan
}
export const getHideEditMembershipPlan = (state) => {
  return state.hideEditMembershipPlan
}
export const getHideEditTitpMembershipPlan = (state) => {
  return state.hideEditTitpMembershipPlan
}
export const getShowDeleteMembershipPlan = (state) =>{
  return state.showDeleteMembershipPlan
}
export const getShowDeleteTitpMembershipPlan = (state) =>{
  return state.showDeleteTitpMembershipPlan
}
export const getHideDeleteMembershipPlan =(state) => {
  return state.hideDeleteMembershipPlan
}
export const getHideDeleteTitpMembershipPlan =(state) => {
  return state.hideDeleteTitpMembershipPlan
}
export const getPlanId = (state) => {
  return  state.membershipPlan.empPlanId;
}
export const getTitpPlanId = (state) => {
  return  state.membershipPlan.titpPlanId;
}
