export const getDeleteCredit = (state) => {
	return state.showDeleteCredit
}

export const getDeleteExpenses = (state) => {
	return state.showDeleteExpenses
}

export const getDeleteAudit = (state) => {
	return state.showDeleteAudit
}