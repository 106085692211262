export const getMasterStrengthData = (state) => {
    return state.masterStrength.strengthData;
}

export const getMasterStrengthLink = (state) => {
    return state.masterStrength.strengthLinks;
}

export const getMasterStrengthMeta = (state) => {
    return state.masterStrength.strengthMeta;
}

export const getShowApproveRejectConfirmationModal = (state) => {
    return state.approveRejectStrength.showStrength;
}

export const getAction = (state) => {
    return state.approveRejectStrength.action;
}

export const getData = (state) => {
    return state.approveRejectStrength.data;
}

export const getShowEditModal = (state) => {
    return state.editStrength.showStrength;
}

