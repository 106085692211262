export const getMasterPositionData = (state) => {
    return state.masterPosition.positionData;
}

export const getMasterPositionLink = (state) => {
    return state.masterPosition.positionLinks;
}

export const getMasterPositionMeta = (state) => {
    return state.masterPosition.positionMeta;
}

export const getShowApproveRejectConfirmationModal = (state) => {
    return state.approveRejectPosition.showPosition;
}

export const getAction = (state) => {
    return state.approveRejectPosition.action;
}

export const getData = (state) => {
    return state.approveRejectPosition.data;
}

export const getShowEditModal = (state) => {
    return state.editPosition.showPosition;
}

