export const jobViewData = ({ commit, context }, query = '') => {
    return window.axios.get( process.env.VUE_APP_SMART_RECRUIT_API_URL + "api/jobs" + query ).then((response) => {
        commit('SET_JOB_LIST', response)
        return true
    }).catch((error) => {
        // commit("SET_ALERT", "warning", { root: true });
        // commit("SET_ALERT_MESSAGE", "Something went Job view", { root: true });
        context.errors = error.response.data.errors;
        return false;
    });
};

export const jobShowData = ({ commit, dispatch }, { payload, context }) => {
    return window.axios.get( process.env.VUE_APP_SMART_RECRUIT_API_URL + "api/job/view/"+payload+"?fullReadMode=true").then((response) => {
        commit("SET_ALERT_MESSAGE", response.data.message, { root: true });
        return response.data;
    }).catch((error) => {
        context.errors = error.response.data.errors;
        return false;
    });
};

export const jobCreate = ({ commit, dispatch }, { payload, context }) => {
  return window.axios.post( process.env.VUE_APP_SMART_RECRUIT_API_URL + "api/job/create?allowCreation=true&permissionCheck=true", payload).then((response) => {      // Showing  success message
      commit('SET_ALERT', 'success', { root: true })
      commit('SET_ALERT_MESSAGE', response.data.message, { root: true })
      return response.data
  }).catch((error) => {
      commit('SET_ALERT', 'warning', { root: true })
      commit('SET_ALERT_MESSAGE', 'Something went wrong To Upload Job Form ', { root: true })
      context.errors = error.response.data.errors
      return error.response.data
  })
};

export const jobUpdate = ({ commit, dispatch }, { payload, context }) => {
  return window.axios.post( process.env.VUE_APP_SMART_RECRUIT_API_URL + "api/job/update?allowUpdation=true&permissionCheck=true", payload).then((response) => {      // Showing  success message
      commit('SET_ALERT', 'success', { root: true })
      commit('SET_ALERT_MESSAGE', response.data.message, { root: true })
      return response.data
  }).catch((error) => {
      commit('SET_ALERT', 'warning', { root: true })
      commit('SET_ALERT_MESSAGE', 'Something went wrong To Upload Job Form ', { root: true })
      context.errors = error.response.data.errors
      return error.response.data
  })
};

export const jobEditSubmit = ({ commit, dispatch }, { payload, context, id}) => {
  return window.axios.post( process.env.VUE_APP_SMART_RECRUIT_API_URL + "api/employee/job/update/"+id+"", payload).then((response) => {      // Showing  success message
      commit('SET_ALERT', 'success', { root: true })
      commit('SET_ALERT_MESSAGE', response.data.message, { root: true })
      return true
  }).catch((error) => {
      commit('SET_ALERT', 'warning', { root: true })
      commit('SET_ALERT_MESSAGE', 'Something went wrong To Upload Job edit Form ', { root: true })
      context.errors = error.response.data
       return error.response.data
  })
};

export const jobDeleteData = ({ commit, context }, id) => {
    return window.axios.get( process.env.VUE_APP_SMART_RECRUIT_API_URL + "api/job/delete/"+id+"").then((response) => {
      commit('SET_ALERT', 'success', { root: true })
      commit('SET_ALERT_MESSAGE', response.data.message, { root: true })
          return response.data.success
    }).catch((error) => {
        commit("SET_ALERT", "warning", { root: true });
        commit("SET_ALERT_MESSAGE", "Something went Job delete", { root: true });
        context.errors = error.response.data.errors;
        return false;
    });
};

export const jobTypeData = ({ commit, context }) => {
    return window.axios.get( process.env.VUE_APP_SMART_RECRUIT_API_URL + "api/managejob/job-type").then((response) => {
        commit("SET_ALERT_MESSAGE", response.data.message, { root: true });
        return response.data.data;
    }).catch((error) => {
        context.errors = error.response.data.errors;
        return false;
    });
};

export const strengthData = ({ commit, context }) => {
    return window.axios.get( process.env.VUE_APP_SMART_RECRUIT_API_URL + "api/strength-list").then((response) => {
        commit("SET_ALERT_MESSAGE", response.data.message, { root: true });
        return response.data.data;
    }).catch((error) => {
        context.errors = error.response.data.errors;
        return false;
    });
};
export const positionData = ({ commit, context }) => {
    return window.axios.get( process.env.VUE_APP_SMART_RECRUIT_API_URL + "api/master-data-position").then((response) => {
        commit("SET_ALERT_MESSAGE", response.data.message, { root: true });
        return response.data.data;
    }).catch((error) => {
        context.errors = error.response.data.errors;
        return false;
    });
};
export const keySkillsData = ({ commit, context }) => {
    return window.axios.get( process.env.VUE_APP_SMART_RECRUIT_API_URL + "api/master-data-keyskill?all=true").then((response) => {
        return response.data.data.data;
    }).catch((error) => {
        context.errors = error.response.data.errors;
        return false;
    });
};
export const functionAreaData = ({ commit, context }) => {
    return window.axios.get( process.env.VUE_APP_SMART_RECRUIT_API_URL + "api/master-data-function-area?all=true").then((response) => {
        return response.data.data.data;
    }).catch((error) => {
        context.errors = error.response.data.errors;
        return false;
    });
};

export const workStyleData = ({ commit, context }) => {
    return window.axios.get( process.env.VUE_APP_SMART_RECRUIT_API_URL + "api/master-data-work-style?all=true").then((response) => {
        return response.data.data.data;
    }).catch((error) => {
        context.errors = error.response.data.errors;
        return false;
    });
};

export const educationData = ({ commit, context }) => {
    return window.axios.get( process.env.VUE_APP_SMART_RECRUIT_API_URL + "api/master-data-exam-degree?all=true").then((response) => {
        return response.data.data.data;
    }).catch((error) => {
        context.errors = error.response.data.errors;
        return false;
    });
};

export const hiringSourceData = ({ commit, context }) => {
    return window.axios.get( process.env.VUE_APP_SMART_RECRUIT_API_URL + "api/site-setting").then((response) => {
        return response.data;
    }).catch((error) => {
        context.errors = error.response.data.errors;
        return false;
    });
};
export const visaStatusData = ({ commit, context }) => {
  return window.axios.get( process.env.VUE_APP_SMART_RECRUIT_API_URL + "api/managejob/visa-status").then((response) => {
  commit("SET_ALERT_MESSAGE", response.data.message, { root: true });
  return response.data.data;
  }).catch((error) => {
    context.errors = error.response.data.errors;
    return false;
  });
};

export const currencyData = ({ commit, context }) => {
  return window.axios.get( process.env.VUE_APP_SMART_RECRUIT_API_URL + "api/managejob/currency").then((response) => {
  commit("SET_ALERT_MESSAGE", response.data.message, { root: true });
  return response.data.data;
  }).catch((error) => {
    context.errors = error.response.data.errors;
    return false;
  });
};

export const commonCurrencyData = ({ commit, context }) => {
    return window.axios.get( process.env.VUE_APP_SMART_RECRUIT_API_URL + "api/currency").then((response) => {
    commit("SET_ALERT_MESSAGE", response.data.message, { root: true });
    return response.data.data;
    }).catch((error) => {
      context.errors = error.response.data.errors;
      return false;
    });
  };

export const languageData = ({ commit, context }) => {
  return window.axios.get( process.env.VUE_APP_SMART_RECRUIT_API_URL + "api/master-data-language?all=true").then((response) => {
      commit("SET_ALERT_MESSAGE", response.data.message, { root: true });
      return response.data.data;
  }).catch((error) => {
      context.errors = error.response.data.errors;
      return false;
  });
};
export const languageLevelData = ({ commit, context }) => {
  return window.axios.get( process.env.VUE_APP_SMART_RECRUIT_API_URL + "api/master-data-language-level?all=true").then((response) => {
      commit("SET_ALERT_MESSAGE", response.data.message, { root: true });
      return response.data.data;
  }).catch((error) => {
      context.errors = error.response.data.errors;
      return false;
  });
};


export const workflowData = ({ commit, context }) => {
    return window.axios.get( process.env.VUE_APP_SMART_RECRUIT_API_URL + "api/managejob/workflow").then((response) => {
        commit("SET_ALERT_MESSAGE", response.data.message, { root: true });
        return response.data.data;
    }).catch((error) => {
        context.errors = error.response.data.errors;
        return false;
    });
};


export const industryData = ({ commit, context }) => {
    return window.axios.get( process.env.VUE_APP_SMART_RECRUIT_API_URL + "api/master-data-industry-type?all=true").then((response) => {
        return response.data.data.data;
    }).catch((error) => {
        context.errors = error.response.data.errors;
        return false;
    });
};
export const industrySubCatData = ({ commit, context }, ids) => {
    return window.axios.get( process.env.VUE_APP_SMART_RECRUIT_API_URL + "api/master-multiple-industry-sub-list", {
      params: { "industryList": ids },
    }).then((response) => {
        return response.data.data;
    }).catch((error) => {
        context.errors = error.response.data.errors;
        return false;
    });
};

export const courseData = ({ commit, context }, ids) => {
    return window.axios.get( process.env.VUE_APP_SMART_RECRUIT_API_URL + "api/master-education-course-list", {
      params: { "educationList": ids },
    }).then((response) => {
        return response.data.data;
    }).catch((error) => {
        context.errors = error.response.data.errors;
        return false;
    });
};

export const docDelete = ({ commit, context }, id) => {
    return window.axios.get( process.env.VUE_APP_SMART_RECRUIT_API_URL + "api/docs/delete/"+id+"").then((response) => {
      commit('SET_ALERT', 'success', { root: true })
      commit('SET_ALERT_MESSAGE', response.data.message, { root: true })
          return response.data.success

    }).catch((error) => {
        commit("SET_ALERT", "warning", { root: true });
        commit("SET_ALERT_MESSAGE", "Something went Job doc delete", { root: true });
        context.errors = error.response.data.errors;
        return false;
    });
};

export const countryData = ({ commit, context }) => {
    return window.axios.get("api/match-country").then((response) => {
        commit("SET_ALERT_MESSAGE", response.data.message, { root: true });
        return response.data.data;
    }).catch((error) => {
        context.errors = error.response.data.errors;
        return false;
    });
};

export const stateMatchData = ({ commit, context }, id) => {
  return window.axios.get("api/match-state", {
      params: { id },
    }).then((response) => {
      commit("SET_ALERT_MESSAGE", response.data.message, { root: true });
      return response.data.data;
    }).catch((error) => {
      context.errors = error.response.data.errors;
      return false;
    })  
};
export const cityMatchData = ({ commit, context }, id) => {

  return window.axios.get( process.env.VUE_APP_SSO_API_URL + "api/get-city/"+id+"").then((response) => {
      commit("SET_ALERT_MESSAGE", response.data.message, { root: true });
      // return response.data.data;
    }).catch((error) => {
      context.errors = error.response.data.errors;
      return false;
    })  
};

export const getAppliedJobs = ({ commit, context }, id) => {
    return window.axios.get( process.env.VUE_APP_SMART_RECRUIT_API_URL + "api/jobseeker/get-applied-jobs").then((response) => {
        commit("SET_APPLIED_JOBS", response.data.jobs)
    }).catch((error) => {
        return false;
    })  
};

export const getJobDetails = ({ commit, context }, id) => {
    return window.axios.get( process.env.VUE_APP_SMART_RECRUIT_API_URL + "api/job/view/" + id + "?fullReadMode=true").then((response) => {
        commit('SET_JOB_APPLICATION', response)
        commit('SET_JOB_DETAILS', response)
        return response.data
    }).catch((error) => {
        // commit("SET_ALERT", "warning", { root: true });
        // commit("SET_ALERT_MESSAGE", "Something went Job view", { root: true });
        context.errors = error.response.data.errors;
        return false;
    });
};

export const applyJob = ({ commit, dispatch }, { payload, context }) => {
    return window.axios.post( process.env.VUE_APP_SMART_RECRUIT_API_URL + "api/jobseeker/apply", payload).then((response) => {
        commit('SET_ALERT', 'success', { root: true })
        commit('SET_ALERT_MESSAGE', response.data.message, { root: true })
        return true
    }).catch((error) => {
        // commit("SET_ALERT", "warning", { root: true });
        // commit("SET_ALERT_MESSAGE", "Something went Job view", { root: true });
        context.errors = error.response.data.errors;
        return false;
    });
};

export const revokeJobApplication = ({ commit, context }, id) => {
    return window.axios.get( process.env.VUE_APP_SMART_RECRUIT_API_URL + "api/jobseeker/revoke-job-application/" + id).then((response) => {
        commit('SET_ALERT', 'success', { root: true })
        commit('SET_ALERT_MESSAGE', response.data.message, { root: true })
        return response.data
    }).catch((error) => {
        // commit("SET_ALERT", "warning", { root: true });
        // commit("SET_ALERT_MESSAGE", "Something went Job view", { root: true });
        context.errors = error.response.data.errors;
        return false;
    });
};

export const getMyResume = ({ commit, context }, {type, language}) => {
    return window.axios.get( process.env.VUE_APP_SMART_RECRUIT_API_URL + "api/jobseeker/my-resume?profiletype="+type + "&resume_language=" + language).then((response) => {
        commit("SET_MY_RESUME", response.data)
    }).catch((error) => {
        return false;
    })  
};

export const getRejectReasons = ({ commit, context }) => {
    return window.axios.get( process.env.VUE_APP_SMART_RECRUIT_API_URL + "api/get-application-reject-reasons").then((response) => {
        return response.data
    }).catch((error) => {
        return false;
    })  
};

export const getApplicants = ({ commit }, {id, query = ''}) => {
    return window.axios.get( process.env.VUE_APP_SMART_RECRUIT_API_URL + "api/jobs/get-applicants/" + id+query).then((response) => {
        commit('SET_APPLICANTS', response)
        return response.data
    }).catch((error) => {
        return false;
    });
};
export const getMatchingApplicants = ({ commit }, {id, query = ''}) => {
    return window.axios.get( process.env.VUE_APP_SMART_RECRUIT_API_URL + "api/jobs/get-matching-applicants/" + id + query).then((response) => {
        commit('SET_MATCHING_APPLICANTS', response)
        return response.data
    }).catch((error) => {
        return false;
    });
};

export const getJobApplication = ({ commit, context }, id) => {
    return window.axios.get( process.env.VUE_APP_SMART_RECRUIT_API_URL + "api/managejob/get-job-application/" + id).then((response) => {
        if (response.data.success) {
            commit('SET_JOB_APPLICATION', response.data)
            commit('SET_ALERT', 'success', { root: true })
            commit('SET_ALERT_MESSAGE', response.data.message, { root: true })
        } else {
            commit('SET_ALERT', 'warning', { root: true })
            commit('SET_ALERT_MESSAGE', response.data.message, { root: true })
        }        
        return response.data
    }).catch((error) => {
        commit("SET_ALERT", "warning", { root: true });
        commit("SET_ALERT_MESSAGE", "Something went wrong", { root: true });
        context.errors = error.response.data.errors;
        return false;
    });
};

export const changeApplicationStatus = ({ commit, dispatch }, { payload, context }) => {
    return window.axios.post( process.env.VUE_APP_SMART_RECRUIT_API_URL + "api/change-applied-job-status", payload).then((response) => {
        commit('SET_ALERT', 'success', { root: true })
        commit('SET_ALERT_MESSAGE', response.data.message, { root: true })
        return true
    }).catch((error) => {
        commit("SET_ALERT", "warning", { root: true });
        commit("SET_ALERT_MESSAGE", "Something went Wrong", { root: true });
        return false;
    });
};

export const scheduleInterview = ({ commit, dispatch }, { payload, context }) => {
    return window.axios.post( process.env.VUE_APP_SMART_RECRUIT_API_URL + "api/schedule-job-interview", payload).then((response) => {
        commit('SET_ALERT', 'success', { root: true })
        commit('SET_ALERT_MESSAGE', response.data.message, { root: true })
        return true
    }).catch((error) => {
        commit("SET_ALERT", "warning", { root: true });
        commit("SET_ALERT_MESSAGE", "Something went Wrong", { root: true });
        return false;
    });
};

export const updateJobMeeting = ({ commit, dispatch }, { payload, context }) => {
    return window.axios.post( process.env.VUE_APP_SMART_RECRUIT_API_URL + "api/update-meeting", payload).then((response) => {
        commit('SET_ALERT', 'success', { root: true })
        commit('SET_ALERT_MESSAGE', response.data.message, { root: true })
        return true
    }).catch((error) => {
        commit("SET_ALERT", "warning", { root: true });
        commit("SET_ALERT_MESSAGE", "Something went Wrong", { root: true });
        return false;
    });
};

export const updateJobStatus = ({ commit, dispatch }, { payload, context }) => {
    return window.axios.post( process.env.VUE_APP_SMART_RECRUIT_API_URL + "api/update-applied-job-status", payload).then((response) => {
        commit('SET_ALERT', 'success', { root: true })
        commit('SET_ALERT_MESSAGE', response.data.message, { root: true })
        return true
    }).catch((error) => {
        commit("SET_ALERT", "warning", { root: true });
        commit("SET_ALERT_MESSAGE", "Something went Wrong", { root: true });
        return false;
    });
};

export const uploadWelcomePhotos = ({ commit, dispatch }, { payload, context }) => {
    return window.axios.post( process.env.VUE_APP_SMART_RECRUIT_API_URL + "api/upload-welcome-photos", payload).then((response) => {
        commit('SET_ALERT', 'success', { root: true })
        commit('SET_ALERT_MESSAGE', response.data.message, { root: true })
        return true
    }).catch((error) => {
        commit("SET_ALERT", "warning", { root: true });
        commit("SET_ALERT_MESSAGE", "Something went Wrong", { root: true });
        return false;
    });
};

export const deleteWelcomePhoto = ({ commit, dispatch }, { payload, context }) => {
    return window.axios.post( process.env.VUE_APP_SMART_RECRUIT_API_URL + "api/delete-welcome-photo", payload).then((response) => {
        commit('SET_ALERT', 'success', { root: true })
        commit('SET_ALERT_MESSAGE', response.data.message, { root: true })
        return true
    }).catch((error) => {
        commit("SET_ALERT", "warning", { root: true });
        commit("SET_ALERT_MESSAGE", "Something went Wrong", { root: true });
        return false;
    });
};

export const acceptJobOfferApi = ({ commit, dispatch }, { payload, context }) => {
    return window.axios.post( process.env.VUE_APP_SMART_RECRUIT_API_URL + "api/accept-job-offer", payload).then((response) => {
        commit('SET_ALERT', 'success', { root: true })
        commit('SET_ALERT_MESSAGE', response.data.message, { root: true })
        return true
    }).catch((error) => {
        commit("SET_ALERT", "warning", { root: true });
        commit("SET_ALERT_MESSAGE", "Something went Wrong", { root: true });
        return false;
    });
};

export const declineJobOfferApi = ({ commit, dispatch }, { payload, context }) => {
    return window.axios.post( process.env.VUE_APP_SMART_RECRUIT_API_URL + "api/decline-job-offer", payload).then((response) => {
        commit('SET_ALERT', 'success', { root: true })
        commit('SET_ALERT_MESSAGE', response.data.message, { root: true })
        return true
    }).catch((error) => {
        commit("SET_ALERT", "warning", { root: true });
        commit("SET_ALERT_MESSAGE", "Something went Wrong", { root: true });
        return false;
    });
};

export const revokeJobOfferApi = ({ commit, dispatch }, { payload, context }) => {
    return window.axios.post( process.env.VUE_APP_SMART_RECRUIT_API_URL + "api/revoke-job-offer", payload).then((response) => {
        commit('SET_ALERT', 'success', { root: true })
        commit('SET_ALERT_MESSAGE', response.data.message, { root: true })
        return true
    }).catch((error) => {
        commit("SET_ALERT", "warning", { root: true });
        commit("SET_ALERT_MESSAGE", "Something went Wrong", { root: true });
        return false;
    });
};

export const negotiateJobOfferApi = ({ commit, dispatch }, { payload, context }) => {
    return window.axios.post( process.env.VUE_APP_SMART_RECRUIT_API_URL + "api/negotiate-job-offer", payload).then((response) => {
        commit('SET_ALERT', 'success', { root: true })
        commit('SET_ALERT_MESSAGE', response.data.message, { root: true })
        return true
    }).catch((error) => {
        commit("SET_ALERT", "warning", { root: true });
        commit("SET_ALERT_MESSAGE", "Something went Wrong", { root: true });
        return false;
    });
};

export const addVisaDocumentsApi = ({ commit, dispatch }, { payload, context }) => {
    return window.axios.post( process.env.VUE_APP_SMART_RECRUIT_API_URL + "api/add-visa-documents", payload).then((response) => {
        commit('SET_ALERT', 'success', { root: true })
        commit('SET_ALERT_MESSAGE', response.data.message, { root: true })
        return true
    }).catch((error) => {
        commit("SET_ALERT", "warning", { root: true });
        commit("SET_ALERT_MESSAGE", "Something went Wrong", { root: true });
        return false;
    });
};

export const uploadVisaDocumentsApi = ({ commit, dispatch }, { payload, context }) => {
    return window.axios.post( process.env.VUE_APP_SMART_RECRUIT_API_URL + "api/upload-visa-documents", payload).then((response) => {
        commit('SET_ALERT', 'success', { root: true })
        commit('SET_ALERT_MESSAGE', response.data.message, { root: true })
        return true
    }).catch((error) => {
        commit("SET_ALERT", "warning", { root: true });
        commit("SET_ALERT_MESSAGE", "Something went Wrong", { root: true });
        return false;
    });
};

export const uploadOtherVisaDocumentApi = ({ commit, dispatch }, { payload, context }) => {
    return window.axios.post( process.env.VUE_APP_SMART_RECRUIT_API_URL + "api/upload-other-visa-document", payload).then((response) => {
        commit('SET_ALERT', 'success', { root: true })
        commit('SET_ALERT_MESSAGE', response.data.message, { root: true })
        return true
    }).catch((error) => {
        commit("SET_ALERT", "warning", { root: true });
        commit("SET_ALERT_MESSAGE", "Something went Wrong", { root: true });
        return false;
    });
};

export const removeVisaDocumentsApi = ({ commit, dispatch }, { payload, context }) => {
    return window.axios.post( process.env.VUE_APP_SMART_RECRUIT_API_URL + "api/remove-visa-document", payload).then((response) => {
        commit('SET_ALERT', 'success', { root: true })
        commit('SET_ALERT_MESSAGE', response.data.message, { root: true })
        return true
    }).catch((error) => {
        commit("SET_ALERT", "warning", { root: true });
        commit("SET_ALERT_MESSAGE", "Something went Wrong", { root: true });
        return false;
    });
};

export const fetchGeneral = ({ commit}) => {
  return window.axios.get('/api/general/list').then((response) => {
      commit('SET_HIRING_SOURCE', response.data[0])
      return response.data;
  }).catch((error) => {
      commit("SET_ALERT", "warning", { root: true });
      commit("SET_ALERT_MESSAGE", "Something went wrong", { root: true });
      return false;
  });   
};

export const fetchHiringSource = ({ commit}) => {
    return window.axios.get("api/simple/search?search_category=employers&allResults=true").then((response) => {
        commit("SET_EMPLOYER_DATA", response.data)
    })
    .catch((error) => {
        commit("SET_ALERT", "warning", { root: true });
        commit("SET_ALERT_MESSAGE", "Something went wrong", { root: true });
        return false;
    });   
};

export const sendJobOffer = ({ commit, dispatch }, { payload, context }) => {
    return window.axios.post( process.env.VUE_APP_SMART_RECRUIT_API_URL + "api/send-job-offer", payload).then((response) => {
        commit('SET_ALERT', 'success', { root: true })
        commit('SET_ALERT_MESSAGE', response.data.message, { root: true })
        return true
    }).catch((error) => {
        commit("SET_ALERT", "warning", { root: true });
        commit("SET_ALERT_MESSAGE", "Something went Wrong", { root: true });
        return false;
    });
};

export const getJobseekerResume = ({ commit, context }, id) => {
    return window.axios.get( process.env.VUE_APP_SMART_RECRUIT_API_URL + "api/jobseeker/resume-for-employer/"+id).then((response) => {
        commit("SET_JOBSEEKER_RESUME", response.data)
        return response.data.success;
    }).catch((error) => {
        return false;
    })  
};


export const fetchProfileList = ({commit}) => {
    return window.axios.get(process.env.VUE_APP_SMART_RECRUIT_API_URL + 'api/jobseeker-profile-count').then((response) => {          
        commit('setProfileList', response.data)
        return response.data
    })
}


// delete job seeker profilw model 11-08-022
export const deleteJobseekerProfile = ({ commit }, { profile_type, resume_language }) => {
    return window.axios.delete(process.env.VUE_APP_SMART_RECRUIT_API_URL + `api/jobseeker/delete/` + profile_type + `/` + resume_language).then((response) => {
        commit('SET_ALERT', 'success', { root: true })
        commit('SET_ALERT_MESSAGE', response.data.message, { root: true })     
        return response.data.success
    }).catch((error) => {
      commit("SET_ALERT", "warning", { root: true });
      commit("SET_ALERT_MESSAGE", "Something went wrong", { root: true });
      return false;
  });  

}
///////////

// make master profile 12-08-2022
export const makeMasterProfile = ({ commit, dispatch }, { payload, context }) => {
  return window.axios.put( process.env.VUE_APP_SMART_RECRUIT_API_URL + "api/jobseeker/master-profile", payload).then((response) => {      // Showing  success message
      commit('SET_ALERT', 'success', { root: true })
      commit('SET_ALERT_MESSAGE', response.data.message, { root: true })
      return response.data
  }).catch((error) => {
      commit('SET_ALERT', 'warning', { root: true })
      commit('SET_ALERT_MESSAGE', 'Something went wrong To Upload Job Form ', { root: true })
      context.errors = error.response.data
      return error.response.data
  })
};
////

// deactivate 12-08-2022
export const deactivateProfile = ({ commit, dispatch }, { payload, context }) => {
  return window.axios.put( process.env.VUE_APP_SMART_RECRUIT_API_URL + "api/jobseeker/status-change", payload).then((response) => {      // Showing  success message
      commit('SET_ALERT', 'success', { root: true })
      commit('SET_ALERT_MESSAGE', response.data.message, { root: true })
      return response.data
  }).catch((error) => {
      commit('SET_ALERT', 'warning', { root: true })
      commit('SET_ALERT_MESSAGE', 'Something went wrong To Upload Job Form ', { root: true })
      context.errors = error.response.data
      return error.response.data
  })
};
////

export const fetchResumeLanguage = ({commit}, type) => {
    return window.axios.get(process.env.VUE_APP_SMART_RECRUIT_API_URL + 'api/jobseeker-resume-language?profiletype=' + type).then((response) => {          
        commit('setResumeLanguage', response.data)
        return response.data
    })
};

export const storeJobStrength = ({ commit, dispatch }, { payload, context }) => {
  return window.axios.post( process.env.VUE_APP_SMART_RECRUIT_API_URL + "api/master-data-strength/0", payload).then((response) => {      // Showing  success message
      commit('SET_ALERT', 'success', { root: true })
      commit('SET_ALERT_MESSAGE', response.data.message, { root: true })
      return response.data
  }).catch((error) => {
      commit('SET_ALERT', 'warning', { root: true })
      commit('SET_ALERT_MESSAGE', 'Something went wrong To Upload Job Form ', { root: true })
      context.errors = error.response.data
      return error.response.data
  })
};

export const storeKeySkill = ({ commit, dispatch }, { payload, context }) => {
  return window.axios.post( process.env.VUE_APP_SMART_RECRUIT_API_URL + "api/master-data-keyskill/0", payload).then((response) => {      // Showing  success message
      commit('SET_ALERT', 'success', { root: true })
      commit('SET_ALERT_MESSAGE', response.data.message, { root: true })
      return response.data
  }).catch((error) => {
      commit('SET_ALERT', 'warning', { root: true })
      commit('SET_ALERT_MESSAGE', 'Something went wrong To Upload Job Form ', { root: true })
      context.errors = error.response.data
      return error.response.data
  })
};

export const storeFunArea = ({ commit, dispatch }, { payload, context }) => {
  return window.axios.post( process.env.VUE_APP_SMART_RECRUIT_API_URL + "api/master-data-function-area/0", payload).then((response) => {      // Showing  success message
      commit('SET_ALERT', 'success', { root: true })
      commit('SET_ALERT_MESSAGE', response.data.message, { root: true })
      return response.data
  }).catch((error) => {
      commit('SET_ALERT', 'warning', { root: true })
      commit('SET_ALERT_MESSAGE', 'Something went wrong To Upload Job Form ', { root: true })
      context.errors = error.response.data
      return error.response.data
  })
};

export const storeIndustry = ({ commit, dispatch }, { payload, context }) => {
  return window.axios.post( process.env.VUE_APP_SMART_RECRUIT_API_URL + "api/master-data-industry-type/0", payload).then((response) => {      // Showing  success message
      commit('SET_ALERT', 'success', { root: true })
      commit('SET_ALERT_MESSAGE', response.data.message, { root: true })
      return response.data
  }).catch((error) => {
      commit('SET_ALERT', 'warning', { root: true })
      commit('SET_ALERT_MESSAGE', 'Something went wrong To Upload Job Form ', { root: true })
      context.errors = error.response.data
      return error.response.data
  })
};

export const storeWorkStyle = ({ commit, dispatch }, { payload, context }) => {
  return window.axios.post( process.env.VUE_APP_SMART_RECRUIT_API_URL + "api/master-data-work-style/0", payload).then((response) => {      // Showing  success message
      commit('SET_ALERT', 'success', { root: true })
      commit('SET_ALERT_MESSAGE', response.data.message, { root: true })
      return response.data
  }).catch((error) => {
      commit('SET_ALERT', 'warning', { root: true })
      commit('SET_ALERT_MESSAGE', 'Something went wrong To Upload Job Form ', { root: true })
      context.errors = error.response.data
      return error.response.data
  })
};


export const storeEducation = ({ commit, dispatch }, { payload, context }) => {
  return window.axios.post( process.env.VUE_APP_SMART_RECRUIT_API_URL + "api/master-data-exam-course-minor/0", payload).then((response) => {      // Showing  success message
      commit('SET_ALERT', 'success', { root: true })
      commit('SET_ALERT_MESSAGE', response.data.message, { root: true })
      return response.data
  }).catch((error) => {
      commit('SET_ALERT', 'warning', { root: true })
      commit('SET_ALERT_MESSAGE', 'Something went wrong To Upload Job Form ', { root: true })
      context.errors = error.response.data
      return error.response.data
  })
};

export const storePosition = ({ commit, dispatch }, { payload, context }) => {
  return window.axios.post( process.env.VUE_APP_SMART_RECRUIT_API_URL + "api/master-data-position/0", payload).then((response) => {      // Showing  success message
      commit('SET_ALERT', 'success', { root: true })
      commit('SET_ALERT_MESSAGE', response.data.message, { root: true })
      return response.data
  }).catch((error) => {
      commit('SET_ALERT', 'warning', { root: true })
      commit('SET_ALERT_MESSAGE', 'Something went wrong To Upload Job Form ', { root: true })
      context.errors = error.response.data
      return error.response.data
  })
};
