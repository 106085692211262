export const getShowDeleteModel = (state) => {
	return state.showDeleteModel;
}
export const hideDeleteModel = (state) => {
	return state.hideDeleteModel;
}
export const getShowInviteReferrals = (state) => {
	return state.showInviteReferrals;
}
export const hideInviteReferrals = (state) => {
	return state.hideInviteReferrals;
}
export const getReferralListData = (state) => {
	return state.referralList.data;
}
export const getReferralListLinks = (state) => {
	return state.referralList.links;
}
export const getReferralListMeta = (state) => {
	return state.referralList.meta;
}
export const getRefferalId = (state) => {
    return  state.referralList.referralId;
}