export default {
    state: {    
      showDeleteMembershipPlan:false,
      hideDeleteMembershipPlan :false,
      showAddMembershipPlan:false,
      hideAddMembershipPlan :false, 
      showEditMembershipPlan:false,
      hideEditMembershipPlan :false, 
      showAddTitpMembershipPlan:false,
      hideAddTitpMembershipPlan :false,
      showDeleteTitpMembershipPlan:false,
      hideDeleteTitpMembershipPlan :false,
      showEditMembershipPlan:false,
      hideEditMembershipPlan :false, 
      showEditTitpMembershipPlan:false,
      hideEditTitpMembershipPlan :false, 
    },

    membershipPlan:{
      empPlan:null,
      empLinks:null,
      empMeta:null,
      titpPlan:null,
      titpLinks:null,
      titpMeta:null,
    }
}