export default [
	{
        path: '/agreements',
        component: () => import('../components/AgreementList.vue'),
        name: 'agreements',
        meta: { 
            guest: false,
            needsAuth: true,        
        }
    },
    {
        path: '/view-agreement/:id',
        component: () => import('../components/ViewAgreement.vue'),
        name: 'view-agreement',
        meta: { 
            guest: false,
            needsAuth: true,        
        }
    },
    {
        path: '/edit-agreement/:id',
        component: () => import('../components/EditAgreement.vue'),
        name: 'edit-agreement',
        meta: { 
            guest: false,
            needsAuth: true,        
        }
    },
    {
        path: '/edit-agreement/:id/:componentName',
        component: () => import('../components/EditAgreementDetails.vue'),
        name: 'edit-agreement-details',
        meta: { 
            guest: false,
            needsAuth: true,        
        }
    },
]