export default [
    {
        path: '/settings/comission',
        component: () => import('../components/Comission.vue'),
        name: 'comission',
        meta: {		
            guest: false,
            needsAuth: true,	
        }
    },
]