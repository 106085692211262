export const showAddAgreement = (state) => {
    state.addAgreement.showAgreement = true;
};
export const hideAddAgreement = (state) => {
    state.addAgreement.showAgreement = false;
    state.setEditId = '';
};

export const showEditAgreement = (state, flag) => {
    state.editAgreement.showAgreement = flag;
};

export const hideEditAgreement = (state) => {
    state.editAgreement.showAgreement = false;
};

export const setEditId = (state, id) => {
    state.setEditId = id;
};

export const setContractData = (state, data) => {
	state.contract.contractData = data.data.data.data;
	state.contract.contractLinks = data.data.data.links;

    state.contract.contractMeta = {
        current_page: data.data.data.current_page,
        from: data.data.data.from,
        to:  data.data.data.to,
        total: data.data.data.total,
        perPage: data.data.data.per_page
    }
}