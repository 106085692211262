export const setGeneralSettingsData = (state, data) => {
	state.general.base_url = data.data.data.base_url
	state.general.site_access_reminder_no = data.data.data.site_access_reminder_no
	state.general.site_access_reminder_type = data.data.data.site_access_reminder_type
	state.general.hiring_authorities_non_titp = data.data.data.hiring_authorities_non_titp
	state.general.hiring_authorities_titp = data.data.data.hiring_authorities_titp
	state.general.advertisements_per_week = data.data.data.advertisements_per_week
	state.general.job_fairs_no_allowed = data.data.data.job_fairs_no_allowed
	state.general.consumption_tax = data.data.data.consumption_tax
	state.general.tax_registration_number = data.data.data.tax_registration_number
	state.general.referrals_jobseekers_rewards = data.data.data.referrals_jobseekers_rewards
	state.general.referrals_companies_discount = data.data.data.referrals_companies_discount
	state.general.referrals_jobseeker_commission = data.data.data.referrals_jobseeker_commission
	state.min_contract_duration = data.data.data.min_contract_duration
}