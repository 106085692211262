export const showDeleteContract = (state, flag) => {
    state.contract.showDeleteContract = flag;
}
    
export const hideDeleteContract  = (state) => {
    state.contract.showDeleteContract = false;
}
export const showAddAgreement = (state, flag) => {
         state.addAgreement.showAgreement = flag;
        };
export const hideAddAgreement = (state) => {
        state.addAgreement.showAgreement = false;
};

export const showEditAgreement = (state, flag) => {
         state.editAgreement.showAgreement = flag;
};

export const hideEditAgreement = (state) => {
    state.editAgreement.showAgreement = false;
};

export const setContractData = (state, data) => {
         state.contract.agreements = data;
};

export const showAddTitpDocument = (state, flag) => {
         state.addTitpDocument.showTitpDocument = flag;
};
export const hideAddTitpDocument = (state) => {
        state.addTitpDocument.showTitpDocument = false;
};

export const showAddTitpCategory = (state, flag) => {
         state.addTitpCategory.showTitpCategory = flag;
};
export const hideAddTitpCategory = (state) => {
        state.addTitpCategory.showTitpCategory = false;
};

export const showAddTitpRestriction = (state, flag) => {
         state.addTitpRestriction.showTitpRestriction = flag;
};
export const hideAddTitpRestriction = (state) => {
        state.addTitpRestriction.showTitpRestriction = false;
};

export const setUserRole = (state, data) => {
    state.contract.userRole = data;
};
export const setLanguage = (state, data) => {
    state.contract.language = data;
};
export const setAgreementId = (state, id) => {
    state.contract.agreementId = id;
}
export const setVisibleToRoleSlug = (state, data) => {
    state.contract.visibleToRoleSlug = data;
}

export const showagrCheckModal = (state, flag) => {
         state.agrCheckModal.showAgreement = flag;
};
export const hideagrCheckModal = (state) => {
         state.agrCheckModal.showAgreement = false;
};

export const SET_AUTO_EMAIL_DISPATCH = (state, data) => {
    state.autoEmailDispatch.data = data;
};

export const SET_DISCOUNT_PROMOTION_PLAN_LIST = (state, data) => {
    state.discountPromotionPlanList.data = data.data.discountPromotions.data
    state.discountPromotionPlanList.links = data.data.discountPromotions
}

export const showDeleteDiscount = (state, flag) => {
    state.discountPromotionPlanList.showDeleteDiscount = flag
  }
export const hideDeleteDiscount = (state) => {
    state.discountPromotionPlanList.showDeleteDiscount = false
  }

  export const setDiscountPlanId = (state, id) => {
    state.discountPromotionPlanList.discountId = id;
}

export const setPromoPlanById = (state, data) => {
    state.discountPromotionPlanList.plan = data;
};

export const setGeneral = (state, data) => {
    state.general.data = data;
}

export const setStripePaymentSettings = (state, data) => {
    state.settings.pyament.stripe.data = data;
}

export const showSlackIntegration = (state, flag) => {
    state.slackintegration.showSlackIntegration = flag;
}
export const showAddModuleModel = (state, flag) => {
    state.slackintegration.showAddModuleModel = flag;
}
export const showEditWebhookUrl = (state, flag) => {
    state.slackintegration.showEditWebhookUrl = flag;
}
export const showEditModuleModel = (state, flag) => {
    state.slackintegration.showEditModuleModel = flag;
}
export const showDeleteWebhookUrl = (state, flag) => {
    state.slackintegration.showDeleteWebhookUrl = flag;
}
export const showDeleteModule = (state, flag) => {
    state.slackintegration.showDeleteModule = flag;
}
