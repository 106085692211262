export default [
  {
    path: "/settings",
    component: () => import("../components/Settings.vue"),
    name: "adminSetting",
    meta: {
      guest: false,
      needsAuth: false,
    },
  },
];
