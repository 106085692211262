export const getMasterIndustrySubTypeData = (state) => {
    return state.masterIndustrySubType.industrySubTypeData;
}

export const getMasterIndustrySubTypeLink = (state) => {
    return state.masterIndustrySubType.industrySubTypeLinks;
}

export const getMasterIndustrySubTypeMeta = (state) => {
    return state.masterIndustrySubType.industrySubTypeMeta;
}

export const getShowApproveRejectConfirmationModal = (state) => {
    return state.approveRejectIndustrySubType.showIndustrySubType;
}

export const getAction = (state) => {
    return state.approveRejectIndustrySubType.action;
}

export const getData = (state) => {
    return state.approveRejectIndustrySubType.data;
}

export const getShowEditModal = (state) => {
    return state.editIndustrySubType.showIndustrySubType;
}

