export const fetchTitpDocumentSettings = ({ commit}) => {
    return window.axios.get(process.env.VUE_APP_SMART_RECRUIT_API_URL + 'api/site-setting').then((response) => {
        commit('setTitpDocumentData', response)
        return response.data.success;
    }).catch((error) => {
        commit("SET_ALERT_MESSAGE", "Something went wrong", { root: true });
        return response.data.success;
    });   
};

export const fetchTitpCategory = ({ commit}) => {
    return window.axios.get(process.env.VUE_APP_SMART_RECRUIT_API_URL + 'api/titp-document-parent-category-list').then((response) => {
        commit('setTitpCategory', response)
        return response.data.success;
    }).catch((error) => {
        commit("SET_ALERT_MESSAGE", "Something went wrong", { root: true });
        return response.data.success;
    });   
};


 export const fetchTitpSubCategory = ({ commit}, id) => {
    return window.axios.get(process.env.VUE_APP_SMART_RECRUIT_API_URL + 'api/titp-document-child-category-list/' + id).then((response) => {
        commit('setTitpSubCategory', response)
        return response.data.success;
    }).catch((error) => {
        commit("SET_ALERT_MESSAGE", "Something went wrong", { root: true });
        return response.data.success;
    });   
};

export const postAddTitpCategory = ({ commit}, { payload, id, context}) => {
    return window.axios.post (process.env.VUE_APP_SMART_RECRUIT_API_URL + 'api/titp-document/' + id, payload).then((response) => {
        commit('SET_ALERT', 'success', { root: true })
        commit('SET_ALERT_MESSAGE', response.data.message, { root: true })
        return response.data.success
    }).catch((error) => {
        // commit('SET_ALERT', 'warning', { root: true })
        // commit('SET_ALERT_MESSAGE', 'Something went wrong', { root: true })
        context.errors = error.response.data
        return false
    })
};

export const fetchTitpCategorySettings = ({ commit}, query = '') => {
    return window.axios.get(process.env.VUE_APP_SMART_RECRUIT_API_URL + 'api/titp-document' + query).then((response) => {
        commit('setTitpCategoryData', response)
        return response.data.success;
    }).catch((error) => {
        commit('SET_ALERT', 'warning', { root: true })
        commit("SET_ALERT_MESSAGE", "Something went wrong", { root: true });
        return response.data.success;
    });   
};

export const fetchTitpCategorySettingsById = ({ commit}, id) => {
    return window.axios.get(process.env.VUE_APP_SMART_RECRUIT_API_URL + 'api/titp-document/'+id).then((response) => {
        commit('setTitpCategoryDataById', response)
        return response.data.success;
    }).catch((error) => {
        commit("SET_ALERT", "warning", { root: true });
        commit("SET_ALERT_MESSAGE", "Something went wrong", { root: true });
        return false;
    });   
};

export const titpCategoryDelete = ({ commit, context }, id) => {

    return window.axios.delete(process.env.VUE_APP_SMART_RECRUIT_API_URL +"api/titp-document/" + id).then((response) => {
      commit('SET_ALERT', 'success', { root: true })
      commit('SET_ALERT_MESSAGE', response.data.message, { root: true })
          return response.data.success
    }).catch((error) => {
        commit('SET_ALERT', 'warning', { root: true })
        commit('SET_ALERT_MESSAGE', 'Something went wrong', { root: true })
        context.errors = error.response.data
        return false
    });
};
