export default [
    {
      path: "/master-data-keyskill",
      component: () => import("../components/KeySkill.vue"),
      name: "Master-Data-KeySkill",
      meta: {
        guest: false,
        needsAuth: true,
      },
    },
  ];