export const contractData = ({ commit, dispatch }, query = '') => {
  var newQuery = '';
  if(query && !query.query){
    newQuery = '?perPage=9';
  }else{
    if(query.query === undefined){
      newQuery = '?perPage=9';
    }else{
      newQuery = query.query+'&perPage=9';
    }
  }
  return window.axios.get("api/contract-agreements" + newQuery).then((response) => {
    commit('setContractData', response)
      return response.data.data;
  })
    .catch((error) => {
      dispatch("auth/errorHandler",error.response.status,{ root:true });
      // commit("SET_ALERT", "warning", { root: true });
      // commit("SET_ALERT_MESSAGE", "Something went wrong", { root: true });
      return false;
  });
};

export const storeAndUpdateAgreement = ({ commit, dispatch }, {payload,context, isStore}) => {
  return window.axios.post("api/contract-agreements/"+isStore,payload).then((response) => {
      return response.data;
  })
    .catch((error) => {
      commit("SET_ALERT", "warning", { root: true });
      commit("SET_ALERT_MESSAGE", error.response.data.message, { root: true });
      context.btnSubmit = context.label;
			context.isbeingUpdating = false;
      dispatch("auth/errorHandler",error.response.status,{ root:true });
      return;
  });
};

export const languageData = ({ commit, dispatch }) => {
  return window.axios.get("api/languages-list").then((response) => {
      return response.data;
  })
    .catch((error) => {
      dispatch("auth/errorHandler",error.response.status,{ root:true });
      return false;
  });
};

export const deleteAgreement = ({ commit, dispatch }, payload) => {
  return window.axios.delete("api/contract-agreements/"+payload).then((response) => {
      return response.data;
  })
    .catch((error) => {
      dispatch("auth/errorHandler",error.response.status,{ root:true });
      commit("SET_ALERT", "warning", { root: true });
      commit("SET_ALERT_MESSAGE", error.response.data.message, { root: true });
      return false;
  });
};

export const getAgreementById = ({ commit, dispatch }, id) => {
  return window.axios.get("api/contract-agreements/"+id).then((response) => {
      return response.data;
  }).catch((error) => {
      dispatch("auth/errorHandler",error.response.status,{ root:true });
      // commit("SET_ALERT", "warning", { root: true });
      // commit("SET_ALERT_MESSAGE", "Something went wrong", { root: true });
      return false;
  });
};
