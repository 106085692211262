export const setMasterFunctionAreaData = (state, data) => {
	state.masterFunctionArea.functionAreaData = data.data.data;
	state.masterFunctionArea.functionAreaLinks = data.data.links;

    state.masterFunctionArea.functionAreaMeta = {
        current_page: data.data.current_page,
        from: data.data.from,
        to:  data.data.to,
        total: data.data.total,
        perPage: data.data.per_page
    }
}

export const showApproveRejectConfirmationModal = (state, flag) => {
    state.approveRejectFunctionArea.showFunctionArea = flag;
};

export const hideApproveRejectConfirmationModal = (state) => {
    state.approveRejectFunctionArea.showFunctionArea = false;
};

export const setAction = (state, action) => {
    state.approveRejectFunctionArea.action = action;
}

export const setData = (state, data) => {
    state.approveRejectFunctionArea.data = data;
}

export const showEditModal = (state, flag) => {
    state.editFunctionArea.showFunctionArea = flag;
};

export const hideEditModal = (state) => {
    state.editFunctionArea.showFunctionArea = false;
};
