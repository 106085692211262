export const setMasterExamDegreeData = (state, data) => {
	state.masterExamDegree.examDegreeData = data.data.data;
	state.masterExamDegree.examDegreeLinks = data.data.links;

    state.masterExamDegree.examDegreeMeta = {
        current_page: data.data.current_page,
        from: data.data.from,
        to:  data.data.to,
        total: data.data.total,
        perPage: data.data.per_page
    }
}

export const showApproveRejectConfirmationModal = (state, flag) => {
    state.approveRejectExamDegree.showExamDegree = flag;
};

export const hideApproveRejectConfirmationModal = (state) => {
    state.approveRejectExamDegree.showExamDegree = false;
};

export const setAction = (state, action) => {
    state.approveRejectExamDegree.action = action;
}

export const setData = (state, data) => {
    state.approveRejectExamDegree.data = data;
}

export const showEditModal = (state, flag) => {
    state.editExamDegree.showExamDegree = flag;
};

export const hideEditModal = (state) => {
    state.editExamDegree.showExamDegree = false;
};
