import profile from './profile/routes'
import auth from './auth/routes'
import superAdminSetting from "./superAdminSetting/routes"
import MembershipPlan from './MembershipPlan/routes'
import financeAndAudit from './financeAndAudit/routes'
import roleAndPermission from "./roleAndPermission/routes"
import users from "./users/routes"
import referrals from "./referrals/routes"
import jobFairs from "./jobFairs/routes"
import agreements from "./agreements/routes"
import advertisements from "./advertisements/routes"
import feedbackReviews from './feedbackReviews/routes'
import contactUs from './contactUs/routes'
import PromotionMessages from "./PromotionMessages/routes";
import projects from './projects/routes'
import jobList from './jobList/routes'
import EmailTemplate from './EmailTemplate/routes'
import activityLog from './activityLog/routes'
import jobs from "./jobs/routes";
import employerJobFair from "./employerJobFair/routes"
import blockList from "./blockList/routes"
import dashboard from './dashboard/routes'
import settingsGeneral from "./settingsGeneral/routes"
import settingsFinanceAudit from "./settingsFinanceAudit/routes"
import settingsDiscountPromotionPlans from "./settingsDiscountPromotionPlans/routes"
import settingsDashboard from "./settingsDashboard/routes"
import settingsContractAgreements from "./settingsContractAgreements/routes"
import settingsSlackIntegration from "./settingsSlackIntegration/routes"
import settingsAutoEmailDispatch from "./settingsAutoEmailDispatch/routes"
import settingsTitpDocuments from "./settingsTitpDocuments/routes"
import settingsTitpRestrictions from "./settingsTitpRestrictions/routes"
import settingsComission from "./settingsComission/routes"
import settingsFileServer from "./settingsFileServer/routes"
import settingsSlackFuntion from "./settingsSlackFuntion/routes"
import localization from "./localization/routes"
import MasterDataLanguage from "./MasterDataLanguage/routes"
import MasterDataLanguageLevel from "./MasterDataLanguageLevel/routes"
import MasterDataVisaStatus from "./MasterDataVisaStatus/routes"
import MasterDataExamDegree from "./MasterDataExamDegree/routes"
import MasterDataCourseMinor from "./MasterDataCourseMinor/routes"
import MasterDataUniversity from "./MasterDataUniversity/routes"
import MasterDataStrength from "./MasterDataStrength/routes"
import MasterDataKeySkill from "./MasterDataKeySkill/routes"
import MasterDataFunctionArea from "./MasterDataFunctionArea/routes"
import MasterDataPosition from "./MasterDataPosition/routes"
import MasterDataJobTitle from "./MasterDataJobTitle/routes"
import MasterDataWorkStyle from "./MasterDataWorkStyle/routes"
import MasterDataIndustryType from "./MasterDataIndustryType/routes"
import MasterDataIndustrySubType from "./MasterDataIndustrySubType/routes"
import MasterDataEmployerType from "./MasterDataEmployerType/routes"
import MasterDataJobFairType from "./MasterDataJobFairType/routes"
import RelocationServices from "./RelocationServices/routes"
import JobSeekerResume from "./JobSeekerResume/routes"


export default [
	...profile, 
	...auth, 
	...superAdminSetting, 
	...MembershipPlan, 
    ...financeAndAudit, 
	...roleAndPermission,
	...users,
	...referrals,
	...jobFairs,
    ...agreements,
    ...advertisements,
    ...feedbackReviews,
    ...contactUs,
    ...PromotionMessages,
    ...projects,
    ...jobList,
    ...EmailTemplate,
    ...activityLog,
    ...jobs,
    ...blockList,
    ...employerJobFair,
    ...dashboard,
    ...settingsGeneral,
    ...settingsFinanceAudit,
    ...settingsDiscountPromotionPlans,
    ...settingsDashboard,
    ...settingsContractAgreements,
    ...settingsSlackIntegration,
    ...settingsAutoEmailDispatch,
    ...settingsTitpDocuments,
    ...settingsTitpRestrictions,
    ...settingsComission,
    ...settingsFileServer,
    ...settingsSlackFuntion,
    ...localization,
    ...MasterDataLanguage,
    ...MasterDataLanguageLevel,
    ...MasterDataVisaStatus,
    ...MasterDataExamDegree,
    ...MasterDataCourseMinor,
    ...MasterDataUniversity,
    ...MasterDataStrength,
    ...MasterDataKeySkill,
    ...MasterDataFunctionArea,
    ...MasterDataPosition,
    ...MasterDataJobTitle,
    ...MasterDataWorkStyle,
    ...MasterDataIndustryType,
    ...MasterDataIndustrySubType,
    ...MasterDataEmployerType,
    ...MasterDataJobFairType,
    ...RelocationServices,
    ...JobSeekerResume,
];
