
export const addNewUser = ({ commit, dispatch }, { payload, context }) => {
    return window.axios.post (process.env.VUE_APP_SSO_API_URL +'api/sso-user/create', payload).then((response) => {
        return response.data.success;
    }).catch((error) => {
        dispatch("auth/errorHandler",error.response.status,{ root:true });
       	commit('SET_ALERT', 'warning', { root: true })
        commit('SET_ALERT_MESSAGE', 'The email has already been taken.', { root: true })
        context.errors = error.response.data.errors
        return false
    })
}

export const restAddNewUser = ({ commit, dispatch }, { payload, context }) => {
    return window.axios.post (process.env.VUE_APP_SMART_RECRUIT_API_URL +'api/add-new-user', payload).then((response) => {

        // commit('SET_ALERT', 'success', { root: true })
        // commit('SET_ALERT_MESSAGE', response.data.message, { root: true })
        return response.data;
    }).catch((error) => {
        dispatch("auth/errorHandler",error.response.status,{ root:true });
       	commit('SET_ALERT', 'warning', { root: true })
        commit('SET_ALERT_MESSAGE', 'Please enter all required fields with valid data.', { root: true })
        context.errors = error.response.data.errors
        return false
    })
}

export const restUpdateUser = ({ commit,dispatch }, { payload, context }) => {
    return window.axios.post (process.env.VUE_APP_SMART_RECRUIT_API_URL +'api/update-user-details/'+payload.id, payload).then((response) => {
        // Showing  success message
        // commit('SET_ALERT', 'success', { root: true })
        // commit('SET_ALERT_MESSAGE', response.data.message, { root: true })
        return true
    }).catch((error) => {
        dispatch("auth/errorHandler",error.response.status,{ root:true });
       	commit('SET_ALERT', 'warning', { root: true })
        commit('SET_ALERT_MESSAGE', 'Something went wrong', { root: true })
        context.errors = error.response.data.errors
        return false
    })
}

export const fetchUserList = ({ commit, dispatch }, query = '') => {
    return window.axios.get('api/user-list' + query).then((response) => {
        commit('SET_USER_LIST', response)
        return true
    }).catch((error) => {
        dispatch("auth/errorHandler",error.response.status,{ root:true });
        return false
    })
}

export const getUserRolesData = ({ commit, dispatch }) => {
    return window.axios.get(process.env.VUE_APP_SSO_API_URL + 'api/my-roles?perPage=5000').then((response) => {
        commit('SET_ROLE_LIST', response)
        return response;
    }).catch((error) => {
        dispatch("auth/errorHandler",error.response.status,{ root:true });
    	if (error.response.data.message != '') {
    		commit('SET_ALERT', 'warning', { root: true })
        	commit('SET_ALERT_MESSAGE', error.response.data.message, { root: true })
    	}
        return false
    })
}

export const getSSOuser = ({ commit, dispatch }, {payload, context}) => {
    return window.axios.get(process.env.VUE_APP_SSO_API_URL + `api/sso-user/details/` + payload).then((response) => {
        return response.data.data;
    }).catch((error) => {
        dispatch("auth/errorHandler",error.response.status,{ root:true });
        return false
    })
}

export const fetchUserById = ({ commit, dispatch }, {payload, context}) => {

    return window.axios.get(process.env.VUE_APP_SMART_RECRUIT_API_URL + `api/get-user-data/`+payload).then((response) => {
        return response.data;
    }).catch((error) => {
        dispatch("auth/errorHandler",error.response.status,{ root:true });
        return false
    })
}


export const updateUser = ({ commit, dispatch}, { payload, context, id }) => {
       return window.axios({
        method: 'POST',
        url: process.env.VUE_APP_SSO_API_URL + `api/sso-user/update-details/${payload.email}`,
        data: payload,
        }).then((response) => {

        return response.data.success
        }).catch((error) => {
        dispatch("auth/errorHandler",error.response.status,{ root:true });
        commit('SET_ALERT', 'warning', { root: true })
        commit('SET_ALERT_MESSAGE', 'Please enter all required fields with valid data', { root: true })
        context.errors = error.response.data
        return false
    })
};

export const updateProfilePic = ({ commit, dispatch}, { payload, context}) => {
       return window.axios({
        method: 'POST',
        url: process.env.VUE_APP_SMART_RECRUIT_API_URL + `api/update-user-profile-pic`,
        data: payload,
        }).then((response) => {
       
        return response.data;
        }).catch((error) => {
        dispatch("auth/errorHandler",error.response.status,{ root:true });
        commit('SET_ALERT', 'warning', { root: true })
        commit('SET_ALERT_MESSAGE', 'Something went wrong', { root: true })
        context.errors = error.response.data
        return false
    })
};

export const restDeleteUserData = ({ commit, dispatch }, {payload, context, id}) => {
    let query = id;
    if(payload.restore){
        query = query+'?restore='+payload.restore;
    }
    return window.axios.delete(process.env.VUE_APP_SMART_RECRUIT_API_URL + `api/delete-user/`+query).then((response) => {
        return response.data;
    }).catch((error) => {
        dispatch("auth/errorHandler",error.response.status,{ root:true });
        context.errors = error.response.data;
        return false
    })
}

export const notifyAuthoritiesForUserRegistration = ({ commit, dispatch }, user) => {
    let payload = {
        'userEmail': user.email
    };

    return window.axios.post(
        process.env.VUE_APP_SMART_RECRUIT_API_URL + 'api/notify/user-creation', 
        payload
    ).then((response) => {

    }).catch((error) => {
        dispatch("auth/errorHandler",error.response.status,{ root:true });
        return false;
    })
}

export const notifyAuthoritiesForUserUpdation = ({ commit, dispatch }, user) => {
    let payload = {
        'userEmail': user.email
    };

    return window.axios.post(
        process.env.VUE_APP_SMART_RECRUIT_API_URL + 'api/notify/user-updation', 
        payload
    ).then((response) => {

    })
}

export const notifyAuthoritiesForUserDeletion = ({ commit, dispatch }, email) => {
    let payload = {
        'userEmail': email
    };

    return window.axios.post(
        process.env.VUE_APP_SMART_RECRUIT_API_URL + 'api/notify/user-deletion', 
        payload
    ).then((response) => {

    })
}

export const removeProfile = ({ commit, dispatch }, {payload, context}) => {
    return window.axios.delete(
        process.env.VUE_APP_SMART_RECRUIT_API_URL + 'api/remove-user-profile-pic/'+payload
    ).then((response) => {
        return response.data;
    }).catch((error) => {
        dispatch("auth/errorHandler",error.response.status,{ root:true });
        return false;
    })
}
export const fetchDeletedUserList = ({ commit, dispatch }, query = '') => {
    query = query ? query + '&is_deleted=1' : '?is_deleted=1';
    return window.axios.get('api/user-list' + query).then((response) => {
        commit('SET_USER_LIST', response)
        return true
    }).catch((error) => {
        dispatch("auth/errorHandler",error.response.status,{ root:true });
        return false
    })
}

export const deleteSSOUser = ({context, dispatch},payload) => {
    return window.axios.delete(process.env.VUE_APP_SSO_API_URL + `api/sso-user/delete/`+payload.payload).then((response) => {
        return response.data;
    }).catch((error) => {
        dispatch("auth/errorHandler",error.response.status,{ root:true });
        context.errors = error.response.data;
        return false
    })   
}
export const restoreSSOUser = ({context, dispatch},payload) => {
    return window.axios.post(process.env.VUE_APP_SSO_API_URL + `api/sso-user/re-store/`+payload.payload).then((response) => {
        return response.data;
    }).catch((error) => {
        console.log(error);
        dispatch("auth/errorHandler",error.response.status,{ root:true });
        context.errors = error.response.data;
        return false
    })   
}