// footer base URL
export const fetchGeneral = ({ commit}) => {
  return window.axios.get(process.env.VUE_APP_SMART_RECRUIT_API_URL + 'api/general/list').then((response) => {
      commit("SET_ALERT_MESSAGE", response.data.message, { root: true });
      commit('setGeneral', response.data)
      return response.data;
  }).catch((error) => {
      commit("SET_ALERT", "warning", { root: true });
      commit("SET_ALERT_MESSAGE", "Something went wrong", { root: true });
      return false;
  });   
};