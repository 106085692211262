export const getAdvertisementData = (state) => {
    return state.advertisement.advertisementData;
}

export const getAdvertisementLink = (state) => {
    return state.advertisement.advertisementLinks;
}

export const getAdvertisementMeta = (state) => {
    return state.advertisement.advertisementMeta;
}

export const getId = (state) =>{
    return state.adv.cardId;
}

export const getDeleteModalStatus = (state) =>{
    return state.adv.showDeleteModal;
}

export const getAlertMsg = (state) =>{
    let vm = state.alert;
    let data = {status:vm.status, title:vm.title, msg:vm.msg}
    return data;
}

export const setAdvertisementById = (state) => {
	return state.advertisementById
}