export const showAddPaymentModal = (state, flag) => {
    state.showAddPaymentModal = flag
}

export const hideAddPaymentModal = (state) => {
      state.showAddPaymentModal = false
}

export const editPaymentModal = (state, flag) => {
    state.editPaymentModal = flag
}

export const editStripePaymentModel = (state, flag) => {
    state.editStripePaymentModel = flag
}

export const editPaypalPaymentModel = (state, flag) => {
    state.editPaypalPaymentModel = flag
}

export const disablePaymentModel = (state, flag) => {
    state.disablePaymentModel = flag
}

export const updatePaymentModal = (state) => {
    state.editPaymentModal = false
    state.editStripePaymentModel = false
    state.editPaypalPaymentModel = false
}

export const showRemovePaymentModal = (state, flag) => {
    state.showRemovePaymentModal = flag
}

export const hideRemovePaymentModal = (state) => {
    state.showRemovePaymentModal = false
}

export const setFinanceAuditSettingsData = (state, data) => {
	state.financeAudit.financial_year_start = data.data.data.financial_year_start
	state.financeAudit.financial_year_end = data.data.data.financial_year_end
	state.financeAudit.isStripe = data.data.data.stripe_is_enabled
	state.financeAudit.isBank = data.data.data.bank_transfer_is_enabled
	state.financeAudit.isPaypal = data.data.data.paypal_is_enabled
	state.financeAudit.stripe_api_key = data.data.data.stripe_api_key
	state.financeAudit.stripe_secret_key = data.data.data.stripe_secret_key
	state.financeAudit.bank_transfer_account_name = data.data.data.bank_transfer_account_name
	state.financeAudit.bank_transfer_bank_name = data.data.data.bank_transfer_bank_name
	state.financeAudit.bank_transfer_branch_name = data.data.data.bank_transfer_branch_name
	state.financeAudit.bank_transfer_account_type = data.data.data.bank_transfer_account_type
	state.financeAudit.bank_transfer_ifsc_code = data.data.data.bank_transfer_ifsc_code
	state.financeAudit.bank_transfer_account_number = data.data.data.bank_transfer_account_number
	state.financeAudit.paypal_url = data.data.data.paypal_url
	state.financeAudit.paypal_id = data.data.data.paypal_id
}

export const setPaymentType = (state, data) => {
	state.paymentType = data;
 }