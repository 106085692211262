
  export default [
    {
      path: "/membership-plan",
      component: () => import("../components/MembershipPlan.vue"),
      name: "Membership-Plan",
      meta: {
        guest: false,
        needsAuth: true,
      },
    },
  ];
  