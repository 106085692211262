export const showEditViewLocalization = (state, flag) => {
    state.showEditViewLocalization = flag;
};

export const setActionCategory = (state, action) => {
    state.editTranslationModal.action = action;
};

export const setImportedLanguageCodeListData = (state, data) => {
	state.importedLanguageCodeList = data.data.data
}
export const setMasterLanguageListData = (state, data) => {
	state.masterLanguageList = data.data.data.data
}

export const setLanguageNameBySlug = (state, data) => {
	state.languageNameBySlug = data.data.data
}