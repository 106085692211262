export const fetchMasterVisaStatus = ({ commit, dispatch }, query='') => {
    let url = process.env.VUE_APP_SMART_RECRUIT_API_URL + 'api/master-data-visa-status'+ query;
    return window.axios.get(url).then((response) => {
        commit('setMasterVisaStatusData', response.data);
        return true;
    }).catch((error) => {
        dispatch("auth/errorHandler",error.response.status,{ root:true });
        return false;
    })
}

export const postMasterDataVisaStatus = ({ commit}, { payload, id, context}) => {
    return window.axios.post (process.env.VUE_APP_SMART_RECRUIT_API_URL + 'api/master-data-visa-status/' + id, payload).then((response) => {
        // commit('SET_ALERT', 'success', { root: true })
        // commit('SET_ALERT_MESSAGE', response.data.message, { root: true })
        return response.data.success
    }).catch((error) => {
        // commit('SET_ALERT', 'warning', { root: true })
        // commit('SET_ALERT_MESSAGE', 'Something went wrong', { root: true })
        context.errors = error.response.data
        return false
    })
};

