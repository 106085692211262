export default [
    {
      path: "/jobseeker-resume",
      component: () => import("../components/JobSeekerResume.vue"),
      name: "jobseeker-resume",
      meta: {
        guest: false,
        needsAuth: true,
      },
    },
  ];