export const getAgreementListData = (state) => {
	return state.agreemntList.data;
}

export const getAgreementListLinks = (state) => {
	return state.agreemntList.links;
}

export const getAgreementListMeta = (state) => {
	return state.agreemntList.meta;
}
export const getUserId = (state) => {
	return state.user_id;
}
export const getUserListData = (state) => {
	return state.userList.data
}

export const getUserListLinks = (state) => {
	return state.userList.links
}

export const getUserListMeta = (state) => {
	return state.userList.meta
}
export const getAgreementDetails = (state) => {
	return state.agreementData
}
export const getShowInclusion = (state)  => {
  return state.inclusions.showInclusion
}
export const getShowWithPlanInclusion = (state) => {
  return state.inclusions.showWithPlanInclusion;
}
export const getShowSmartPlannerInclusion = (state)  => {
  return state.inclusions.showSmartPlannerInclusion
}
export const getSettingInclusion = (state) => {
	return state.inclusionsSettings;
}
export const getPlannerData = (state) => {
	return state.smartPlannerData;
}
export const getJiipaData = (state) => {
	return state.jiipaData;
}
export const getPaymentData = (state) => {
  return state.paymentData;
}

