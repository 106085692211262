export default {

    masterWorkStyle:{
        workStyleData:null,
        workStyleLinks:null,
        workStyleMeta:null,
    },

    approveRejectWorkStyle: {
        showWorkStyle: false,
        action:'',
        data:''
    },
    editWorkStyle: {
        showWorkStyle: false,
    },
    
};