export const setMasterCourseMinorData = (state, data) => {
	state.masterCourseMinor.courseMinorData = data.data.data;
	state.masterCourseMinor.courseMinorLinks = data.data.links;

    state.masterCourseMinor.courseMinorMeta = {
        current_page: data.data.current_page,
        from: data.data.from,
        to:  data.data.to,
        total: data.data.total,
        perPage: data.data.per_page
    }
}

export const showApproveRejectConfirmationModal = (state, flag) => {
    state.approveRejectCourseMinor.showCourseMinor = flag;
};

export const hideApproveRejectConfirmationModal = (state) => {
    state.approveRejectCourseMinor.showCourseMinor = false;
};

export const setAction = (state, action) => {
    state.approveRejectCourseMinor.action = action;
}

export const setData = (state, data) => {
    state.approveRejectCourseMinor.data = data;
}

export const showEditModal = (state, flag) => {
    state.editCourseMinor.showCourseMinor = flag;
};

export const hideEditModal = (state) => {
    state.editCourseMinor.showCourseMinor = false;
};
