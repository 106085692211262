export default {
    addAgreement: {
        showAgreement: false,
        hideAgreement: false,
    },
    
    editAgreement: {
        showAgreement: false,
        hideAgreement: false,
    },

    setEditId:'',
    contract:{
        contractData:null,
        contractLinks:null,
        contractMeta:null,
    },

}