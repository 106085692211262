<template>
	<div>   
		<div class="overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none justify-center items-center flex">
			<div class="relative w-full mx-4 md:mx-0 px-4 md:px-0">       
				<div class="border-2 rounded-2xl shadow-lg rounded-2xl relative flex  bg-white outline-none focus:outline-none max-w-xl xl:max-w-4xl mx-auto">        
					<div class="p-5 w-full">
						<div class="flex justify-end">                                
							<div class="relative">
								<!--button class="absolute inset-x-2 -top-7 bg-white rounded-full" type="button"  @click="$store.commit('hideChangePasswordModel', true)" :disabled="checkIfPassChangeReq()">
									<svg width="20" height="20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M10 .424c-5.522 0-10 4.305-10 9.615 0 5.31 4.478 9.616 10 9.616s10-4.306 10-9.616S15.522.424 10 .424Zm3.692 13.268-1.473-.006L10 11.142l-2.217 2.542-1.475.006a.174.174 0 0 1-.179-.172c0-.04.016-.08.043-.111l2.904-3.327-2.904-3.325a.175.175 0 0 1-.043-.111c0-.095.08-.172.179-.172l1.475.007L10 9.022l2.216-2.541 1.474-.007c.098 0 .178.075.178.172 0 .04-.015.08-.042.112l-2.9 3.324 2.902 3.327c.027.032.043.07.043.111 0 .095-.08.172-.179.172Z" fill="#DA5352"/></svg>
								</button-->
							</div>
						</div>
						<div class="flex justify-center">
							<label class="uppercase text-xl sm:text-3xl font-bold mb-4 text-center">Welcome to smart recruit
							</label>
						</div>
						<div class="flex justify-center mb-10">
							<label class="w-28 border-b-4 border-red-600"></label>
						</div>

						<div class="flex xl:space-x-6 md:px-10 xl:px-0">
							<div class="w-6/12 hidden xl:block">
								<div class="w-full relative">
									<div><img src="images/Group.png" class="rounded-md w-full h-changePasswordHeight"></div>
									<div class="absolute bottom-6 left-6 top-6 right-6 border-2 border-white rounded-lg flex items-center justify-center">
										<div class="flex flex-col items-center space-y-4">
											<label class="uppercase text-lg text-white">Welcome To</label>
											<label class="uppercase text-4xl font-semibold text-white ">SMART RECRUIT
											</label>
											<div class="border-t-2 w-28"></div>
											<div class="  text-sm text-white mx-12 text-center tracking-wider">Lorem Ipsum is simply dummy text of the printing and typesetting industry.Lorem Ipsum has been the industry's standard. 
											</div>
										</div>
									</div>
								</div>
							</div>
							<div class="w-full xl:w-6/12">
								<form class="h-full" @submit.prevent="submit">
									<div class="border shadow-md rounded-lg py-2  space-y-4 h-full px-6 md:px-16 flex items-center justify-center w-full">
										<div class="space-y-4 w-full">
											<div class="flex justify-center">
												<label class=" font-sans font-bold text-xl xl:text-3xl mb-4 tracking-tighter text-red-600 ">
													Change Password
												</label>
											</div>
											<div class="flex flex-col space-y-4">
												<div>
													<input type="text" v-model="change.old_password" @blur="clearValidity('old_password')" class="border shadow-md w-full py-2 px-4 rounded-lg focus:outline-none focus:ring-1 focus:ring-green-300" placeholder="Current Password" />
													<span v-if="!errors.old_password" class="pt-2 text-xs text-red-500">The old password field is required.</span>
												</div>
												<div>
													<input type="text" v-model="change.password" @blur="!errors.p_size ? clearValidity('p_size') : clearValidity('password')" class="border shadow-md w-full py-2 px-4 rounded-lg focus:outline-none focus:ring-1 focus:ring-green-300" placeholder="New Password" />
													<span v-if="!errors.password" class="pt-2 text-xs text-red-500">The password field is required.</span>
                                					<span v-else-if="!errors.p_size" class="pt-2 text-xs text-red-500">The password must be at least 8 characters.</span>
												</div>
												<div>
													<input type="text" v-model="change.confirm_password" @blur="!errors.is_match ? clearValidity('is_match') : clearValidity('confirm_password')" class="border shadow-md w-full py-2 px-4 rounded-lg focus:outline-none focus:ring-1 focus:ring-green-300" placeholder="Confirm New Password" />
													<span v-if="!errors.confirm_password" class="pt-2 text-xs text-red-500">The confirm password field is required.</span>
					                                <span v-else-if="!errors.is_match" class="pt-2 text-xs text-red-500">New Password & Confirm Password does not match.</span>
												</div>             
											</div>  
											<div class="flex justify-center py-2">
												<button class="flex border bg-red-600 hover:bg-red-700 text-white text-sm font-semibold tracking-tighter hover:bg-red-800 rounded-lg py-2 px-12" @click="$store.commit('showChangePasswordModel', false)">
													Cancel
												</button>
												<button class="flex border bg-red-600 hover:bg-red-700 text-white text-sm font-semibold tracking-tighter hover:bg-red-800 rounded-lg py-2 px-12">
													{{submitButtonText}}
												</button>
											</div>
										</div>
									</div>
								</form>
							</div>
						</div>
					</div>                                 
				</div>
			</div>
		</div>

		<div  v-if="showList" class="absolute z-50 ">
			<div class="flex justify-center h-full w-full">
				<div class="fixed  left-0 top-0 flex justify-center items-center inset-0 z-50">
					<div class="absolute bg-black opacity-60 inset-0 z-0"></div>
					<div class="w-full  max-w-lg py-4 relative mx-auto my-auto rounded-xl shadow-lg bg-white">
						<!--=========== content ===========-->
						<div class="">
							<div class="flex justify-center px-6">
								<svg class="w-10 text-red-600" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd"></path></svg>
							</div>
							<div class="text-center flex-auto justify-center">
								<h2 class="text-xl font-bold text-red-500 py-4 ">Password changed successfully
								</h2>
							</div>

							<div class=" flex flex-col justify-center items-center text-center space-y-6 pb-5">
								<p>Your password has been changed successfully</p>

								<router-link to="sign-in" class="mb-2 md:mb-0 bg-red-500 hover:bg-red-700 border border-red-500 px-5 py-1 text-sm shadow-sm font-medium tracking-wider text-white rounded shadow-lg hover:bg-red-600 w-28 block">Ok
								</router-link>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="opacity-25 fixed inset-0 z-40 bg-black"></div>
	</div>
</template>

<script>
// @ is an alias to /src
import { mapActions } from 'vuex'

export default {

  data: function() {
    return {
        change: {
            old_password: '',
            password: '',
            confirm_password:'',
        },
        errors: {
            password:true,
            old_password:true,
            confirm_password:true,
            is_match : true,
            p_size: true,
        },
        submitted: false,
        submitButtonText: 'Submit',
		userData: this.$store.getters['auth/user']
    }
},

components: {

},
methods: {
    ...mapActions({
        changepassword: 'auth/changepassword',
		restChangePassword : 'auth/restChangePassword',
    }),
	clearValidity(input){
        this.errors[input] = true;
    },
	validation(){
        var formIsValid = true;
        if(!this.change.old_password){
            formIsValid = false;
            this.errors.old_password = false;
        }

        if(!this.change.password){
            formIsValid = false;
            this.errors.password = false;
        }else if(this.change.password.length < 8){
            formIsValid = false;
            this.errors.p_size = false;
        }

        if(!this.change.confirm_password){
            formIsValid = false;
            this.errors.confirm_password = false;
        }

        if(this.change.password != this.change.confirm_password){
            formIsValid = false;
            this.is_match = false;
        }
        return formIsValid;
    },
    resetForm() {
        this.change.old_password= '';
        this.change.password= '';
        this.change.confirm_password='';
    },
    submit() {
		try{
			if(this.validation()){
				this.gIndicator('start');
				let vm = this
				this.submitted = true
				if (this.submitted) {
					this.submitButtonText = 'Please wait..'
				}
				this.isbeingLoggedIn = true
				this.changepassword({
					payload: this.change,
					context: this
				}).then((success) => {
					if(success){
						const payload = {email: localStorage.getItem('email')};
						this.restChangePassword({
							payload: payload,
							context: this,
						}).then((res)=>{
							if(res){
								vm.resetForm()
								vm.submitted = false;
								this.gIndicator('stop');
								this.$store.commit('hideChangePasswordModel', true, { root: true });
								vm.submitButtonText = 'Submit';
								if (success) {
									window.location = "/";
								}
							}
						})
					}
				})
			}
		}catch(error){
			this.submitButtonText = 'Submit';
			this.gIndicator('stop');
			console.log('error',error);
		}finally{
			this.submitButtonText = 'Submit';
			this.gIndicator('stop');
		}
	}
},
 mounted() {
    this.$store.commit('isAuthenticated', false)
  }
};
</script>