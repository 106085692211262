export const postNewSlackWebUrl = ({ commit}, { payload, id, context}) => {
    return window.axios.post (process.env.VUE_APP_SMART_RECRUIT_API_URL + 'api/slack-webhook/' + id, payload).then((response) => {
        commit('SET_ALERT', 'success', { root: true })
        commit('SET_ALERT_MESSAGE', response.data.message, { root: true })
        return response.data.success
    }).catch((error) => {
        // commit('SET_ALERT', 'warning', { root: true })
        // commit('SET_ALERT_MESSAGE', 'Something went wrong', { root: true })
        context.errors = error.response.data
        return false
    })
};

export const fetchSlackWebhook = ({ commit}, query = '') => {
    var newQuery = '';
  if(query && (query.query == undefined)){
    newQuery = '';
  }else if(query.query == undefined){newQuery = '';}
  else{
    newQuery = query.query;
  }
    return window.axios.get(process.env.VUE_APP_SMART_RECRUIT_API_URL + 'api/slack-webhook' +newQuery).then((response) => {
        commit('setSlackWebhook', response)
        return response.data.success;
    }).catch((error) => {
        commit("SET_ALERT_MESSAGE", "Something went wrong", { root: true });
        return response.data.success;
    });   
};

export const fetchSlackWebhookById = ({ commit}, id) => {
    return window.axios.get(process.env.VUE_APP_SMART_RECRUIT_API_URL + 'api/slack-webhook/'+id).then((response) => {
        commit('setSlackWebhookDataById', response)
        return response.data.success;
    }).catch((error) => {
        commit("SET_ALERT", "warning", { root: true });
        commit("SET_ALERT_MESSAGE", "Something went wrong", { root: true });
        return false;
    });   
};

export const slackWebhookDelete = ({ commit, context }, id) => {

    return window.axios.delete(process.env.VUE_APP_SMART_RECRUIT_API_URL +"api/slack-webhook/" + id).then((response) => {
        commit('SET_ALERT', 'success', { root: true })
        commit('SET_ALERT_MESSAGE', response.data.message, { root: true })
        return response.data.success
    }).catch((error) => {
        commit('SET_ALERT', 'warning', { root: true })
        commit('SET_ALERT_MESSAGE', 'Something went wrong', { root: true })
        context.errors = error.response.data
        return false
    });
};