export const setContactList = (state, data) => {
    state.contactList.data =  data.data.data.data
    state.contactList.links = data.data.data.links
    state.contactList.meta = {
        current_page: data.data.data.current_page,
        from: data.data.data.from,
        to:  data.data.data.to,
        total: data.data.data.total,
        perPage: data.data.data.per_page
    }
}
export const showLoader = (state, flag) => {
    state.showLoader = flag
}

export const setContactListByID = (state, value) => {
    state.contactListByID = value;
};

export const setReplyListByID = (state, value) => {
    state.replyListByID = value;
};