export default [	
	{
		path: '/promotionMessages',
		component: () => import('../components/promotionMessages.vue'),
		name: 'promotionMessages',
		meta: {	
			guest: false,
			needsAuth: true	
		}
	},
	{
      path: "/create-pramotion-messages",
      component: () => import("../components/partials/createPramotionMessages.vue"),
      name: "createPramotionMessages",
      meta: {
        guest: false,
        needsAuth: true,
      },
    },

    {
      path: "/view-pramotion-messages",
      component: () => import("../components/partials/viewPramotionMessages.vue"),
      name: "viewPramotionMessages",
      meta: {
        guest: false,
        needsAuth: true,
      },
    },

    {
      path: "/edit-pramotion-messages",
      component: () => import("../components/partials/editPramotionMessages.vue"),
      name: "editPramotionMessages",
      meta: {
        guest: false,
        needsAuth: true,
      },
    },
]