export default {
  state: {
    showChangePasswordModel: false,
    hideChangePasswordModel: false,
    
},
   
  getters: {
    showChangePasswordModel(state) {
      return state.showChangePasswordModel
    },
    hideChangePasswordModel(state) {
      return state.hideChangePasswordModel
    }
    
  },
  mutations: {
    showChangePasswordModel(state, flag) {
      state.showChangePasswordModel = flag
    },
    hideChangePasswordModel(state) {
      state.showChangePasswordModel = false
    }
    
  },
}