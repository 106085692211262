export default [
    {
        path: "/job-fairs",
        name: "myjobfairs",
        component: () => import('../components/jobfairs.vue'),
    },
    {
        path: "/add-new-jobfair",
        name: "addnewjobfair",
        component: () => import('../components/partials/addNewJobFair.vue'),
    },
    {
        path: "/edit-jobfair/:id",
        name: "editjobfair",
        component: () => import('../components/partials/editJobFair.vue'),
    },
    {
        path: "/view-jobfair/:id",
        name: "viewjobfair",
        component: () => import('../components/partials/viewJobFair.vue'),
    },
    {
        path: "/view-jobfair-organiser/:id",
        name: "viewjobfairorganiser",
        component: () => import('../components/partials/viewOrganiser.vue'),
    },
    {
        path: "/view-jobfair-unsubscribed/:id",
        name: "viewjobfairUnsubscribed",
        component: () => import('../components/partials/viewJobFairUnsubscribed.vue'),
    },
]