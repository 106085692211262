export const getJobfairData = (state) => {
    let st = state.jobfairs;
    let data = {data:st.all_data, links:st.all_links}
	return data;
}

export const getOrganiserData = (state) => {
    let st = state.jobfairs;
    let data = {data:st.organiser_list_data, links:st.organiser_list_links}
	return data;
}

export const get_industry_types = (state) => {
    return state.jobfairs.industryTypes;
}

export const get_jobfair_id = (state) => {
    return state.jobfairs.jobfairId;
}

export const get_delete_jobfair_modal = (state) => {
	return state.jobfairs.showDeleteJobFair;
}

export const getExhibitorNAttendeeList  = (state) => {
    let st = state.jobfairs;
    let data = {data:st.exhibitorNAttendeeList, links:st.exhibitorNAttendeeListLinks};
    return data;
}

export const getNonSubscribedLists  = (state) => {
   let st = state.jobfairs;
   let data = {data:st.nonSubscribedList, links:st.nonSubscribedListLinks}
   return data;
}

export const getRescheduleModal  = (state) => {
    return state.jobfairs.reschedule.reschedule;
}
export const getRescheduleModal_id  = (state) => {
    return state.jobfairs.reschedule.jobfair_id;
}
export const getCancel  = (state) => {
    return state.jobfairs.cancel.cancelJobfair;
}
export const getCancel_id  = (state) => {
    return state.jobfairs.cancel.jobfair_id;
}
export const getAcceptDecline  = (state) => {
    return state.jobfairs.acceptDeclineModal.acceptDecline;
}
export const getAcceptDeclineMsg  = (state) => {
    return state.jobfairs.acceptDeclineModal.msg;
}
export const getAcceptDeclineTitle  = (state) => {
    return state.jobfairs.acceptDeclineModal.title;
}

export const get_subscribers_list  = (state) => {
    return state.jobfairs.subscriberList.subscribers;
}
export const get_subscribers_list_links  = (state) => {
    return state.jobfairs.subscriberList.subscribers_links;
}

export const get_subscribers_list_attendee  = (state) => {
    return state.jobfairs.subscriberList_attendee.subscribers;
}
export const get_subscribers_list_links_attendee  = (state) => {
    return state.jobfairs.subscriberList_attendee.subscribers_links;
}

export const get_subscribed_as  = (state, data) => {
    return state.jobfairs.subscribed_as;
}


export const getDateNTime =  (state) =>{
    let st = state.datetime;
    let data = {startdate:st.startDate,enddate:st.endDate,starttime:st.startTime,endtime:st.endTime,timezone:st.timezone}
    
    return data;
}


export const getCommonJobfairById = (state) => {
    return state.jobfairs.commonJobfairById;
}

export const getConvertedTimeNDate = (state) =>{
    return state.converter.data;
}

export const getShowPaymentModal = (state) =>{
    return state.showPaymentModal
}

export const getHidePaymentModal = (state) =>{
    return state.hidePaymentModal
}
export const getJobseekerMembershipPlan = (state) => {
    return state.plan.membershipPlan;
}
export const getJobFairId = (state) => {
	return state.job_fair.id;	
}
export const getJobFairSubscribersCategory = (state) => {
	return state.job_fair.value
}
export const getJobFairSubscribersType = (state) => {
	return state.job_fair.type
}