export default [	
{
	path: '/emailTemplates',
	component: () => import('../components/emailTemplate.vue'),
	name: 'emailTemplates',
	meta: {	
		guest: false,
		needsAuth: true	
	}
},
{
      path: "/create-email-templates",
      component: () => import("../components/partials/createEmailTemplate.vue"),
      name: "createEmailTemplates",
      meta: {
        guest: false,
        needsAuth: true,
      },
    },
]