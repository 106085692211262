export const setMasterPositionData = (state, data) => {
	state.masterPosition.positionData = data.data.data;
	state.masterPosition.positionLinks = data.data.links;

    state.masterPosition.positionMeta = {
        current_page: data.data.current_page,
        from: data.data.from,
        to:  data.data.to,
        total: data.data.total,
        perPage: data.data.per_page
    }
}

export const showApproveRejectConfirmationModal = (state, flag) => {
    state.approveRejectPosition.showPosition = flag;
};

export const hideApproveRejectConfirmationModal = (state) => {
    state.approveRejectPosition.showPosition = false;
};

export const setAction = (state, action) => {
    state.approveRejectPosition.action = action;
}

export const setData = (state, data) => {
    state.approveRejectPosition.data = data;
}

export const showEditModal = (state, flag) => {
    state.editPosition.showPosition = flag;
};

export const hideEditModal = (state) => {
    state.editPosition.showPosition = false;
};
