export const showDeleteMembershipPlan = (state, flag) => {
    state.showDeleteMembershipPlan = flag
  }
export const hideDeleteMembershipPlan = (state) => {
    state.showDeleteMembershipPlan = false
  }

export const showAddMembershipPlan = (state, flag) => {
    state.showAddMembershipPlan = flag
  }
export const hideAddMembershipPlan = (state) => {
     state.showAddMembershipPlan = false
  }   
   
export const showEditMembershipPlan = (state, flag) => {
    state.showEditMembershipPlan = flag
  }
export const hideEditMembershipPlan = (state) => {
    state.showEditMembershipPlan = false
  }
export const setEmpMembershipPlan = (state, data) => {
    state.membershipPlan.empPlan = data.data.data;
    state.membershipPlan.empLinks = data.data.links;
  
    state.membershipPlan.empMeta = {
      current_page: data.data.current_page,
      from: data.data.from,
      to:  data.data.to,
      total: data.data.total,
      perPage: data.data.per_page
    }
}
export const setTITPMembershipPlan = (state, data) => {
  state.membershipPlan.titpPlan = data.data.data;
  state.membershipPlan.titpLinks = data.data.links;

  state.membershipPlan.titpMeta = {
    current_page: data.data.current_page,
    from: data.data.from,
    to:  data.data.to,
    total: data.data.total,
    perPage: data.data.per_page
  }
}
export const showAddTitpMembershipPlan = (state, flag) => {
  state.showAddTitpMembershipPlan = flag
}
export const hideAddTitpMembershipPlan = (state) => {
  state.showAddTitpMembershipPlan = false
}
export const showDeleteTitpMembershipPlan = (state, flag) => {
  state.showDeleteTitpMembershipPlan = flag
}
export const hideDeleteTitpMembershipPlan = (state) => {
  state.showDeleteTitpMembershipPlan = false
}
export const showEditTitpMembershipPlan = (state, flag) => {
  state.showEditTitpMembershipPlan = flag
}
export const hideEditTitpMembershipPlan = (state) => {
  state.showEditTitpMembershipPlan = false
}
export const setEmpPlanId = (state, data) => {
  state.membershipPlan.empPlanId = data;
}
export const setTitpPlanId = (state, data) => {
  state.membershipPlan.titpPlanId = data;
}