export const fetchFinanceAuditSettings = ({ commit, dispatch}, type='') => {
    if(type == 'stripe'){
        var url= process.env.VUE_APP_SMART_RECRUIT_API_URL + 'api/site-setting?stripeEdit=true';
    }else if(type == 'paypal'){
        var url= process.env.VUE_APP_SMART_RECRUIT_API_URL + 'api/site-setting?paypalEdit=true';
    }else{
        var url= process.env.VUE_APP_SMART_RECRUIT_API_URL + 'api/site-setting';
    }
    return window.axios.get(url).then((response) => {
        commit('setFinanceAuditSettingsData', response)
        return response.data.success;
    }).catch((error) => {
        dispatch("auth/errorHandler",error.response.status,{ root:true });
        commit("SET_ALERT_MESSAGE", "Something went wrong", { root: true });
        return response.data.success;
    });   
};