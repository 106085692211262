export default {
	slackintegration:{
        showSlackWebhookURLModel: false,
        showDeleteModule: false,
    },

    // slackWebhook:{
    //     webhookData:null,
    //     action:'',
    //     id:'',
    //     data:''
    // },

    slackWebhook:{
        webhookData:null,
        webhookLinks:null,
        webhookMeta:null,
        action:'',
        id:'',
        data:''
    },
}