export const getRelocationServicesData = (state) => {
    return state.relocationServices.relocationServicesData;
}

export const getRelocationServicesLink = (state) => {
    return state.relocationServices.relocationServicesLinks;
}

export const getRelocationServicesMeta = (state) => {
    return state.relocationServices.relocationServicesMeta;
}

export const getRelocationServicesDataById = (state) => {
    return state.relocation.relocationDataById;
}
export const getRelocationServicesReplyDataById = (state) => {
    return state.relocation.relocationReplyDataById;
}