export const setTitpDocumentData = (state, data) => {
	state.titpDocument.data = data.data.data.titp_common_documents
}

export const setTitpCategoryData = (state, data) => {
	state.titpCategory.categoryData = data.data.data.data;
	state.titpCategory.categoryLinks = data.data.data.links;

    state.titpCategory.categoryMeta = {
        current_page: data.data.data.current_page,
        from: data.data.data.from,
        to:  data.data.data.to,
        total: data.data.data.total,
        perPage: data.data.data.per_page
    }
}

export const setTitpCategoryDataById = (state, data) => {
	state.titpCategoryById.data = data.data.data[0]
}

export const setTitpCategory = (state, data) => {
	state.titpDocument.category = data.data.data
}

export const setTitpSubCategory = (state, data) => {
	state.titpDocument.sub_category = data.data.data
}

export const showAddNewTitpCommonDocument = (state, flag) => {
    state.addTitpCommonDocument.showTitpCommonDocument = flag;
};

export const hideAddNewTitpCommonDocument = (state) => {
    state.addTitpCommonDocument.showTitpCommonDocument = false;
};

export const setAction = (state, action) => {
    state.addTitpCommonDocument.action = action;
}

export const showAddNewTitpCategory = (state, flag) => {
    state.addTitpCategory.showTitpCategory = flag;
};

export const hideAddNewTitpCategory = (state) => {
    state.addTitpCategory.showTitpCategory = false;
};

export const setActionCategory = (state, action) => {
    state.addTitpCategory.action = action;
};

export const setId = (state, id) => {
    state.addTitpCategory.id = id;
}
