export const getFinanceAuditSettingsData = (state) => {
	return state.financeAudit
}

export const getShowAddPaymentModal = (state) => {
	return state.showAddPaymentModal
}

export const getHideAddPaymentModal = (state) => {
	return state.hideAddPaymentModal
}

export const getEditPaymentModal = (state) => {
	return state.editPaymentModal
}

export const getEditStripePaymentModel = (state) => {
	return state.editStripePaymentModel
}

export const getEditPaypalPaymentModel = (state) => {
	return state.editPaypalPaymentModel
}

export const getDisablePaymentModel = (state) => {
	return state.disablePaymentModel
}

export const getupdatePaymentModal = (state) => {
	return state.updatePaymentModal
}

export const getShowRemovePaymentModal = (state) => {
	return state.showRemovePaymentModal
}

export const getHideRemovePaymentModal = (state) => {
	return state.hideRemovePaymentModal
}

export const getPaymentType = (state) => {
    return  state.paymentType;
  }

