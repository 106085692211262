import localforage from 'localforage'

const getCitiesFromBackOffice = ({ commit}, { payload, context}) => {
  var string = payload ? payload : 'a';
  return window.axios({
      method: 'GET',
      url: process.env.VUE_APP_SSO_API_URL + `api/get-city/` + string,
  }).then((response) => {
      return response.data;
  }).catch((error) => {
      commit('SET_ALERT', 'warning', { root: true })
      commit('SET_ALERT_MESSAGE', 'Something went wrong', { root: true })
      context.errors = error.response.data
      return false
  })
};

const getCountryState = ({ commit}, { payload, context}) => {
  return window.axios({
      method: 'GET',
      url: process.env.VUE_APP_SSO_API_URL + `api/get-country-state-by-city/` + payload.id,
  }).then((response) => {
      return response.data;
  }).catch((error) => {
      commit('SET_ALERT', 'warning', { root: true })
      commit('SET_ALERT_MESSAGE', 'Something went wrong', { root: true })
      context.errors = error.response.data
      return false
  })
};

const countryData = ({ commit, context}, query = 'some') => {
  
  return window.axios.get(process.env.VUE_APP_SSO_API_URL  + "api/countries/" + query).then((response) => {
      commit("SET_COUNTRY_DATA", response.data);
      return true;
    }).catch((error) => {
      commit("SET_ALERT", "warning", { root: true });
      commit("SET_ALERT_MESSAGE", "Something went wrong City", { root: true });
      context.errors = error.response.data.errors;
      return false;
    });   
};

const getCountryAndCountryCode = ({ commit}, {context}) => {
  return window.axios({
      method: 'GET',
      url: process.env.VUE_APP_SSO_API_URL + `api/phone-code`,
  }).then((response) => { 
      return response.data;
  }).catch((error) => {
      commit('SET_ALERT', 'warning', { root: true })
      commit('SET_ALERT_MESSAGE', 'Something went wrong', { root: true })
      context.errors = error.response.data
      return false
  })
};

const fetchCommonData = ({ commit}, {payload, context})=>{
  return window.axios({
      method: 'GET',
      url: process.env.VUE_APP_SMART_RECRUIT_API_URL + 'api/get-common-data/'+payload.type+'?search=' +payload.search,
  }).then((res) => {
      return res.data; 
  }).catch(e => {
    commit('SET_ALERT', 'warning', { root: true })
    commit('SET_ALERT_MESSAGE', 'Something went wrong', { root: true })
    context.errors = error.response.data
    return false
  });
};

const languagesList = ({ commit, context}) => {
  
  return window.axios.get(process.env.VUE_APP_SMART_RECRUIT_API_URL  + "api/languages-list").then((response) => {
      if(response.data.success){
        return response.data.data;
      }else{
        return false;
      }
    }).catch((error) => {
      commit("SET_ALERT", "warning", { root: true });
      commit("SET_ALERT_MESSAGE", "Something went wrong City", { root: true });
      return false;
    });   
};
const industryList = ({commit, dispatch}) =>{
  return window.axios.get(process.env.VUE_APP_SSO_API_URL + 'api/master-industry-list').then((response) => {
      return response.data;
  }).catch((error) => {
      commit('SET_ALERT', 'warning', { root: true })
      commit('SET_ALERT_MESSAGE', 'Something went wrong', { root: true })
  })
}
const getIndustrySubTypeList = ({commit, dispatch},{payload}) =>{
  return window.axios.get(process.env.VUE_APP_SSO_API_URL + 'api/master-industry-sub-list/'+payload.id).then((response) => {
      return response.data;
  }).catch((error) => {
      commit('SET_ALERT', 'warning', { root: true })
      commit('SET_ALERT_MESSAGE', 'Something went wrong', { root: true })
  })
}
export default {
	  countryData,
    getCountryAndCountryCode,
    getCitiesFromBackOffice,
    getCountryState,
    fetchCommonData,
    languagesList,
    industryList,
    getIndustrySubTypeList
};

