export const setAdvertisementData = (state, data) => {
	state.advertisement.advertisementData = data.data.data;
	state.advertisement.advertisementLinks = data.data.links;

    state.advertisement.advertisementMeta = {
        current_page: data.data.current_page,
        from: data.data.from,
        to:  data.data.to,
        total: data.data.total,
        perPage: data.data.per_page
    }
}

export const setAdvertisementById = (state, data) => {
	state.advertisementById.id = data.data.data.id
	state.advertisementById.user_id = data.data.data.user_id
	state.advertisementById.ad_title = data.data.data.ad_title
	state.advertisementById.created_date = data.data.data.created_date
	state.advertisementById.publish_startdate = data.data.data.publish_startdate
	state.advertisementById.publish_enddate = data.data.data.publish_enddate
	state.advertisementById.posting_firstname = data.data.data.posting_firstname
	state.advertisementById.posting_lastname = data.data.data.posting_lastname
	state.advertisementById.ad_status = data.data.data.ad_status
	state.advertisementById.ad_filename_url = data.data.data.ad_filename_url
	state.advertisementById.ad_filename = data.data.data.ad_filename
	state.advertisementById.sso_user_role = data.data.data.sso_user_role
	state.advertisementById.company_logo = data.data.data.company_logo
	state.advertisementById.company_name = data.data.data.company_name
	state.advertisementById.sso_designation = data.data.data.sso_designation
}

export const setId = (state, id) =>{
    state.adv.cardId = id;
}

export const showDeleteModal = (state, flag) =>{
    state.adv.showDeleteModal = flag;
}

export const hideDeleteModal = (state) =>{
    state.adv.showDeleteModal = false;
}

export const showAlertMsg = (state,data) =>{
    state.alert.status = data.flag;
    state.alert.title = data.title;
    state.alert.msg = data.msg;
}

export const hideAlertMsg = (state) =>{
   state.alert.status = false;
}