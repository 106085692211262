export const setMasterIndustrySubTypeData = (state, data) => {
	state.masterIndustrySubType.industrySubTypeData = data.data.data;
	state.masterIndustrySubType.industrySubTypeLinks = data.data.links;

    state.masterIndustrySubType.industrySubTypeMeta = {
        current_page: data.data.current_page,
        from: data.data.from,
        to:  data.data.to,
        total: data.data.total,
        perPage: data.data.per_page
    }
}

export const showApproveRejectConfirmationModal = (state, flag) => {
    state.approveRejectIndustrySubType.showIndustrySubType = flag;
};

export const hideApproveRejectConfirmationModal = (state) => {
    state.approveRejectIndustrySubType.showIndustrySubType = false;
};

export const setAction = (state, action) => {
    state.approveRejectIndustrySubType.action = action;
}

export const setData = (state, data) => {
    state.approveRejectIndustrySubType.data = data;
}

export const showEditModal = (state, flag) => {
    state.editIndustrySubType.showIndustrySubType = flag;
};

export const hideEditModal = (state) => {
    state.editIndustrySubType.showIndustrySubType = false;
};
