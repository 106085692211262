export default {
	showViewFeedback: false,
   	hideViewFeedback: false,	

   	showDeleteFeedback: false,
   	hideDeleteFeedback: false,	

   	feedbackList: [],
   	feedbackIdDeleted: null, 
   	feedbackById: null, 
   	feedbackPublishList: [],   	

   	general:{
        data:[],
    }
}