export const fetchEmployersMembershipPlan = ({ commit, dispatch}, query = '') => {
    let setUrl = query ? '/api/membership-plan'+query+'&is_titp_plan=0' : '/api/membership-plan?is_titp_plan=0';
    return window.axios.get(setUrl).then((response) => {
        commit('setEmpMembershipPlan', response.data);
        commit("SET_ALERT", response.data.message, { root: true });
        return response.data.data;
    }).catch((error) => {
        dispatch("auth/errorHandler",error.response.status,{ root:true });
        commit("SET_ALERT", "warning", { root: true });
        commit("SET_ALERT_MESSAGE", "Something went wrong", { root: true });
        return false;
    });
};

export const fetchTITPMembershipPlan = ({ commit, dispatch}, query = '') => {
    let setUrl = query ? '/api/membership-plan'+query+'&is_titp_plan=1' : '/api/membership-plan?is_titp_plan=1';
    return window.axios.get(setUrl).then((response) => {
        commit('setTITPMembershipPlan', response.data);
        commit("SET_ALERT", response.data.message, { root: true });
        return response.data.data;
    }).catch((error) => {
        dispatch("auth/errorHandler",error.response.status,{ root:true });
        commit("SET_ALERT", "warning", { root: true });
        commit("SET_ALERT_MESSAGE", "Something went wrong", { root: true });
        return false;
    });
};

export const storeAndUpdatePlan = ({ commit, dispatch}, { payload, context}) => {
    return window.axios.post ('/api/membership-plan/'+payload.storeId, payload).then((response) => {
        // Showing  success message
        // commit('SET_ALERT', 'success', { root: true })
        // commit('SET_ALERT_MESSAGE', response.data.message, { root: true })
        return response.data.success
    }).catch((error) => {
        dispatch("auth/errorHandler",error.response.status,{ root:true });
        commit('SET_ALERT', 'warning', { root: true })
        commit('SET_ALERT_MESSAGE', 'Something went wrong', { root: true })
        context.errors = error.response.data
        return false
    })
}
export const fetchMembershipPlanId = ({ commit, dispatch}, id) => {
    return window.axios.get('/api/membership-plan/'+id).then((response) => {
        commit("SET_ALERT_MESSAGE", response.data.message, { root: true });
        return response.data;
    }).catch((error) => {
        dispatch("auth/errorHandler",error.response.status,{ root:true });
        commit("SET_ALERT", "warning", { root: true });
        commit("SET_ALERT_MESSAGE", "Something went wrong", { root: true });
        return false;
    });   
};
export const deleteMembershipPlanId = ({ commit, dispatch}, id) => {
    return window.axios.delete('/api/membership-plan/'+id).then((response) => {
        // commit('SET_ALERT', 'success', { root: true })
        // commit("SET_ALERT_MESSAGE", response.data.message, { root: true });
        return response.data;
    }).catch((error) => {
        dispatch("auth/errorHandler",error.response.status,{ root:true });
        commit("SET_ALERT", "warning", { root: true });
        commit("SET_ALERT_MESSAGE", "Something went wrong", { root: true });
        return false;
    });   
};
export const fetchJobseekerMembershipPlan = ({ commit, dispatch}) => {
    return window.axios.get(process.env.VUE_APP_SMART_RECRUIT_API_URL + 'api/site-setting').then((response) => {
        return response.data;
    }).catch((error) => {
        dispatch("auth/errorHandler",error.response.status,{ root:true });
        commit("SET_ALERT_MESSAGE", "Something went wrong", { root: true });
        return response.data.success;
    });   
};
export const postJobSeekerPlan = ({ commit, dispatch}, { payload, context}) => {
    return window.axios.post ('/api/site-setting', payload).then((response) => {
        // Showing  success message
        // commit('SET_ALERT', 'success', { root: true })
        // commit('SET_ALERT_MESSAGE', response.data.message, { root: true })
        // window.location = "membership-plan"
        return response.data.success
    }).catch((error) => {
        dispatch("auth/errorHandler",error.response.status,{ root:true });
        commit('SET_ALERT', 'warning', { root: true })
        commit('SET_ALERT_MESSAGE', 'Something went wrong', { root: true })
        context.errors = error.response.data
        return false
    })
}
