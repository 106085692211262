export const storeFunction = ({ commit, dispatch}, {payload, context}) => {
    return window.axios.post (process.env.VUE_APP_SMART_RECRUIT_API_URL + 'api/slack-modules/' +payload.isStore, payload).then((response) => {
        commit('SET_ALERT', 'success', { root: true })
        commit('SET_ALERT_MESSAGE', response.data.message, { root: true })
        return response.data
    }).catch((error) => {
        dispatch("auth/errorHandler",error.response.status,{ root:true });
        context.error = error.response.data
        return false
    })
};

export const getSlackFunctionList = ({ commit, dispatch }, query='') => {
    let url = process.env.VUE_APP_SMART_RECRUIT_API_URL + 'api/slack-modules'+ query;
    return window.axios.get(url).then((response) => {
        commit('SET_SLACK_FUNCTION_LIST', response.data);
        commit('SET_SLACK_MODULE_LIST', response);
        return true;
    }).catch((error) => {
        dispatch("auth/errorHandler",error.response.status,{ root:true });
        return false;
    })
}

export const deleteSlackFunction = ({ commit, dispatch }, payload) => {
    return window.axios.delete(process.env.VUE_APP_SMART_RECRUIT_API_URL + 'api/slack-modules/' + payload).then((response) => {
        return response.data;
    }).catch((error) => {
        dispatch("auth/errorHandler",error.response.status,{ root:true });
        return false;
    })
}

export const getByIdFunction = ({ commit, dispatch }, payload) => {
    return window.axios.get(process.env.VUE_APP_SMART_RECRUIT_API_URL + 'api/slack-modules/' + payload).then((response) => {
        return response.data;
    }).catch((error) => {
        dispatch("auth/errorHandler",error.response.status,{ root:true });
        return false;
    })
}