export const getMasterUniversityData = (state) => {
    return state.masterUniversity.universityData;
}

export const getMasterUniversityLink = (state) => {
    return state.masterUniversity.universityLinks;
}

export const getMasterUniversityMeta = (state) => {
    return state.masterUniversity.universityMeta;
}

export const getShowApproveRejectConfirmationModal = (state) => {
    return state.approveRejectUniversity.showUniversity;
}

export const getAction = (state) => {
    return state.approveRejectUniversity.action;
}

export const getData = (state) => {
    return state.approveRejectUniversity.data;
}

export const getShowEditModal = (state) => {
    return state.editUniversity.showUniversity;
}

