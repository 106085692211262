export default {
	fileServer: {
		file_server_local_aws:'',
		file_server_access_key_id:'',
		file_server_url:'',
		file_server_bucket_name:'',
		file_server_region:'',
		file_server_secret_key:'',
		oldValue:'',
		fileServerName:''
	},
}