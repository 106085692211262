export default [
    {
        path: '/settings/contract-agreements',
        component: () => import('../components/ContractAgreements.vue'),
        name: 'contract-agreements',
        meta: {		
            guest: false,
            needsAuth: true,	
        }
    },
]