export const getMasterCourseMinorData = (state) => {
    return state.masterCourseMinor.courseMinorData;
}

export const getMasterCourseMinorLink = (state) => {
    return state.masterCourseMinor.courseMinorLinks;
}

export const getMasterCourseMinorMeta = (state) => {
    return state.masterCourseMinor.courseMinorMeta;
}

export const getShowApproveRejectConfirmationModal = (state) => {
    return state.approveRejectCourseMinor.showCourseMinor;
}

export const getAction = (state) => {
    return state.approveRejectCourseMinor.action;
}

export const getData = (state) => {
    return state.approveRejectCourseMinor.data;
}

export const getShowEditModal = (state) => {
    return state.editCourseMinor.showCourseMinor;
}

