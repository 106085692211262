export default [
    {
        path: '/settings/general',
        component: () => import('../components/General.vue'),
        name: 'general',
        meta: {		
            guest: false,
            needsAuth: true,	
        }
    },
]
    