export default [
    {
        path: '/settings/setting-dashboard',
        component: () => import('../components/Dashboard.vue'),
        name: 'setting-dashboard',
        meta: {		
            guest: false,
            needsAuth: true,	
        }
    },
]