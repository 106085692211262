export const getMasterIndustryTypeData = (state) => {
    return state.masterIndustryType.industryTypeData;
}

export const getMasterIndustryTypeLink = (state) => {
    return state.masterIndustryType.industryTypeLinks;
}

export const getMasterIndustryTypeMeta = (state) => {
    return state.masterIndustryType.industryTypeMeta;
}

export const getShowApproveRejectConfirmationModal = (state) => {
    return state.approveRejectIndustryType.showIndustryType;
}

export const getAction = (state) => {
    return state.approveRejectIndustryType.action;
}

export const getData = (state) => {
    return state.approveRejectIndustryType.data;
}

export const getShowEditModal = (state) => {
    return state.editIndustryType.showIndustryType;
}

