export const getViewFeedback = (state) => {
      return state.showViewFeedback
}
export const hideViewFeedback = (state) => {
      return state.hideViewFeedback
}

export const getDeleteFeedback = (state) => {
      return state.showDeleteFeedback
}
export const getFeedId = (state) => {
    return  state.feedbackList.feedId;
}
////
export const getFeedbackList = (state) => {
      return state.feedbackList.data
}

export const getFeedbackPublishList = (state) => {
      return state.feedbackPublishList
}
export const getFeedbackListLinks = (state) => {
    return state.feedbackList.links
}

export const getFeedbackListMeta = (state) => {
    return state.feedbackList.meta
}


export const getShowLoader = (state) => {
      return state.showLoader
}

export const getSlideLoader = (state) => {
      return state.slideLoader
}

export const getCategoryList = (state) => {
      return state.category_name
}

