export const getMasterJobTitleData = (state) => {
    return state.masterJobTitle.jobTitleData;
}

export const getMasterJobTitleLink = (state) => {
    return state.masterJobTitle.jobTitleLinks;
}

export const getMasterJobTitleMeta = (state) => {
    return state.masterJobTitle.jobTitleMeta;
}

export const getShowApproveRejectConfirmationModal = (state) => {
    return state.approveRejectJobTitle.showJobTitle;
}

export const getAction = (state) => {
    return state.approveRejectJobTitle.action;
}

export const getData = (state) => {
    return state.approveRejectJobTitle.data;
}

export const getShowEditModal = (state) => {
    return state.editJobTitle.showJobTitle;
}

