export const setMasterVisaStatusData = (state, data) => {
	state.masterVisaStatus.visaStatusData = data.data.data;
	state.masterVisaStatus.visaStatusLinks = data.data.links;

    state.masterVisaStatus.visaStatusMeta = {
        current_page: data.data.current_page,
        from: data.data.from,
        to:  data.data.to,
        total: data.data.total,
        perPage: data.data.per_page
    }
}

export const showApproveRejectConfirmationModal = (state, flag) => {
    state.approveRejectVisaStatus.showVisaStatus = flag;
};

export const hideApproveRejectConfirmationModal = (state) => {
    state.approveRejectVisaStatus.showVisaStatus = false;
};

export const setAction = (state, action) => {
    state.approveRejectVisaStatus.action = action;
}

export const setData = (state, data) => {
    state.approveRejectVisaStatus.data = data;
}

export const showEditModal = (state, flag) => {
    state.editVisaStatus.showVisaStatus = flag;
};

export const hideEditModal = (state) => {
    state.editVisaStatus.showVisaStatus = false;
};
