export const fetchRelocationServices = ({ commit, dispatch }, query='') => {
    let url = process.env.VUE_APP_SMART_RECRUIT_API_URL + 'api/relocation-service'+ query;
    return window.axios.get(url).then((response) => {
        commit('setRelocationServicesData', response.data);
        return true;
    }).catch((error) => {
        dispatch("auth/errorHandler",error.response.status,{ root:true });
        return false;
    })
}

export const fetchRelocationServicesById = ({ commit, dispatch}, id) => {
    return window.axios.get(process.env.VUE_APP_SMART_RECRUIT_API_URL + 'api/relocation-service/'+id).then((response) => {
        commit('setRelocationServicesDataById', response)
        return response.data.success;
    }).catch((error) => {
        dispatch("auth/errorHandler",error.response.status,{ root:true });
        return false;
    });   
};

export const fetchRelocationServicesReplyById = ({ commit, dispatch}, id) => {
    return window.axios.get(process.env.VUE_APP_SMART_RECRUIT_API_URL + 'api/relocation-reply/'+id).then((response) => {
        commit('setRelocationServicesReplyDataById', response)
        return response.data.success;
    }).catch((error) => {
        dispatch("auth/errorHandler",error.response.status,{ root:true });
        return false;
    });   
};

export const postRelocationReply = ({ commit}, { payload, context}) => {
    return window.axios.post (process.env.VUE_APP_SMART_RECRUIT_API_URL + 'api/relocation-reply', payload).then((response) => {
        // commit('SET_ALERT', 'success', { root: true })
        // commit('SET_ALERT_MESSAGE', response.data.message, { root: true })
        return response.data
    }).catch((error) => {
        context.errors = error.response.data
        return false
    })
};